import { HttpException } from "./httpException"
export function executeServiceCall<TResponse>(servicePath: string, body?: any): Promise<TResponse> {
  return new Promise<TResponse>((resolve, reject) => {
    var httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = function (readyState) {
      try {
        if (httpRequest.readyState == XMLHttpRequest.DONE) {
          if (httpRequest.status >= 200 && httpRequest.status < 300) {
            if (httpRequest.responseText) {
              resolve(JSON.parse(httpRequest.responseText, _serviceResultReviver));
            }
            else {
              resolve();
            }
          }
          else {
            try {
              let errorMessage = JSON.parse(httpRequest.responseText);
              reject(new HttpException(httpRequest, errorMessage))
            }catch{
              reject(new HttpException(httpRequest));
            }
          }
        }
      }
      catch (error) {
        reject(new HttpException(httpRequest, error));
      }
    };

    httpRequest.open("POST", "/services/" + servicePath, true);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.setRequestHeader("Accept", "application/json");
    httpRequest.overrideMimeType("application/json");
    httpRequest.send(JSON.stringify(body));
  });
}

export function executeBinaryUploadCall<TResponse>(servicePath: string, body?: Blob): Promise<TResponse> {
  return new Promise<TResponse>((resolve, reject) => {
    var httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = function (readyState) {
      try {
        if (httpRequest.readyState == XMLHttpRequest.DONE) {
          if (httpRequest.status >= 200 && httpRequest.status < 300) {
            if (httpRequest.responseText) {
              resolve(JSON.parse(httpRequest.responseText, _serviceResultReviver));
            }
            else {
              resolve();
            }
          }
          else {
            try {
              let errorMessage = JSON.parse(httpRequest.responseText);
              reject(new HttpException(httpRequest, errorMessage))
            }catch{
              reject(new HttpException(httpRequest));
            }
          }
        }
      }
      catch (error) {
        reject(new HttpException(httpRequest, error));
      }
    };

    httpRequest.open("POST", "/services/" + servicePath, true);
    httpRequest.setRequestHeader("Content-Type", "binary/octet-stream");
    httpRequest.setRequestHeader("Accept", "application/json");
    httpRequest.overrideMimeType("application/json");
    httpRequest.send(body);
  });
}

var _iso8601DateRegex = /^[0-9]{4}-?[0-9]{2}-?[0-9]{2}T[0-9]{2}:?[0-9]{2}(:?[0-9]{2}(?:\.[0-9]+)?)?(?:Z|(?:[+-][0-9]{2}:[0-9]{2}))$/

function _serviceResultReviver(key: string, value: any): any {
  if (typeof value === "string") {
    if (_iso8601DateRegex.test(value)) {
      return new Date(value);
    }
  }
  return value;
}

export interface DimensionalModel<TKeyData extends any[] = any[]> {
  dimensions: { [K in keyof TKeyData]: TKeyData[K][] };
  measures: DimensionalModelMeasure<TKeyData>[];
}

export interface DimensionalModelMeasure<TKeyData extends any[] = any[]> {
  dimensions: TKeyData;
  value: number;
}

export interface DimensionalModelCriterion<TData = any> {
  fieldName: string;
  allowedValues?: TData[];
}