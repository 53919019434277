import { JQueryReadOnlyPropertyBinding } from "../jqueryBinding";
import * as Croppie from "croppie";

export class CroppieBinding extends JQueryReadOnlyPropertyBinding<Croppie> {
  public croppieObject!: Croppie;
  public croppieOptions!: Croppie.CroppieOptions;

  public initialize(): void {
    super.initialize();
    let boundElements = this.getBoundElements();
    this.croppieObject = new (Croppie as any).Croppie(boundElements.get(0), this.croppieOptions || {});
    this.suppressInitialValueRestoration = true;
  }

  public destroy(): void {
    super.destroy();
    this.croppieObject.destroy();
  }

  public getBoundValue(boundElements: JQuery): Croppie {
    return this.croppieObject;
  }
}