import { JQueryPropertyBinding } from "../jqueryBinding";

export interface ProgressBar {
  maxValue: number;

  currentValue: number;
  displayText: string;
  color?: string;
}

export class ProgressBarBinding extends JQueryPropertyBinding<ProgressBar> {
  public getBoundValue(boundElements: JQuery): ProgressBar {
    return {
      currentValue: Number(boundElements.attr('aria-valuenow')),
      maxValue: Number(boundElements.attr('aria-valuemax')),
      displayText: boundElements.text()
    };
  }
  public setBoundValue(boundElements: JQuery, value: ProgressBar): void {
    boundElements.attr('aria-valuenow', value.currentValue);
    boundElements.attr('aria-valuemax', value.maxValue);
    boundElements.text(value.displayText);
    boundElements.width((value.maxValue != 0 ? (value.currentValue / value.maxValue * 100) : 100) + "%");

    if (value.color)
      boundElements.removeClass('bg-danger').removeClass('bg-orange').removeClass('bg-success').addClass(value.color);
  }
}
