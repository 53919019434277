import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { CompanySingleQuery } from "../model/company";
import { currentUser } from "../shared/currentUser";
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { SelectOption } from "model/select";
import { Province } from "model/referenceData";

interface CompanyDetails {
  CompanyID?: string,
  CompanyName: string,
  Street1: string,
  Street2: string,
  PostalCode: string,
  City: string,
  ProvinceID: string | undefined,
  EstimatedEmployees: number,
  Ratio?: number | null,
  SignedCards?: number | null,
  LinkedPersonCount?: number | null
}


export class CompanySingleController extends JQueryController {
  // View
  @JQueryController.bindVisibility('#new-company-header') public newCompanyHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#update-company-header') public updateCompanyHeaderVisibility!: boolean;
  @JQueryController.bindDisplayText("#cookieName") public cookieName!: string;
  @JQueryController.bindVisibility(".new-company-button") public newCompanyButtonVisibility: boolean;
  @JQueryController.bindVisibility(".edit-company-button") public editCompanyButtonVisiblity: boolean;

  // General
  @JQueryController.bindInputValue("#companyName") public companyName!: string;
  @JQueryController.bindInputValue("#estimatedEmployees") public estimatedEmployees!: number;
  @JQueryController.bindInputValue("#cardRatio") public cardRatio?: number | null;
  @JQueryController.bindInputValue("#signedCards") public signedCards?: number | null;
  @JQueryController.bindInputValue("#personCount") public personCount?: number | null;

  // Address
  @JQueryController.bindInputValue("#street1") public street1!: string;
  @JQueryController.bindInputValue("#street2") public street2!: string;
  @JQueryController.bindInputValue("#postal-code") public postalCode!: string;
  @JQueryController.bindInputValue("#city") public city!: string;
  @JQueryController.bindSelectOptions("#province") public provinceOptions!: SelectOption[]
  @JQueryController.bindInputValue('#province') public provinceID?: string;

  // Validation
  @JQueryController.bindParsleyValidation("#main-form") public isMainFormValid!: boolean;

  private _companyID?: string;
  private _clean: CompanyDetails = {
    CompanyID: "",
    CompanyName: "",
    Street1: "",
    Street2: "",
    PostalCode: "",
    City: "",
    ProvinceID: "",
    EstimatedEmployees: 0,
    Ratio: null,
    SignedCards: null,
    LinkedPersonCount: null
  };

  constructor(rootElement: JQuery<HTMLElement>, companyID?: string) {
    super(rootElement);
    this.newCompanyButtonVisibility = false;
    this.editCompanyButtonVisiblity = false;
    var query = QueryParser.getQuery<CompanySingleQuery>(false);
  
    this.populateSelects().then(() => {
      if(query && query.companyID && currentUser) {
        this._companyID = query.companyID;
        this.LoadInformation();
        this.setNewVisiblity(false);
        this.setHeader(false);
      } else {
        this.cookieName = "New Company";
        this.setHeader(true);
        this.setNewVisiblity(true);
      }
    });
  }

  private async populateSelects() {
    await this.populateProvinceSelect();
  }

  private async populateProvinceSelect() {
    this.provinceOptions = (await executeServiceCall<Province[]>('referenceData/getProvinces')).map(x => { return { text: x.Province, value: x.ProvinceID }})
  }
  
  private setHeader(isNew: boolean) {
    this.newCompanyHeaderVisibility = isNew;
    this.updateCompanyHeaderVisibility = !isNew;
  }

  private setNewVisiblity(isNew: boolean) {
      this.editCompanyButtonVisiblity = !isNew;
      this.newCompanyButtonVisibility = isNew;
  }

  private setUIValues(company: CompanyDetails) {
    this.companyName = company.CompanyName;
    this.street1 = company.Street1;
    this.street2 = company.Street2;
    this.postalCode = company.PostalCode;
    this.city = company.City;
    this.provinceID = company.ProvinceID;
    this.estimatedEmployees = company.EstimatedEmployees;
    this.cardRatio = company.Ratio;

    this.cookieName = company.CompanyName;
    this.signedCards = company.SignedCards;
    this.personCount = company.LinkedPersonCount;

    this._clean = {...company};
  }

  public async LoadInformation() {
    var information = await this.executeServiceCall<CompanyDetails>("companies/getCompany",
    {
      companyID: this._companyID
    });
    this.setUIValues(information);
  }

  @JQueryController.bindClick("#cancel") 
  public cancelInput() {
    this.setUIValues(this._clean);
  }

  @JQueryController.bindClick("#cancelCreate") 
  public cancelCreateCompany() {
    window.location.href = '/companies.html'
  }

  @JQueryController.bindClick("#save")
  @JQueryController.bindValidation("#company-form")
  public async UpdateCompany() {
    if(this._companyID) {
      this._clean = {
        CompanyID: this._companyID,
        CompanyName: this.companyName,
        Street1: this.street1,
        Street2: this.street2,
        PostalCode: this.postalCode,
        City: this.city,
        ProvinceID: this.provinceID,
        EstimatedEmployees: this.estimatedEmployees,
        Ratio: this.cardRatio,
        SignedCards: this.signedCards,
        LinkedPersonCount: this.personCount
      }
      
      var response = await this.executeServiceCall("companies/updateCompany",
      {
        company: this._clean
      });

      JQueryMessageController.showMessage("updated-company");
    }
  }

  @JQueryController.bindClick("#createCompany")
  @JQueryController.bindValidation("#company-form")
  public async CreateCompany(e: JQuery.Event): Promise<void> {
    var response = await this.executeServiceCall<{CompanyID: string}>("companies/addCompany",
    {
      company: {
        CompanyID: this._companyID,
        CompanyName: this.companyName,
        Street1: this.street1,
        Street2: this.street2,
        PostalCode: this.postalCode,
        City: this.city,
        ProvinceID: this.provinceID,
        EstimatedEmployees: this.estimatedEmployees
      }
    });

    this._companyID = response.CompanyID;
    this.setNewVisiblity(false);
    
    window.location.href = "/companies.html"+ QueryParser.BuildQueryString<{createdCompany: string}>({createdCompany: this.companyName});
  }

  @JQueryController.bindInputChange('#estimatedEmployees')
  public updateRatio(){
    this.cardRatio = this.estimatedEmployees == 0 ? null : Math.round((this.signedCards && this.estimatedEmployees ? this.signedCards / this.estimatedEmployees : 0)*1000)/1000;
  }

}