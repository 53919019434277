import * as jQuery from "jquery";

// The following code is extracted from the app-tables-datatables sample file and applies some
// general styling to all data grids
jQuery.extend( true, $.fn.dataTable.defaults, {
    dom:
      "<'row mai-datatable-header'<'col-sm-6'l><'col-sm-6'f>>" +
      "<'row mai-datatable-body'<'col-sm-12'tr>>" +
      "<'row mai-datatable-footer'<'col-sm-5'i><'col-sm-7'p>>"
}
);

jQuery.extend( $.fn.dataTable.ext.classes, {
  sFilterInput:  "form-control form-control-sm",
  sLengthSelect: "form-control form-control-sm",
} );