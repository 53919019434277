import "@legacyx/web-uitoolkit/init";

/******* Fire up our controllers *******/
import "@legacyx/web-uitoolkit/jqueryMessageController";
import "@legacyx/web-uitoolkit/jqueryErrorController";

import "./controllerLoader";
import {App} from "../../web-host/wwwroot/assets/js/app.js";

// Initialize the starting controllers for the page; we were getting random errors that suggests
// in a very small number of cases controllers are getting initialized before the DOM is ready
// for them, so we wait until the DOM is ready before we fire everything up
import { JQueryRouter } from "@legacyx/web-uitoolkit/jqueryRouter";
import { domReady } from "@legacyx/web-uitoolkit/domReady";
import { JQueryLoadingIndicator } from "@legacyx/web-uitoolkit/jqueryLoadingIndicator"
JQueryLoadingIndicator.current.notifyLoadingStarted();
(window as any).App = App; // Beagle expects to be able to initilize its libraries onto this object.
domReady.then(() => {
  JQueryRouter.initializeAutoloadedControllers()

  // TODO: This App.js is needed to initialize the bootstrap theme menu on mobile sizes. There are 
  // dependecies for that theme through the Web Host's assets file and those files should be copied
  // out into the proper typescript when time can be allocated to identifying the portion that we need
  // here.
  App.init();
});;