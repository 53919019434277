import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { DropzoneController } from "../../web-uitoolkit/src/bindings/dropzone-v2";
import { NoZoneDate } from "@legacyx/web-uitoolkit/noZoneDate";
import { DateTime } from "../shared/datetime";
import { currentUser } from "../shared/currentUser";
import { PersonDocumentRepeater } from "../model/personDocument"
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { SelectOption } from "model/select";
import { Company } from "model/company";
import { PaginatedResults } from "model/pagination";

interface PersonDocuments {
  PersonDocumentID: string,
  ImageURL: string,
  SignedDate: string,
  FirstName: string,
  LastName: string,
  PhoneNumber: string;
  Street1: string;
  City: string;
  IsVerified: string;
}

interface SignatureDetails {
  CompanyID: string,
  PersonDocumentID: string[],
}

export class AssignSignatureController extends JQueryController {
  public personDocumentIdArray!: string[];
  public signatureDetails!: SignatureDetails;
  @JQueryController.bindInputValue('#company') public companyID!: string;
  @JQueryController.bindSelectOptions("#company") public companyOptions!: SelectOption[]
  @JQueryController.bindParsleyValidation("#assign-form") public isAssignFormValid!: boolean; 
  @JQueryController.bindRepeatingController('#person-document-repeater') public personDocumentItems?: PersonDocuments[];
  
  @JQueryController.bindVisibility('#cannot-send-service') public sendServiceVisibility = false;
  @JQueryController.bindDisplayHtml('#cannot-send-service-message') public sendServiceMessage = "";

  @JQueryController.bindVisibility('#sent-service') public sentServiceVisibility = false;
  @JQueryController.bindDisplayHtml('#sent-service-message') public sentServiceMessage = "";

  @JQueryController.bindVisibility('#no-signatures') public noSignaturesVisibility = false;
  @JQueryController.bindAttribute("#css-sig", "class") public cssSigBind!: string; 
  @JQueryController.bindAttribute("#css-name", "class") public cssNameBind!: string;
  @JQueryController.bindAttribute("#css-date", "class") public cssDateBind!: string;
  @JQueryController.bindAttribute("#css-view", "class") public cssViewBind!: string;
  
  @JQueryController.bindEnabled("#create-assignment") public createEnabled: boolean = true;
  @JQueryController.bindEnabled("#company") public companyEnabled: boolean = true;

  private defaultlist: PersonDocuments[] = [];

  constructor(rootElement: JQuery){
    super(rootElement);
    this.populateSelects().then(() => {
      this.loadDetails();
    });
  }

  private async populateCompanySelect() {
    this.companyOptions = (await executeServiceCall<PaginatedResults<Company>>('companies/getCompaniesForSelect')).results.map(x => { return { text: x.CompanyName, value: x.CompanyID }})
  }

  private async populateSelects() {
    await this.populateCompanySelect();    
  }

  private async loadDetails() {
    var docs = await executeServiceCall<PersonDocuments[]>(`signature/getDocuments`);
    
    if(docs.length >= 1){
      
      this.cssSigBind = this.cssSigBind.replace("disable-css", "")
      this.cssNameBind = this.cssNameBind.replace("disable-css", "")
      this.cssDateBind = this.cssDateBind.replace("disable-css", "")
      this.cssViewBind = this.cssViewBind.replace("disable-css", "")
      this.noSignaturesVisibility = false;
      this.createEnabled = true;
      this.companyEnabled = true;
      this.personDocumentItems = docs;
    } else {
      
      this.cssSigBind += " disable-css" 
      this.cssNameBind += " disable-css" 
      this.cssDateBind += " disable-css" 
      this.cssViewBind += " disable-css" 
      this.noSignaturesVisibility = true;
      this.createEnabled = false;
      this.companyEnabled = false;
      this.personDocumentItems = [];
    }
  }

  @JQueryController.bindClick("#create-assignment")
  @JQueryController.bindValidation("#assign-form")  
  public async CreateUser(e: JQuery.Event): Promise<void> {
    var mainAssignFormValid = this.isAssignFormValid;
    if(!mainAssignFormValid) {
      return Promise.resolve();
    }
    if (this.personDocumentIdArray.length == 0) {
      this.sentServiceVisibility = false;
      this.sendServiceVisibility = true;
      this.sendServiceMessage = "Please select at least one signature to assign a company";
    } else{
      var countSigs = this.personDocumentIdArray.length;
      this.sendServiceVisibility = false;
      this.sendServiceMessage = "";
      var newSignatures: SignatureDetails = {
        CompanyID: this.companyID,
        PersonDocumentID: this.personDocumentIdArray
      }
      var assignSignatures = await this.executeServiceCall<boolean>("signature/assignSignature", { payload: newSignatures });
      if(assignSignatures)
      {
        this.populateSelects().then(() => {
          this.loadDetails();
          
          this.companyID = "";
          this.sentServiceVisibility = true;
          if(countSigs == 1){
            this.sentServiceMessage = "Signature assignment has successfully been added to the selected company";
          }else{
            this.sentServiceMessage = "Signature assignment has successfully added " + countSigs + " signatures to the selected company";
          }
          
          this.personDocumentIdArray = [];
        });
      }
    }
  }
}

export class PersonDocumentController extends JQueryController {
  @JQueryController.bindDisplayText("#persondocumentid") public personDocumentIdText !: string;
  @JQueryController.bindDisplayText("#firstname") public firstNameText !: string;
  @JQueryController.bindDisplayText("#lastname") public lastNameText !: string;
  @JQueryController.bindDisplayText("#signeddate") public signedDateText !: string;
  @JQueryController.bindDisplayText("#isverified") public isverifiedText !: string;
  @JQueryController.bindDisplayText("#phonenumber") public phoneText !: string;
  @JQueryController.bindDisplayText("#street1") public street1Text !: string;
  @JQueryController.bindDisplayText("#city") public cityText !: string;

  @JQueryController.bindInputChecked("[name=companyenabled]") public isCompanyEnabled!: boolean; 
  @JQueryController.bindAttribute('[name=companyenabled]', 'value') public isCompanyValue!:string;  
  @JQueryController.bindAttribute('#imgSignature', 'src') public imgSrc!:string;
  @JQueryController.bindAttribute("#modal-fired", "data-target") public modalBind!: string; 
 
  @JQueryController.bindAttribute(".modal", "id") public modalWrapperBind!: string; 
 
  
  public constructor(rootElement: JQuery, public parentController: AssignSignatureController, item: PersonDocuments){
    super (rootElement);
    this.parentController.personDocumentIdArray = [];
    this.personDocumentIdText = item.PersonDocumentID;
    this.firstNameText = item.FirstName + " " + item.LastName
    this.phoneText = "Phone Number : " + item.PhoneNumber;
    this.street1Text = "Address : " + item.Street1;
    this.cityText = "City : " + item.City;
    this.signedDateText = DateTime.getDateString(new Date(item.SignedDate));
    item.IsVerified == '0' ? this.isverifiedText = 'Not Verified' : this.isverifiedText  = 'Verified';
    this.imgSrc = item.ImageURL;
    this.isCompanyEnabled = false;
    this.isCompanyValue = item.PersonDocumentID;
    this.modalBind = "#" + item.PersonDocumentID;
    this.modalWrapperBind = item.PersonDocumentID;
  }

  public removeItemsWithName(items: any[], name: string): any[] {
    return items.filter(i => i !== name);
  }

  @JQueryController.bindInputChange("[name=companyenabled]")
  public checkboxChanged(e: any) {
    let curValue = e.currentTarget.value;
    let curIsChecked = e.currentTarget.checked; 
    var hasId = this.parentController.personDocumentIdArray.includes(curValue)
    if(curIsChecked){      
      if(!hasId){
        this.parentController.personDocumentIdArray.push(curValue)
      }//does not exist in array so add. If it does exist remove in false. 
    } else{      
      if(hasId){    
        this.parentController.personDocumentIdArray = this.removeItemsWithName(this.parentController.personDocumentIdArray, curValue)
      }//check if exists if so remove it 
    }//checked is false
  }

  // @JQueryController.bindClick("#modal-fired")
  // public async ModalFired(e: JQuery.Event): Promise<void> {
  //   alert('modal hit');
    //<h4 id="phonenumber">phone</h4>
    //<h4 id="street1">address</h4>
    //<h4 id="city">city</h4>
  // }
}
