import { JQueryComponent } from "../jqueryComponent";
import { JQueryEventBinding } from "../jqueryBinding";

var _activeGlobalErrorBindings: GlobalErrorBinding[] = [];

export class GlobalErrorBinding extends JQueryEventBinding {
  public static raiseGlobalError(error: any, source: JQueryComponent): void {
    for (let activeBinding of _activeGlobalErrorBindings) {
      try {
        activeBinding.invokeHandler(error, source);
      }
      catch (error) { } // TODO: Log these?
    }
  }

  public initialize(): void {
    _activeGlobalErrorBindings.push(this);
  }

  public destroy(): void {
    _activeGlobalErrorBindings.splice(_activeGlobalErrorBindings.indexOf(this), 1);
  }
}

var _activeClearGlobalErrorBindings: GlobalErrorBinding[] = [];

export class ClearGlobalErrorBinding extends JQueryEventBinding {
  public static clearGlobalError(): void {
    for (let activeBinding of _activeClearGlobalErrorBindings) {
      try {
        activeBinding.invokeHandler();
      }
      catch (error) { } // TODO: Log these?
    }
  }

  public initialize(): void {
    _activeClearGlobalErrorBindings.push(this);
  }

  public destroy(): void {
    _activeClearGlobalErrorBindings.splice(_activeClearGlobalErrorBindings.indexOf(this), 1);
  }
}