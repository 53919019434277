import { JQueryPropertyBinding } from "../jqueryBinding";

export class DisplayTextBinding extends JQueryPropertyBinding<string> {
  public getBoundValue(boundElements: JQuery): string {
    return boundElements.text();
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    boundElements.text(value || "");
  }
}

export class DisplayHtmlBinding extends JQueryPropertyBinding<string> {
  public getBoundValue(boundElements: JQuery): string {
    return boundElements.html();
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    boundElements.html(value || "");
  }
}