import { JQueryPropertyBinding } from "../jqueryBinding";

export class AttributeBinding extends JQueryPropertyBinding<string> {
  public attribute!: string;

  public getBoundValue(boundElements: JQuery): string {
    return boundElements.attr(this.attribute)!;
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    boundElements.attr(this.attribute, value);
  }
}

export class InputValueBinding extends JQueryPropertyBinding<string | null> {
  public getBoundValue(boundElements: JQuery): string | null {
    var value = boundElements.val() as string;
    return value ? value : null;
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    boundElements.val(value);
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
    // If this is a text area we need to adjust size on update, although this appears to only
    // be required in certain circumstances for some reason
    if (this._updateEventHandler) {
      this._updateEventHandler();
    }
  }

  private _textarea?: HTMLTextAreaElement;
  private _updateEventHandler?: () => void;
  private _minHeight!: number;

  public initialize(): void {
    // This code auto-resizes text areas
    var boundElements = this.getBoundElements();
    
    if (boundElements.length === 1 && boundElements[0] instanceof HTMLTextAreaElement) {
      this._textarea = boundElements[0] as HTMLTextAreaElement

      if(this._textarea!.style.minHeight)
      this._minHeight = Number(this._textarea!.style.minHeight!.substring(0, this._textarea!.style.minHeight!.length - 2));

      this._updateEventHandler = () => {
        this._textarea!.style.height = "auto";
        this._textarea!.style.height = this.scrollHeightOrMinHeight() + 'px';
      }
      this._textarea.addEventListener("input", this._updateEventHandler);

      // This code happens first, by setting the overflow here it prevents an issue where the
      // vertical scrollbar shows up on first population
      this._textarea!.setAttribute('style', 'height:' + (this.scrollHeightOrMinHeight() + 12) + 'px;overflow-y:hidden;');
    }
    super.initialize();
  }

  // Returns whichever is bigger, scrollHeight or minHeight from initialization
  private scrollHeightOrMinHeight() {
    var result = this._minHeight || 0;
    result = result > this._textarea!.scrollHeight ? result : this._textarea!.scrollHeight;
    return result;
  }

  public destroy(): void {
    super.destroy();
    if (this._textarea) {
      this._textarea.removeEventListener("input", this._updateEventHandler!);
    }
  }
}

export class InputCheckedBinding extends JQueryPropertyBinding<boolean> {
  public getBoundValue(boundElements: JQuery): boolean {
    return boundElements.prop("checked");
  }
  public setBoundValue(boundElements: JQuery, value: boolean): void {
    boundElements.prop("checked", value);
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }
}

export class EnabledBinding extends JQueryPropertyBinding<boolean> {
  public attribute!: string;

  public getBoundValue(boundElements: JQuery): boolean {
    return boundElements.attr("disabled") != "disabled";
  }
  public setBoundValue(boundElements: JQuery, value: boolean): void {
    if (value) {
      boundElements.removeAttr("disabled");
    }
    else {
      boundElements.attr("disabled", "disabled");
    }
  }
}