import { JQueryEventBinding, JQueryReadOnlyPropertyBinding, BindableTarget } from "../jqueryBinding";
import * as Dropzone from "dropzone";

export class DropzoneController {
  public dropzones!: Dropzone[];

  public constructor(
    boundElements: JQuery,
    url: string | ((context: BindableTarget) => string),
    target: BindableTarget,
    options?: Dropzone.DropzoneOptions) {
    // Internally Dropzone offers some bindings to jQuery but it doesn't return the actual
    // jQuery objects; the code below manually creates Dropzone objects exactly as they would
    // be if we just used $.dropzone(...)
    let dropzones: Dropzone[] = [];
    let stringUrl: string;
    if (typeof url !== "string") {
      let thisUrl = url;
      // HACK: Documentation says we're allowed to use functions but typings disagrees
      stringUrl = ((ignored: Dropzone.DropzoneFile) => thisUrl(target)) as any;
    }
    else {
      stringUrl = url;
    }
    boundElements.each((index, element) => {
      let dropzone = new Dropzone(element, { url: stringUrl, ...options });
      dropzones.push(dropzone);
    });
    this.dropzones = dropzones;
  }

  public destroy(): void {
    for (let dropzone of this.dropzones) {
      dropzone.destroy();
    }
  }

  public sendFiles(serverArguments?: { [key: string]: any }): Promise<DropzoneSendResults> {
    return new Promise<DropzoneSendResults>((resolve, reject) => {
      let queuedFileCount = 0;
      let returnValue: DropzoneSendResults = { successful: [], failed: [] }
      let dropzones = this.dropzones;
      function onsending(file: Dropzone.DropzoneFile, xhr: XMLHttpRequest, formData: FormData): void {
        for (var key in serverArguments) {
          formData.append(key, serverArguments[key]);
        }
      }
      function onsuccess(file: Dropzone.DropzoneFile): void {
        returnValue.successful.push(file);
        fileCompleted();
      }
      function onerror(file: Dropzone.DropzoneFile, error: string, xhr: XMLHttpRequest): void {
        returnValue.failed.push(file);
        fileCompleted();
      }
      function fileCompleted(): void {
        queuedFileCount--;
        if (!queuedFileCount) {
          for (let dropzone of dropzones) {
            if (serverArguments) {
              dropzone.off("sending", onsending);
            }
            dropzone.off("success", onsuccess);
            dropzone.off("error", onerror);
          };
          setTimeout(function () {
            for (let dropzone of dropzones) {
              for (let file of dropzone.getFilesWithStatus("success")) {
                dropzone.removeFile(file);
              }
            };
            resolve(returnValue);
          }, 3000);
        }
        else {
          // HACK: We might be spamming calls to dropzone if we are hooked up to more than one
          for (let dropzone of dropzones) {
            dropzone.processQueue();
          };
        }
      }

      // If we don't have any files, we want to just exit, and we want to retry any files that
      // previously failed
      for (let dropzone of dropzones) {
        let failedFiles = dropzone.getFilesWithStatus("error");
        for (let failedFile of failedFiles) {
          dropzone.removeFile(failedFile);
          dropzone.addFile(failedFile);
        }

        queuedFileCount += dropzone.getQueuedFiles().length;
      };
      if (!queuedFileCount) {
        resolve(returnValue);
        return;
      }

      // We've counted the files and there's more than zero - do the real upload
      for (let dropzone of dropzones) {
        if (serverArguments) {
          dropzone.on("sending", onsending);
        }
        dropzone.on("success", onsuccess);
        dropzone.on("error", onerror);
        dropzone.processQueue();
      };
    });
  }
}

export class DropzoneBinding extends JQueryEventBinding {
  private _dropzoneController!: DropzoneController;

  public url!: string | ((context: BindableTarget) => string);

  public initialize(): void {
    super.initialize();
    this._dropzoneController = new DropzoneController(this.getBoundElements(), this.url, this.target);
    this._dropzoneController.dropzones.forEach(dropzone => {
      dropzone.on("success", file => {
        this.invokeHandler(file, file.xhr!.responseText ? JSON.parse(file.xhr!.responseText) : undefined);
        setTimeout(function () {
          dropzone.removeFile(file);
        }, 3000);
      });
    });
  }

  public destroy(): void {
    super.destroy();
    this._dropzoneController.destroy();
  }
}

export interface DropzoneSendCallback {
  (serverArguments?: { [key: string]: any }): Promise<DropzoneSendResults>;
}

export interface DropzoneSendResults {
  successful: Dropzone.DropzoneFile[];
  failed: Dropzone.DropzoneFile[];
}

export class DropzoneManualBinding extends JQueryReadOnlyPropertyBinding<DropzoneSendCallback> {
  private _dropzoneController!: DropzoneController;

  public url!: string | ((context: BindableTarget) => string);

  public initialize(): void {
    super.initialize();

    // The processQueue method of Dropzone doesn't try to send everything, it only sends the
    // number of items it can parallelize; we avoid that by just setting parallel uploads to
    // one and re-executing processQueue until the queue is empty
    this._dropzoneController = new DropzoneController(this.getBoundElements(), this.url, this.target, {
      autoProcessQueue: false,
      parallelUploads: 1,
      addRemoveLinks: true
    });
  }

  public destroy(): void {
    super.destroy();
    this._dropzoneController.destroy();
  }

  

  public getBoundValue(): DropzoneSendCallback {
    return this._dropzoneController.sendFiles.bind(this);
  }
}

export class DropzoneControllerBinding extends JQueryReadOnlyPropertyBinding<DropzoneController> {
  public dropzoneOptions!: Dropzone.DropzoneOptions;

  public url!: string | ((context: BindableTarget) => string);
  private _dropzoneController!: DropzoneController;

  public initialize(): void {
    super.initialize();
    this._dropzoneController = new DropzoneController(this.getBoundElements(), this.url, this.target, {
      autoProcessQueue: false,
      parallelUploads: 1,
      addRemoveLinks: true
    });
  }

  public destroy(): void {
    super.destroy();
    this._dropzoneController.destroy();
  }

  public getBoundValue(boundElements: JQuery): DropzoneController {
    return this._dropzoneController;
  }

  public setBoundValue(boundElements: JQuery<HTMLElement>, value: DropzoneController): void {
    throw new Error("You can't set a Dropzone Object.");
  }
}