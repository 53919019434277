var _cookies: { [cookieName: string]: string } = {};
for (let cookie of document.cookie.split(";")) {
  let splitCookie = cookie.trim().split("=");
  _cookies[splitCookie[0]] = decodeURIComponent(splitCookie[1])
}

export var cookies: { readonly [cookieName: string]: string } = _cookies;

export function setCookie(cookieName: string, value: string): void {
  document.cookie = `${cookieName}=${encodeURIComponent(value)};path=/`;
  _cookies[cookieName] = value;
}

export function deleteCookie(cookieName: string): void {
  document.cookie = `${cookieName}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  delete _cookies[cookieName];
}