// TODO: This is a bit of a hodge-podge of things, is there a more coherent interface we could make?
export interface BindableTarget {
  rootElement: JQuery;
  findBindings<T extends JQueryBinding<any>>(bindingClass: JQueryBindingConstructor<T>, searchCriteria: {
    selector?: string;
    propertyKey?: string;
  }): T[]
  destroy(): void;
}

export interface BindingFactory {
  (target: BindableTarget): JQueryBinding<any>;
}

export interface JQueryBindingConstructor<T extends JQueryBinding<any>> {
  new (target: BindableTarget, selector: string, propertyKey: string, ... otherArgs: any[]): T
}

export abstract class JQueryBinding<T> {
  public static setupBinding(
    bindingClass: JQueryBindingConstructor<any>,
    targetPrototype: BindableTarget,
    selector: string,
    propertyKey: string,
    otherArgs: { [name: string]: any }
  ): BindingFactory {
    // HACK: There's probably some code we could write to remove the need for an explicit check here, but I'm moving on to other things
    let bindingFactory: BindingFactory
    if (bindingClass.prototype instanceof JQueryPropertyBinding) {
      bindingFactory = JQueryPropertyBinding.setupBinding(bindingClass, targetPrototype, selector, propertyKey, otherArgs);
    }
    else if (bindingClass.prototype instanceof JQueryEventBinding) {
      bindingFactory = JQueryEventBinding.setupBinding(bindingClass, targetPrototype, selector, propertyKey, otherArgs);
    }
    else if (bindingClass.prototype instanceof JQueryBehavioralBinding) {
      bindingFactory = JQueryBehavioralBinding.setupBinding(bindingClass, targetPrototype, selector, propertyKey, otherArgs);
    }
    else {
      bindingFactory = function (target: BindableTarget): JQueryBinding<any> {
        return new bindingClass(target, selector, propertyKey);
      };
    }

    // For convenience, provide all named arguments to the binding via properties that are easily
    // accessible in the onInitialize() and other methods
    return function (target: BindableTarget): JQueryBinding<any> {
      let returnValue = bindingFactory(target);
      for (let key in otherArgs) {
        (returnValue as any)[key] = otherArgs[key];
      }
      return returnValue;
    }
  }

  public constructor(
    public readonly target: BindableTarget,
    public readonly selector: string,
    public readonly propertyKey: string
  ) {
  }

  protected reportBindingError(message: string): never {
    console.error(`BINDING ERROR: ${message}`);
    console.warn("Binding Error Target:", this.target);
    console.warn("Binding Error Selector:", this.selector);
    console.warn("Binding Error Property Key:", this.propertyKey);
    throw new Error(`Binding error: ${message}`); // TODO: Throw better error
  }

  public getBoundElements(): JQuery {
    return this.target.rootElement.find(this.selector).addBack(this.selector);
  }

  public getOtherSelectorBindings<T extends JQueryBinding<any>>(bindingClass: JQueryBindingConstructor<T>): T[] {
    return this.target.findBindings(bindingClass, { selector: this.selector });
  }

  public getOtherPropertyBindings<T extends JQueryBinding<any>>(bindingClass: JQueryBindingConstructor<T>): T[] {
    return this.target.findBindings(bindingClass, { propertyKey: this.propertyKey });
  }

  public initialize(): void { }
  public destroy(): void { }
  public disconnect(): any { this.destroy(); }
  public reconnect(state: any): void { this.initialize(); }

  public get boundProperty(): T { return (this.target as any)[this.propertyKey]; }
  public set boundProperty(value: T) { (this.target as any)[this.propertyKey] = value; }
}

export abstract class JQueryPropertyBinding<T> extends JQueryBinding<T> {
  public constructor(target: BindableTarget, selector: string, propertyKey: string, existingDescriptor: PropertyDescriptor) {
    super(target, selector, propertyKey);

    // It would be nice to be able to set these up on the prototype but the need to add on extra
    // metadata makes that less feasible; we shouldn't be creating so many of these that this
    // becomes a drain on performance anyhow
    let getter: () => any;
    let setter: (value: any) => void;
    if (existingDescriptor) {
      // TODO: Implement binding when there are getters/setters
      throw new Error("Binding to a getter/setter property not yet supported");
    }
    else {
      let binding = this;
      getter = function (this: BindableTarget): any {
        return binding.getBoundValue(binding.getBoundElements())
      };
      setter = function (this: BindableTarget, value: any): void {
        binding.setBoundValue(binding.getBoundElements(), value)
      };
    }
    Object.defineProperty(target, propertyKey, {
      enumerable: true,
      configurable: true,
      get: getter,
      set: setter
    });
  }

  public static setupBinding(
    bindingClass: JQueryBindingConstructor<any>,
    targetPrototype: BindableTarget,
    selector: string,
    propertyKey: string,
    otherArgs: { [name: string]: any }
  ): BindingFactory {
    let existingDescriptor = this._getBasePropertyDescriptor(targetPrototype, propertyKey);
    return function (target: BindableTarget): JQueryBinding<any> {
      return new bindingClass(target, selector, propertyKey, existingDescriptor);
    };
  }

  private static _getBasePropertyDescriptor(target: any, propertyKey: string): PropertyDescriptor | undefined {
    while (target) {
      let returnValue = Object.getOwnPropertyDescriptor(target, propertyKey);
      if (returnValue) return returnValue;
      target = Object.getPrototypeOf(target);
    }
    return undefined;
  }

  public abstract getBoundValue(boundElements: JQuery): T;
  public abstract setBoundValue(boundElements: JQuery, value: T): void;

  public suppressInitialValueRestoration?: boolean;
  public initialValue?: T;

  public initialize(): void {
    if (!this.suppressInitialValueRestoration) {
      this.initialValue = this.getBoundValue(this.getBoundElements());
    }
  }
  public destroy(): void {
    if (!this.suppressInitialValueRestoration) {
      this.setBoundValue(this.getBoundElements(), this.initialValue!);
    }
  }
  public disconnect(): any {
    var returnValue = this.getBoundValue(this.getBoundElements());
    this.destroy();
    return returnValue;
  }
  public reconnect(state: any): void {
    this.initialize();
    this.setBoundValue(this.getBoundElements(), state);
  }
}

export abstract class JQueryBackedPropertyBinding<T> extends JQueryPropertyBinding<T> {
  protected storedValue?: T;

  public getBoundValue(boundElements: JQuery): T {
    // The value isn't available until first set, but we'll assume most code is smart enough to
    // not get the value until a first set has occurred
    return this.storedValue!;
  }
  public setBoundValue(boundElements: JQuery, value: T): void {
    this.storedValue = value;
    this.notifyBoundValueChanged(boundElements, value);
  }

  protected abstract notifyBoundValueChanged(boundElements: JQuery, value: T): void;
}

export abstract class JQueryReadOnlyPropertyBinding<T> extends JQueryPropertyBinding<T> {
  public setBoundValue(boundElements: JQuery, value: T): void {
    // We ignore set attempts for these properties
  }
  public disconnect(): any { this.destroy(); }
  public reconnect(state: any): void { this.initialize(); }
}
// TODO: Use JQueryController.initializePrototype(...)-style method instead?
JQueryReadOnlyPropertyBinding.prototype.suppressInitialValueRestoration = true;

export abstract class JQueryEventBinding extends JQueryBinding<Function> {
  public static setupBinding(
    bindingClass: JQueryBindingConstructor<any>,
    targetPrototype: BindableTarget,
    selector: string,
    propertyKey: string,
    otherArgs: { [name: string]: any }
  ): BindingFactory {
    return function (target: BindableTarget): JQueryBinding<Function> {
      return new bindingClass(target, selector, propertyKey);
    };
  }

  public invokeHandler(...args: any[]): any {
    return this.boundProperty.apply(this.target, args);
  }
}

export abstract class JQuerySingleEventBinding extends JQueryEventBinding {
  public initialize(): void {
    super.initialize();
    this._handler = e => this.jqueryEventHandler(e);
    this.getBoundElements().on(this.handlerName, this._handler);
  }

  public destroy(): void {
    super.destroy();
    this.getBoundElements().off(this.handlerName, this._handler);
  }

  public abstract get handlerName(): string;
  public jqueryEventHandler(e: JQuery.Event): void {
    // By default, just run the handler
    this.invokeHandler(e);
  }

  private _handler!: (e: JQuery.Event) => void;
}

export abstract class JQueryBehavioralBinding<TReturn> extends JQueryBinding<Function> {
  public realMethod: Function;

  public constructor(target: BindableTarget, selector: string, propertyKey: string) {
    super(target, selector, propertyKey);

    // Route calls through our method, which we get at construction time (this allows multi-level
    // layers of behaviors)
    let binding = this;
    let realMethod = (target as any)[propertyKey] as Function;
    this.realMethod = realMethod;
    (target as any)[propertyKey] = function () {
      return binding.invokeMethodWithBehavior(this, realMethod, Array.prototype.slice.apply(arguments));
    }
  }

  public static setupBinding(
    bindingClass: JQueryBindingConstructor<any>,
    targetPrototype: BindableTarget,
    selector: string,
    propertyKey: string,
    otherArgs: { [name: string]: any }
  ): BindingFactory {
    return function (target: BindableTarget): JQueryBinding<any> {
      return new bindingClass(target, selector, propertyKey);
    };
  }

  public invoke(...args: any[]): any {
    this.invokeMethodWithBehavior(this.target, this.realMethod, args);
  }

  protected abstract invokeMethodWithBehavior(targetThis: any, method: Function, args: any[]): TReturn;
}