import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { currentUser } from "../shared/currentUser"
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { Type } from "croppie";

export interface MenuItem {
  text: string;
  icon: string;
  href: string;
  textAssign: string;
  hasServiceCall?: boolean;
}

interface signatureCount {
  SignatureCount: number;
}
export class MenuController extends JQueryController {
  @JQueryController.bindVisibility("#notesPageLink") public notesPageLink: boolean = true; // all users can currently see this
  @JQueryController.bindVisibility("#accountsPageLink") public accountsPageLink: boolean = false; // only admins have access to this.
  @JQueryController.bindVisibility(".admin") public adminView: boolean = false; // only admins have access to this.
  public signatureCount!: signatureCount; 
  @JQueryController.bindRepeatingController('#menuitems') public menuItems?: MenuItem[];
  private defaultlist: MenuItem[] = [
    {text: 'Notes', icon: 'mdi-home', href: 'notes.html', textAssign: ''},
    {text: 'Companies', icon: 'mdi-local-store', href: 'companies.html', textAssign: ''},
    {text: 'People', icon: 'mdi-accounts-list', href: 'persons.html', textAssign: ''},
    {text: 'Card Queue', icon: 'mdi-brush', href: 'assign-signature.html', textAssign: '', hasServiceCall:true}];

  @JQueryController.bindRepeatingController('#admin-menu-items') public adminMenuItems?: MenuItem[];
  constructor(rootElement: JQuery){
    super(rootElement);
    
    if (currentUser && (currentUser.canManageAccounts || currentUser.canManageAgreements || currentUser.canManageBilling || currentUser.canManageReports || currentUser.canManageDrives)){
      this.adminView = true;
      this.accountsPageLink = true;
      let adminItems = [];
      if (currentUser.canManageAccounts)
        adminItems.push({text: 'Accounts', icon: 'mdi-accounts', href: 'accounts.html', textAssign: ''});
      if (currentUser.canManageDrives)
        adminItems.push({text: 'Drives', icon: 'mdi-assignment', href: 'drives.html', textAssign: ''});
      if (currentUser.canManageReports)
        adminItems.push({text: 'Reports', icon:'mdi-folder', href: 'reports.html', textAssign: ''});
      if (currentUser.canManageAgreements)
        adminItems.push({text: 'Agreements', icon:'mdi-collection-text', href:'agreements.html', textAssign: ''} );
      //if (currentUser.canManageBilling)
      //  adminItems.push({text: 'Billing', icon: 'mdi-money', href:'billing.html', textAssign: ''});
      if (currentUser.canManageBilling && currentUser.canManageAgreements && currentUser.canManageReports && currentUser.canManageDrives && currentUser.canManageAccounts)
        adminItems.push({text: 'API Keys', icon: 'mdi-key', href:'apikeys.html', textAssign: ''});
      this.adminMenuItems = adminItems;
    }
    
    this.menuItems = this.defaultlist;
  }
}

export class MenuItemController extends JQueryController {
  @JQueryController.bindDisplayText('#text') public text!:string;
  @JQueryController.bindAttribute('#icon', 'class') public icon!:string;
  @JQueryController.bindAttribute('#link', 'href') public href!:string;   
  @JQueryController.bindDisplayText('#text-assign') public textAssign!:string;

  constructor(rootElement: JQuery, parentController: MenuController, item: MenuItem) {
    super(rootElement);
      this.text = item.text;
      this.href = item.href;
      this.textAssign = item.textAssign;
      this.icon = "icon mdi " + item.icon;
      //If we need to add more service calls, we should make the function generic in the interface declaration. Eithe pass the route in, or create another interface that 
      //menuitem has an instance of that contains the function to run and what to do with it.
      if (item.hasServiceCall){
        this.executeServiceCall<signatureCount>("signature/getAvailableSignatureCount")
        .then((sig)=>{
          if (sig.SignatureCount > 0)
            this.textAssign = sig.SignatureCount.toString();
        })
      }
  }
}

export class AdminMenuItemController extends JQueryController {
  @JQueryController.bindDisplayText('#text') public text!:string;
  @JQueryController.bindAttribute('#icon', 'class') public icon!:string;
  @JQueryController.bindAttribute('#link', 'href') public href!:string;

  constructor(rootElement: JQuery, parentController: MenuController, item: MenuItem) {
    super(rootElement);
    this.text = item.text;
    this.href = item.href;
    this.icon = "icon mdi " + item.icon;
  }
}