import { JQueryPropertyBinding } from "../jqueryBinding";
import { NoZoneDate } from "../noZoneDate";

export class DatePickerBinding extends JQueryPropertyBinding<Date | NoZoneDate | undefined> {
  public dataType?: "NoZoneDate";
  public containerID?: "string";

  public getBoundValue(boundElements: JQuery): Date | NoZoneDate | undefined {
    // Handle an empty value
    var selectedValue = boundElements.val() as string;
    if (!selectedValue) return undefined;

    // If the caller wants a NoZoneDate, give it to 'em
    if (this.dataType && this.dataType === "NoZoneDate") return new NoZoneDate(selectedValue);

    // In all of their wisdom, the devs at Chrome and Firefox produce a UTC time for anything that
    // doesn't have an explicit time, then transform it back by the current timezone offset; of
    // course, they assume anything with an explicit time is in the current timezone and they
    // don't do that; we'll assume (pray?) the value we receive never includes an explicit time
    // since this is a date control and not a date/time control, we'll offset the date/time to
    // present a 12:00 local time, and we'll add a flag to use for later serialization.
    let returnValue = new Date(selectedValue);
    returnValue = new Date(returnValue.setMinutes(returnValue.getMinutes() + returnValue.getTimezoneOffset()));
    (returnValue as any).isDateOnly = true;
    return returnValue;
  }

  public setBoundValue(boundElements: JQuery, value: Date | NoZoneDate | undefined): void {
    value = value instanceof NoZoneDate ? value.getClientDate() : value;

    if(this.containerID) {
      $(this.containerID).datepicker('setDate', value || null);
      $(this.containerID).trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
    }
    else {
      boundElements.datepicker('setDate', value || null);
      boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
    }
  }

  public initialize(): void {
    super.initialize();
    
    if(this.containerID)
      $(this.containerID).datepicker();
    else
      this.getBoundElements().datepicker();

    // Because release is tomorrow this is a quick hack way of limiting our datepickers to acceptable characters.
    // While it works we should probably address the root issue of users trying to enter text dates into the datepicker.
    // Note: Please leave the leading , on the allowedCharacters otherwise this won't work.
    this.getBoundElements().on("keydown", (event) => {

      // Instead of worrying about ctrl events, just let the user do it. (Mostly for copy + paste)
      if (event.ctrlKey)
        return;

      var allowedCharacters = `,1,2,3,4,5,6,7,8,9,0,-,/,., ,Backspace,Tab,Delete`;

      if (!event.key || !allowedCharacters.includes(','+event.key))
        event.preventDefault();
    });
  }

  public destroy(): void {
    super.destroy();
    this.getBoundElements().off("keydown");
    this.getBoundElements().datepicker("destroy");
  }
}