import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { currentUser, logout } from "../shared/currentUser"

interface DriveDetails {
  DriveID: string,
  Name: string,
  StartDate: Date, 
  EndDate: Date | null,
}

interface DriveRepeatingItem {
  Text: string,
  ID: string,
}

export class CurrentUserController extends JQueryController {

  @JQueryController.bindDisplayText('.username') public userName!: string;
  @JQueryController.bindDisplayText('.drive-name') public driveName!: string;
  public driveID?: string;
  @JQueryController.bindRepeatingController("#drives") public drivesOptions?: DriveRepeatingItem[]
  constructor(rootElement: JQuery<HTMLElement>) {
    super(rootElement);
    
    if(currentUser) {
        this.userName = currentUser.name || '';
        this.driveID = currentUser.currentDriveID || undefined;
        this.getDriveDetails()
        this.populateCurrentDrives();
      }
  }

  private async getDriveDetails() {
    var driveInformation = await this.executeServiceCall<DriveDetails>("drives/getDrive",
    {
      driveID: this.driveID
    });
    this.driveName = 'Drive: ' + driveInformation.Name;
    if (driveInformation.EndDate!= null && driveInformation.EndDate < new Date()) {
      this.driveName += '-EXPIRED'
    }
  }

  private async populateCurrentDrives() {
    let x = await this.executeServiceCall<DriveDetails[]>('drives/getCurrentDrives',
    {
      driveID:this.driveID
    });
    this.drivesOptions = x.map(x => { return { Text: x.Name, ID: x.DriveID }})
  }

  @JQueryController.bindClick("#account")
  public account() {
    if(currentUser)
      window.location.href = '/account-single.html?userAccountID='+ currentUser.userAccountID;
    else
      window.location.href = '/login.html';
  }

  @JQueryController.bindClick("#drive-dropdown")
  public driveDetails() {
  }

  @JQueryController.bindClick("#logout")
  public logout() {
    logout();
  }


}

export class DriveItemController extends JQueryController {
  public id: string;
  public parent: CurrentUserController;
  @JQueryController.bindDisplayText('#text') public text!:string;
  @JQueryController.bindClick("#text")
  public async changeDrive(){
    if (currentUser){
      var driveInformation = await this.executeServiceCall<DriveDetails>("drives/updateCurrentDrive",
      {
        driveID: this.id,
        userID: currentUser.userAccountID
      });
      this.parent.driveName = this.text;
    }
  }
  constructor(rootElement: JQuery, parentController: CurrentUserController, item: DriveRepeatingItem) {
    super(rootElement);
    this.text = item.Text;
    this.id = item.ID;
    this.parent = parentController;
  }
}