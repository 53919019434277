import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { QueryParser } from "../shared/queryParser";
import { currentUser } from "../shared/currentUser"


interface Company {
  CompanyID: string;
  Name: string;
  SignedCards: number;
  EstimatedEmployees: number;
  Ratio: number;
}

export class CompanyListingController extends JQueryController {
  
  @JQueryController.bindDisplayText("#created-company")
  public createdCompany!: string;
  
  @JQueryController.defineDataTableColumn("CompanyName", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("SignedCards", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("EstimatedEmployees", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("Ratio", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumnFromTemplate("CompanyID", "#company-list-table-action-column-template")
  @JQueryController.bindDataTable("#company-list-table", {
    responsive: {
      details: false
    },
    language: {
      search: "Search"
    },
    columnDefs: [
      { responsivePriority: 1, targets: 0 },
      { responsivePriority: 3, targets: 1 },
      { responsivePriority: 3, targets: 2 },
      { responsivePriority: 2, targets: 3 },
      { responsivePriority: 1, targets: 4 }
    ]
  })
  public companies: Company[] = [];

  public constructor(rootElement: JQuery) {
    super(rootElement);
    
    this._populateTable();
  }

  public pageLoaded() : void {
    var query = QueryParser.getQuery<{createdCompany: string}>(true);
    if(query && query.createdCompany) {
      this.createdCompany = query.createdCompany;
      JQueryMessageController.showMessage("created-company-message");
    }
  }

  private async _populateTable() {
      this.companies = await this.executeServiceCall<Company[]>("companies/getCompanyCounts");
  }

}