import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { DriveSingleQuery, DriveListingQuery} from "../model/drive"
import { currentUser } from "../shared/currentUser"
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { PaginatedResults } from "model/pagination";
import { SelectOption } from "model/select";
import { Company } from "model/company";
import { DriveAgreementQuery } from "../model/agreement";
import { exec } from "child_process";
import { log } from "util";
import { JQueryRouter } from "@legacyx/web-uitoolkit/jqueryRouter";

interface DriveCompanies {
  CompanyID: string,
  CompanyName: string,
  SignedCards?: string,
  EstimatedEmployees?: number,
  Ratio?: number
}

interface AddDriveResponse {
  DriveID: string
}

interface DriveDetails {
  DriveID?: string,
  Name: string,
  StartDate: Date, 
  EndDate: Date | null,
  Companies: DriveCompanies[],
  AgreementID?: string,
}


export class BillingController extends JQueryController {
  @JQueryController.listSearchQuery<Company>('#company-lookup', (value: Company) => {
    return (["CompanyName"] as (keyof Company)[])
    .reduce< string | null >((returnValue, key) => {
      if(value[key]) {
        if(returnValue) {
          return returnValue + " - "+ value[key];
        } else {
          return value[key];
        }
      }
      return returnValue;
    }, null) || "";
  })
  public async getTheSelect2Elements(searchTerm: string): Promise<Company[]> {
    if (searchTerm)
      return await executeServiceCall(`drives/getDetailedCompanyForSelect?term=${searchTerm}`);
    return [];
  }
  @JQueryController.bindListValue('#company-lookup')
  public company?: Company;
  public stripe: any;
  public card: any;
  public changeCard: any;
  public cardExpiry: any;
  public cardCvc: any;
  // View
  @JQueryController.bindVisibility('#new-billing-header') public newDriveHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#update-billing-header') public updateDriveHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#new-user-payment') public showNewUserPaymentSection!: boolean;
  @JQueryController.bindVisibility('#existing-user-payment') public showExistingUserPaymentSection = false;
  @JQueryController.bindVisibility('#existing-user-with-payment') public showExistingUserWithPaymentSection = false;
  @JQueryController.bindVisibility('#existing-user-without-payment') public showExistingUserWithoutPaymentSection = false;
  @JQueryController.bindDisplayText("#cookieName") public cookieName!: string;
  @JQueryController.bindVisibility(".new-billing-button") public newDriveButtonVisibility: boolean;
  @JQueryController.bindVisibility(".edit-billing-button") public editDriveButtonVisiblity: boolean;
  @JQueryController.bindVisibility("#automatic-billing-details") public billingDetailsVisibility: boolean;
  @JQueryController.bindDisplayText("#regularUsersLabel") public regularUsersLabel: string;
  @JQueryController.bindDisplayText("#adminUsersLabel") public adminUsersLabel: string;
  @JQueryController.bindDisplayText("#existing-payment-description") public existingBillingDescription!: string;
  @JQueryController.bindDisplayText("#updated-toggle-label") public paymentToggleLabel!: string;
  @JQueryController.bindDisplayText("#current-total") public currentTotalString!: string;
  // General
  @JQueryController.bindInputValue("#email") public email!: string;
  @JQueryController.bindInputValue("#regularUsers") public regularUsers!:  number;
  @JQueryController.bindInputValue("#adminUsers") public adminUsers!: number;
  @JQueryController.bindInputValue("#np-cardholder-name") public cardholderName !: string;
  @JQueryController.bindInputValue("#np-address-1") public address1 !: string;
  @JQueryController.bindInputValue("#np-address-2") public address2 !: string;
  @JQueryController.bindInputValue("#np-city") public city !: string;
  @JQueryController.bindInputValue("#np-province") public province !: string;
  @JQueryController.bindInputValue("#np-country") public country !: string;
  @JQueryController.bindInputValue("#np-postal-code") public postalCode !: string;
  // Payment
  @JQueryController.bindResetForm("#billing-form") 
  public resetForm() {
  }

  @JQueryController.bindInputChecked("#automatic-billing-swt") public isBillingAutomatic!: boolean;
  @JQueryController.bindInputChecked("#existing-automatic-billing-swt") public isExistingBillingAutomatic!: boolean;
  @JQueryController.bindParsleyValidation("#main-form") public isMainFormValid!: boolean;
  @JQueryController.bindSelectOptions("#company") public companyOptions!: SelectOption[];
  @JQueryController.bindVisibility(".updated-automatic-billing-details") public updatedBillingDetailsVisibility: boolean;
  @JQueryController.bindVisibility("#change-CC-Button") public changeCreditCardButtonVisibility = true;
  @JQueryController.bindInputChecked("#updated-automatic-np-billing-swt") public changeNPCreditCard!: boolean;
  @JQueryController.bindVisibility("#updated-automatic-np-billing-details") public updatedNPBillingDetailsVisibility: boolean;
  @JQueryController.bindAttribute(".required-if-checked", "required") public requiredIfChecked = false;
  @JQueryController.bindAttribute("#adminUsers", "min") public adminMinimumUsers = 0;
  @JQueryController.bindAttribute("#regularUsers", "min") public regularMinimumUsers = 0;

  @JQueryController.bindDisplayText("#card-number-error-text") public cardNumberError = "This value is required."
  @JQueryController.bindDisplayText("#card-expiration-error-text") public cardExpirationError = "This value is required."
  @JQueryController.bindDisplayText("#card-cvc-error-text") public cardCVCError = "This value is requried."

  @JQueryController.bindVisibility("#card-number-error") public cardNumberErrorVisibility = false;
  @JQueryController.bindVisibility("#card-expiration-error") public cardExpirationErrorVisibility = false;
  @JQueryController.bindVisibility("#card-cvc-error") public cardCVCErrorVisibility = false;
  
  
  private subscriptionID: string = "";
  private nextBillingDate!: Date;
  private stripePublicAPIKey: string = "";
  private currentTotal!: number;
  private isCreditCardChanged: boolean;
  private _cleanDrive: DriveDetails = {
    DriveID: "",
    Name: "",
    StartDate: new Date(),
    EndDate: null,
    Companies: [],
  };

  constructor(rootElement: JQuery<HTMLElement>, driveID?: string) {
    super(rootElement);
    this.setAPIKey()
      .then(() => {
        this.stripe = Stripe(this.stripePublicAPIKey);
        var elements = this.stripe.elements();
        var classes =  
        {
          base: "form-control general-field stripe-object",
          complete: "form-control general-field stripe-object",
          invalid: 'invalid',
          focus: 'focus',
          empty: 'empty',
        }
    
        let style = { invalid: 
          {color: '#ff0000',
          ':focus': {
            color: '#FA755A',
          }}
        }
            // Create an instance of the card Element.
            this.card = elements.create('cardNumber', {classes: classes, style: style});
            this.cardExpiry = elements.create('cardExpiry', {classes: classes, style: style});
            this.cardCvc = elements.create('cardCvc', {classes: classes, style: style})
            // Add an instance of the card Element into the `card-element` <div>.
            this.card.mount('#np-card-number');
            this.cardExpiry.mount('#np-expiry-date');
            this.cardCvc.mount('#np-cvc');
            this.card.addEventListener('change', (e: any) => {
              if (e.error){
                this.cardNumberErrorVisibility = true;
                this.cardNumberError = e.error.message;
              } else {
                this.cardNumberErrorVisibility = false;
              }
            })
        
            this.cardExpiry.addEventListener('change', (e: any) => {
              if (e.error){
                this.cardExpirationErrorVisibility = true;
                this.cardExpirationError = e.error.message;
              } else {
                this.cardExpirationErrorVisibility = false;
              }
            })
        
            this.cardCvc.addEventListener('change', (e: any) => {
              if (e.error){
                this.cardCVCErrorVisibility = true;
                this.cardCVCError = e.error.message;
              } else {
                this.cardCVCErrorVisibility = false;
              }
            })
      });
    this.isCreditCardChanged = false;

    this.newDriveButtonVisibility = false;
    this.editDriveButtonVisiblity = false;
    this.billingDetailsVisibility = false;
    this.updatedBillingDetailsVisibility = false;
    this.updatedNPBillingDetailsVisibility = false;
    this.currentTotal = 0;
    //var query = QueryParser.getQuery<DriveSingleQuery>(false);
    if(!currentUser || !currentUser.canManageBilling) {
      window.location.href = "/notes.html"
    }
    

    // Create an instance of Elements.
    // if(query && query.driveID && currentUser) {
    //   this._driveID = query.driveID;
    //   this.LoadDriveInformation().then(() => {
    //     //this.populateCompanySelect();
    //   })
    //   this.setNewUserVisiblity(false);
    //   this.setHeader(false);
    // } else {
    //   //this.populateCompanySelect();
    //   this.setHeader(true);
    //   this.setNewUserVisiblity(true);
    // }
    this.regularUsersLabel = "Regular Users";
    this.adminUsersLabel = "Admin Users";
    this.LoadDriveInformation();
  }
  
  private setHeader(isNew: boolean) {
    this.newDriveHeaderVisibility = isNew;
    this.updateDriveHeaderVisibility = !isNew;
  }

  private setNewUserVisiblity(isNewUser: boolean) {
      this.editDriveButtonVisiblity = !isNewUser;
      this.newDriveButtonVisibility = isNewUser;
      this.showNewUserPaymentSection = isNewUser;
      this.showExistingUserPaymentSection = !isNewUser;
  }

  private setUIValues(drive: DriveDetails) {
    this._cleanDrive = {...drive};
  }

  public deleteCompany(companyID: string){
  }

  private async setAPIKey(){
    this.stripePublicAPIKey = await executeServiceCall("billing/getAPIKey");
  }

  public async LoadDriveInformation() {
    var customer:any = await executeServiceCall('billing/getBillingDetails');
    if (customer){
      this.email = customer.Customer.Email;
      this.regularUsers = customer.RegularUsers;
      this.adminUsers = customer.AdminUsers;
      this.subscriptionID = customer.CurrentSubscriptionID;
      this.isExistingBillingAutomatic = customer.IsBillingAutomatic;
      this.billingDetailsVisibility = customer.IsBillingAutomatic;
      this.regularUsersLabel += " (" + customer.CurrentUsers.CurrentRegularUsers + " in use)";
      this.adminUsersLabel += " (" + customer.CurrentUsers.CurrentAdminUsers + " in use)";
      this.adminMinimumUsers = customer.CurrentUsers.CurrentAdminUsers;
      this.regularMinimumUsers = customer.CurrentUsers.CurrentRegularUsers;
      this.nextBillingDate = new Date(customer.NextBillingDate);
      if (this.isExistingBillingAutomatic){
        this.existingBillingDescription = "Billing details have been setup to be billed to the card ending in " + customer.Cards.Data[0].Last4;
        this.showExistingUserWithPaymentSection = true;
      } else {
        this.existingBillingDescription = "Billing details have been setup to be sent to the email above"
        this.showExistingUserWithoutPaymentSection = true;
        this.card.unmount();
        this.card.mount("#np-card-number")
      }
      this.existingBillingDescription += ". The next billing cycle is " + this.nextBillingDate.toLocaleDateString();
      this.setHeader(false);
      this.setNewUserVisiblity(false);
    } else {
      this.setHeader(true);
      JQueryMessageController.showMessage("first-time-billing-setup");
      this.setNewUserVisiblity(true);
    }
    this.currentTotal = (this.adminUsers * 75) + (this.regularUsers * 50)
    this.currentTotalString = "You will be charged $" + this.currentTotal + " recurring every month";
  }

  @JQueryController.bindInputChange("#automatic-billing-swt")
  public billingChanged(e: Event) {
    this.updatedNPBillingDetailsVisibility = !this.updatedNPBillingDetailsVisibility;
    this.requiredIfChecked = !this.requiredIfChecked;
  }
  
  @JQueryController.bindInputChange("#updated-automatic-np-billing-swt")
  public updatedNPBillingChanged() {
    this.updatedNPBillingDetailsVisibility = !this.updatedNPBillingDetailsVisibility;
    this.requiredIfChecked = !this.requiredIfChecked;
  }

  @JQueryController.bindInputChange("#existing-automatic-billing-swt")
  public automaticBillingChanged() {
    this.changeCreditCardButtonVisibility = this.isExistingBillingAutomatic;
    if (!this.isExistingBillingAutomatic) {
      this.updatedBillingDetailsVisibility = false;
      this.updatedNPBillingDetailsVisibility = false;
    }
  }

  @JQueryController.bindInputChange(".stripe-object")
  public stripeChange(e: any) {
  }

  @JQueryController.bindInputChange("#regularUsers")
  public regularUserChanged() {
    this.currentTotal = (this.adminUsers * 75) + (this.regularUsers * 50)
    this.currentTotalString = "You will be charged $" + this.currentTotal + " recurring every month";
  }

  @JQueryController.bindInputChange("#adminUsers")
  public adminUserChanged() {
    this.currentTotal = (this.adminUsers * 75) + (this.regularUsers * 50)
    this.currentTotalString = "You will be charged $" + this.currentTotal + " recurring every month";
  }


  @JQueryController.bindClick("#cancel") 
  public cancelInput() {
    this.setUIValues(this._cleanDrive);
  }

  @JQueryController.bindClick("#cancelCreate") 
  public cancelCreateDrive() {
    window.location.href = '/drives.html'
  }

  @JQueryController.bindClick("#change-CC-Button") 
  public changeCreditCard() {
    this.isCreditCardChanged = true;
    this.updatedBillingDetailsVisibility = true;
    this.updatedNPBillingDetailsVisibility = true;
    this.changeCreditCardButtonVisibility = false;
    this.requiredIfChecked = true;
  }

  @JQueryController.bindClick("#undo-CC-Button")
  public undoCreditCardChange() {
      this.isCreditCardChanged = false;
      this.updatedNPBillingDetailsVisibility = false;
      this.updatedBillingDetailsVisibility = false;
      this.changeCreditCardButtonVisibility = true;
      this.requiredIfChecked = false;
      this.card.unmount();
      this.card.mount("#np-card-number");
  }
  
  //Could clean up the body of this function a bit. I think a lot of the functionality could be wrapped in two functions which are
  //called and could probably make this look quite a bit cleaner
  @JQueryController.bindClick("#save")
  public async UpdateBillingCall() {
    this.checkForStripeValidation();
    this.UpdateBillingDetails();
  }

  @JQueryController.bindValidation("#billing-form")
  public async UpdateBillingDetails() {
    this.checkForStripeValidation();
    if (this.isExistingBillingAutomatic){
      if (this.isCreditCardChanged){
        let result = await this.stripe.createToken(this.card, {
          name: this.cardholderName,
          address_line1: this.address1,
          address_line2: this.address2 ? this.address2 : "",
          address_state: this.province,
          address_zip: this.postalCode,
          address_country: this.country,
          address_city: this.city
        })
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = document.getElementById("updated-card-errors");
          if (errorElement)
            errorElement.textContent = result.error.message;
        } else {
          let billingDetails = {
            StripeCardToken: result.token.id,
            Email: this.email,
            RegularUsers: this.regularUsers,
            AdminUsers: this.adminUsers,
            IsBillingAutomatic: this.isExistingBillingAutomatic,
            StripeSubscriptionID: this.subscriptionID
          }
          var response = await executeServiceCall('billing/updateBillingDetails', {
            billingDetails: billingDetails
          })
          if (response){
            JQueryMessageController.showMessage('successful-billing-update')
          }
        }
      }
      else {
        let billingDetails = {
          StripeCardToken: "",
          Email: this.email,
          RegularUsers: this.regularUsers,
          AdminUsers: this.adminUsers,
          IsBillingAutomatic: this.isExistingBillingAutomatic,
          StripeSubscriptionID: this.subscriptionID
        }
        var response = await executeServiceCall('billing/updateBillingDetails', {
          billingDetails: billingDetails
        });
        if (response){
          JQueryMessageController.showMessage('successful-billing-update')
        }
      }
    } else {
      if(this.changeNPCreditCard){
        let result = await this.stripe.createToken(this.card, {
          name: this.cardholderName,
          address_line1: this.address1,
          address_line2: this.address2 ? this.address2 : "",
          address_state: this.province,
          address_zip: this.postalCode,
          address_country: this.country,
          address_city: this.city
        })
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = document.getElementById("updated-card-errors");
          if (errorElement)
            errorElement.textContent = result.error.message;
        } else {
          let billingDetails = {
            StripeCardToken: result.token.id,
            Email: this.email,
            RegularUsers: this.regularUsers,
            AdminUsers: this.adminUsers,
            IsBillingAutomatic: this.changeNPCreditCard,
            StripeSubscriptionID: this.subscriptionID
          }
          var response = await executeServiceCall('billing/updateBillingDetails', {
            billingDetails: billingDetails
          })
          if (response){
            JQueryMessageController.showMessage('successful-billing-update')
          }
        }
      } else {
        let billingDetails = {
          StripeCardToken: "",
          Email: this.email,
          RegularUsers: this.regularUsers,
          AdminUsers: this.adminUsers,
          IsBillingAutomatic: this.changeNPCreditCard,
          StripeSubscriptionID: this.subscriptionID
        }
        var response = await executeServiceCall('billing/updateBillingDetails', {
          billingDetails: billingDetails
        });
        if (response){
          JQueryMessageController.showMessage('successful-billing-update')
        }
      }
    }
  }

  @JQueryController.bindClick("#createBillingDetails")
  public async TestForBilling(e: JQuery.Event) {
    this.checkForStripeValidation();
    this.CreateBilling(e);
  }

  @JQueryController.bindValidation("#billing-form")
  public async CreateBilling(e: JQuery.Event): Promise<void> {
    this.checkForStripeValidation();
    if (this.isBillingAutomatic){
    this.stripe.createToken(this.card, {
      name: this.cardholderName,
      address_line1: this.address1,
      address_line2: this.address2 ? this.address2 : "",
      address_state: this.province,
      address_zip: this.postalCode,
      address_country: this.country,
      address_city: this.city
    }).then(async (result: any) => {
      if (result.error) {
        // Inform the user if there was an error.
        var errorElement = document.getElementById('card-errors');
        if (errorElement)
          errorElement.textContent = result.error.message;
      } else {
        let billingDetails = {
          StripeCardToken: result.token.id,
          Email: this.email,
          RegularUsers: this.regularUsers,
          AdminUsers: this.adminUsers,
          IsBillingAutomatic: this.isBillingAutomatic
        }
        var response = await executeServiceCall('billing/createBillingDetails', {
          billingDetails: billingDetails
        });
        if (response){
          window.location.href = "/notes.html";
        }
      }
    });
  }
  else {
    let billingDetails = {
      StripeCardToken: "",
      Email: this.email,
      RegularUsers: this.regularUsers,
      AdminUsers: this.adminUsers,
      IsBillingAutomatic: this.isBillingAutomatic
    }
    var response = await executeServiceCall('billing/createBillingDetails', {
      billingDetails: billingDetails
    });
    if (response){
      window.location.href = "/notes.html";
    }
  }
  }

  public checkForStripeValidation() {
    if (this.cardCvc._empty && (this.isBillingAutomatic || this.isExistingBillingAutomatic)) {
      this.cardCVCErrorVisibility = true;
      this.cardCVCError = "This value is required.";
    } 

    if (this.card._empty && (this.isBillingAutomatic || this.isExistingBillingAutomatic)) {
      this.cardNumberErrorVisibility = true;
      this.cardNumberError = "This value is required.";
    }

    if (this.cardExpiry._empty && (this.isBillingAutomatic || this.isExistingBillingAutomatic)) {
      this.cardExpirationErrorVisibility = true;
      this.cardExpirationError = "This value is required.";
    }
  }
}