import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { AccountListingQuery } from "../model/account"
import { QueryParser } from "../shared/queryParser";
import { currentUser } from "../shared/currentUser"


interface User {
  UserAccountID: string;
  LoginName: string;
  FirstName: string;
  LastName: string;
  IsEnabled: boolean;
}

export class AccountListingController extends JQueryController {
  
  @JQueryController.bindDisplayText("#created-account")
  public createdUser!: string;
  
  @JQueryController.defineDataTableColumn("FirstName", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("LastName", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("LoginName")
  @JQueryController.defineDataTableColumnFromTemplate("IsEnabled", "#user-list-table-enabled-column-template")
  @JQueryController.defineDataTableColumnFromTemplate("UserAccountID", "#user-list-table-action-column-template")
  @JQueryController.bindDataTable("#user-list-table", {
    responsive: {
      details: false
    },
    language: {
      search: "Search"
    },
    columnDefs: [
      { responsivePriority: 1, targets: 0 },
      { responsivePriority: 1, targets: 1 },
      { responsivePriority: 3, targets: 2 },
      { responsivePriority: 2, targets: 3 },
      { responsivePriority: 1, targets: 4 }
    ]
  })
  public users: User[] = [];

  public constructor(rootElement: JQuery) {
    super(rootElement);
    if(!currentUser || !currentUser.canManageAccounts) {
      window.location.href = "/notes.html"
    }
    this._populateTable();
  }

  public pageLoaded() : void {
    var query = QueryParser.getQuery<AccountListingQuery>(true);
    if(query && query.createdUser) {
      this.createdUser = query.createdUser;
      JQueryMessageController.showMessage("created-account-message");
    }
  }

  private async _populateTable() {
    if (currentUser && currentUser.canManageAccounts)
      this.users = await this.executeServiceCall<User[]>("users/getUsers");
  }

}