import { JQueryPropertyBinding } from "../jqueryBinding";

export class SliderBinding extends JQueryPropertyBinding<string> {
  public sliderOptions!: SliderOptions;
  public sliderObject!: JQuery;

  //function() { return this.rootElement.find(selector).addBack(selector).slider("getValue") },
  //function(value: string) { this.rootElement.find(selector).addBack(selector).attr("data-slider-value", value).slider('setValue', value); }

  public getBoundValue(boundElements: JQuery): string {
    // TODO: Is this the correct data type?
    // HACK: Our declaration file seems to be incorrect so we have to do some added casting
    return (this.sliderObject ? boundElements.slider("getValue") as any : null) as string;
  }

  public setBoundValue(boundElements: JQuery, value: string): void {
    boundElements.attr("data-slider-value", value).slider('setValue', value);
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }

  public initialize(): void {
    super.initialize();
    let boundElements = this.getBoundElements();
    this.sliderObject = boundElements.slider(this.sliderOptions ? this.sliderOptions : {});
  }

  public destroy(): void {
    super.destroy();
    this.sliderObject.off("slide");
  }
}