import { JQueryPropertyBinding, JQueryReadOnlyPropertyBinding, JQueryBehavioralBinding, JQueryBinding, JQueryEventBinding } from "../jqueryBinding";

export interface SelectOption {
  text: string;
  value: string;
}

interface OptGroupSelect {
  text: string,
  children: any
}

interface LooseObject {
  [key: string]: any
}

function _valueInList(boundElements: JQuery, value: string): boolean {
  let exists = false;
  boundElements.find('option').each(function () {
    if ((this as HTMLOptionElement).value == value) {
      exists = true;
      return false;
    }
  });
  return exists;
}

export class SelectOptionsBinding extends JQueryPropertyBinding<SelectOption[]> {
  public getBoundValue(boundElements: JQuery): SelectOption[] {
    var result: SelectOption[] = [];
    boundElements.find('option')
      .each(function () {
        result.push({
          text: $(this).text(), value: $(this).val() as string
        });
      });
    return result;
  }
  public setBoundValue(boundElements: JQuery, value: SelectOption[]): void {
    // Get whatever value is in the selected value now and any previously unsettable value
    let currentValue = boundElements.val();
    let unselectableValue = boundElements.attr("jquery-unselectable-value");
    boundElements.removeAttr("jquery-unselectable-value");

    // Fill in the options from scratch
    boundElements.empty();
    value.forEach(x =>
      boundElements.append($('<option>', x))
    );

    // If our value list has anything in it, try to reset the value, if it existed; if it doesn't,
    // reset our unselectable value and try again on the next reload
    if (value.length) {
      // We'll try to put the value exactly once; if the caller tries to fill this in with a list
      // that doesn't support what we have we'll assume they want to clear it
      boundElements.val(currentValue || unselectableValue || "");
      if (currentValue !== unselectableValue) {
        boundElements.change();
      }
    }
    else {
      // There's no way any value we have is settable; if we have one, store it for later retrying
      if (currentValue) {
        boundElements.attr("jquery-unselectable-value");
      }
    }
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }
}

export class SelectedValueBinding extends JQueryPropertyBinding<string | undefined> {
  public getBoundValue(boundElements: JQuery): string | undefined {
    return boundElements.val() as string | undefined;
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    // If the requested value is in the list, set this normally; if not, set an alternate property
    // and let the paired options binding pick it up when the list resets
    if (_valueInList(boundElements, value)) {
      boundElements.val(value);
    }
    else if (value) {
      boundElements.attr("jquery-unselectable-value", value);
    }
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }
}

export class SelectedTextBinding extends JQueryReadOnlyPropertyBinding<string> {
  public getBoundValue(boundElements: JQuery): string {
    return boundElements.children("option:selected").text();
  }
}

function _isSelect2(boundElements: JQuery): boolean {
  return boundElements.hasClass("select2-hidden-accessible");
}

interface _Select2Binding extends JQueryBinding<any> {
  select2Initialized?: boolean;
}

interface _ListValueSource<TElement> {
  valueMapper: SelectControlValueMapper<TElement>;
}

// If this load of nonsense looks confusing to you, it's because it is - I can't find any
// documentation that indicates we are allowed to use regular Webpack/TypeScript includes
// or AMD patterns to get at the needed base classes
var Select2DataQueryAdapterPromise: any =
new Promise((resolve, reject) => {
  $.fn.select2.amd.require([
    'select2/data/array'
  ], function (ArrayData: any) {
    resolve(class Select2DataQueryAdapter extends ArrayData {
      public constructor($element: JQuery, options: any) {
        super($element, options);
      }
      public activeSearch: number | undefined;
      public query(params: {
        term?: string,
        context?: object
      },
      callback: (result: {
        results: { id: string, text: string }[],
        more: boolean,
        context?: object
      }) => void
      ) {
        let searchBinding = this.options.options._binding.getOtherSelectorBindings(ListSearchQueryBinding) as ListSearchQueryBinding<any>[];
        if (searchBinding.length === 1) {
          //If the search binding defines a delay timing, we will use it. 
          //Otherwise a 0 is returned, which means to call the function immediately. 
          clearTimeout(this.activeSearch);
          this.activeSearch = window.setTimeout(() => {
            searchBinding[0].executeSearch(params.term || "").then(results => callback({
              results, more: false
            }));
          }, searchBinding[0].getDelayTiming(), params, callback)
        }
        else {
          // TODO: Should this fail silently?
          throw new Error("A select control was configured for search without including a bound search method")
        }
      }
    });
  });
});
function _tryInitializeSelect2(binding: _Select2Binding): void {
  let boundElements = binding.getBoundElements();

  // If we're searchable we behave a bit differently
  let searchable = boundElements.attr("jquery-controller-searchable") !== undefined;

  // If the options we've received require select2 and no other binding has tried to initialize
  // the select2 control, do so now
  if ((boundElements.prop("multiple") || searchable) && !_isSelect2(boundElements)) {
    // The current binding is now responsible for the lifetime of the select2; set the correct
    // flags for that
    binding.select2Initialized = true;

    // If we were asked to support async searching, tie the binding to the search function - many
    // scenarios don't support this but we're too early in the initialization phase to reject this
    // request
    Select2DataQueryAdapterPromise.then((Select2DataQueryAdapter: any) => {
      let select2Args: any;
      if (searchable) {
        select2Args = {
          width: 'resolve',
          dataAdapter: Select2DataQueryAdapter,
          _binding: binding,
          allowClear: boundElements.attr("jquery-controller-allow-clear") !== undefined ? true : false, // This requires a placeholder
          placeholder: boundElements.attr("jquery-controller-placeholder-text")||''
        };
      }
      boundElements.select2(select2Args);
    });
  }
}

function _tryDestroySelect2(binding: JQueryBinding<any> & { select2Initialized?: boolean}): void {
  // We only destroy select2 here if we were tagged as the initial creator of the control
  var boundElement = binding.getBoundElements();
  if (binding.select2Initialized && _isSelect2(boundElement) ) {
    boundElement.select2("destroy");
    binding.select2Initialized = false;
  }
}

export class ListValueBinding<TElement, TValue extends TElement | TElement[] = TElement> extends JQueryPropertyBinding<TValue> {
  public select2Initialized?: boolean;

  public initialize(): void {
    super.initialize();
    _tryInitializeSelect2(this);
  }

  public destroy(): void {
    _tryDestroySelect2(this);
    super.destroy();
  }

  public getListQueryBinding(): ListQueryBinding<TElement> | null {
    let listQueryBindings = this.getOtherSelectorBindings(ListQueryBinding);
    switch (listQueryBindings.length) {
      case 0: return null;
      case 1: return listQueryBindings[0] as any as ListQueryBinding<TElement>;
      default: this.reportBindingError("More than one list query binding specified");
        // TODO: The compiler is incorrectly ignoring the "never" return type of the above call
        return null;
    }
  }

  public getListQuerySearchBinding(): ListSearchQueryBinding<any> | null {
    let listSearchQueryBindings = this.getOtherSelectorBindings(ListSearchQueryBinding);
    switch (listSearchQueryBindings.length) {
      case 0: return null;
      case 1: return listSearchQueryBindings[0] as any as ListSearchQueryBinding<any>;
      default: this.reportBindingError("More than one list query binding specified");
        // TODO: The compiler is incorrectly ignoring the "never" return type of the above call
        return null;
    }
  }

  private _getValueMapper(): SelectControlValueMapper<TElement> | null {
    let queryBinding = this.getListQueryBinding() || this.getListQuerySearchBinding();
    if (queryBinding) return queryBinding.valueMapper; else return null;
  }

  public getBoundValue(boundElements: JQuery<HTMLSelectElement>): TValue {
    let listValueSource = this._getValueMapper();
    if (listValueSource) {
      return listValueSource.getCurrentListValue(boundElements) as TValue;
    }
    else {
      return boundElements.val() as unknown as TValue;
    }
  }
  public setBoundValue(boundElements: JQuery<HTMLSelectElement>, value: TValue): void {
    let listValueSource = this._getValueMapper();
    if (listValueSource) {
      listValueSource.setCurrentListValue(boundElements, value);
    }
    else {
      boundElements.val(value as any);
    }
  }

  public async executeSearch(searchTerm: string): Promise<{ id: string, text: string }[]> {
    let listSearchBinding = this.getListQuerySearchBinding();
    if (listSearchBinding) {
      return await listSearchBinding.executeSearch(searchTerm);
    }
    else {
      // TODO: Make sure this scenario isn't necessary
      throw new Error("Explicitly declaring search on a static select control is unsupported");
      // // Get a list of all of the options, return them to the caller after filtering
      // let returnValue = [] as { id: string, text: string }[];
      // for (let element of (this.getBoundElements() as JQuery<HTMLOptionElement>).find("option")) {
      //   // TODO: Flesh this search criteria out?
      //   if (element.text.startsWith(searchTerm)) {
      //     returnValue.push({ id: element.value, text: element.text });
      //   }
      // }
      // return returnValue;
    }
  }
}

export class SelectControlValueMapper<TElement> {
  public currentListItemsInDisplayOrder: { key: string, item: TElement }[] = [];
  public currentListItemsBySelectKey: { [key: string]: TElement } = {};
  public nextIndex: number = 1;

  public constructor(
    public captionSelector: (value: TElement) => string,
    public itemEqualityComparer: (a: TElement, b: TElement) => boolean
  ) {}

  public setCurrentListItems(items: TElement[]): void {
    this.currentListItemsInDisplayOrder = [];
    this.currentListItemsBySelectKey = {};
    this.addListItems(items);
  }

  public addListItems(items: TElement[]): { key: string, item: TElement }[] {
    let returnValue: { key: string, item: TElement }[] = [];
    let returnValueItem: { key: string, item: TElement };
    for (let item of items) {
      // If the item is already in our list, add it; if not, append it
      let existingItemIndex = this.currentListItemsInDisplayOrder.findIndex(
        x => this.itemEqualityComparer(x.item, item));
      if (existingItemIndex === -1) {
        let key = (this.nextIndex++).toString();
        returnValueItem = { key, item };
        this.currentListItemsInDisplayOrder.push(returnValueItem);
        this.currentListItemsBySelectKey[key] = item;
      }
      else {
        returnValueItem = this.currentListItemsInDisplayOrder[existingItemIndex];
        returnValueItem.item = item;
        this.currentListItemsBySelectKey[returnValueItem.key] = item;
      }
      returnValue.push(returnValueItem);
    }
    return returnValue;
  }

  public getCurrentListValue(boundElements: JQuery<HTMLSelectElement>): TElement | TElement[] | undefined {
    if (!this.currentListItemsBySelectKey) return undefined;

    // We return an array or a regular item based on whether this is a single or multiple select
    if (boundElements.prop("multiple")) {
      return this._getCurrentMultipleListValue(boundElements);
    }
    else {
      return this._getCurrentSingleListValue(boundElements);
    }
  }
  public _getCurrentMultipleListValue(boundElements: JQuery<HTMLSelectElement>): TElement[] | undefined {
    let returnValue = boundElements.val();
    if (Array.isArray(returnValue)) {
      return returnValue.map(x => this.currentListItemsBySelectKey![x]);
    }
    else if (returnValue !== undefined) {
      return [this.currentListItemsBySelectKey[returnValue]];
    }
    else {
      return undefined;
    }
  }
  public _getCurrentSingleListValue(boundElements: JQuery<HTMLSelectElement>): TElement | undefined {
    let returnValue = boundElements.val();
    if (Array.isArray(returnValue) && returnValue.length === 1) {
      return this.currentListItemsBySelectKey[returnValue[0]];
    }
    else if (!Array.isArray(returnValue)) {
      return this.currentListItemsBySelectKey[returnValue as string];
    }
    else {
      return undefined;
    }
  }

  public setCurrentListValue(boundElements: JQuery<HTMLSelectElement>, value: TElement | TElement[]): void {
    // We allow basically anything for values, so there's no easy way to create a proper dictionary
    // with the values as keys
    const _getKeyOfItem = (item: TElement): string => {
      for (let otherItem of this.currentListItemsInDisplayOrder) {
        if (this.itemEqualityComparer(otherItem.item, item)) {
          return otherItem.key;
        }
      }

      // Unfound items are assumed to mean "select nothing" for the time being; this makes it easy
      // to implement "select nothing right now" logic and gives us something productive to do for
      // this situation
      return "";
    }

    // We may or may not have a multiple select box, but we won't bother to check that here and
    // we'll assume developers aren't intentionally getting past compile time checks
    if(value == null) {
      boundElements.val("");
    } else if (Array.isArray(value)) {
      boundElements.val(value.map(x => _getKeyOfItem(x)));
    }
    else {
      var key = _getKeyOfItem(value)
      if(key)
      {  
        boundElements.val(key);
      } else  {
        // This allows assigning value of something not in the list
        // Adds the item to the list then selects it
        let key = (this.nextIndex++).toString();
        var returnValueItem = { key, item: value as TElement };
        this.currentListItemsInDisplayOrder.push(returnValueItem);
        this.currentListItemsBySelectKey[key] = value;
        boundElements.append(new Option(this.captionSelector(returnValueItem.item), returnValueItem.key, false, true))
      } 
    }

    // Let input listeners know that we've changed
    // TODO: We have a story (20482) to properly handle change events and avoid circular event references
    boundElements.trigger("change");
  }
}

export class ListQueryBinding<TElement> extends JQueryBehavioralBinding<Promise<TElement[]>> {
  public captionSelector!: (value: TElement) => string;
  public equalityComparer?: (a: TElement, b: TElement) => boolean;

  private _onScreenListItems?: HTMLOptionElement[];

  private _listLoadingOptionValue?: string;

  public valueMapper!: SelectControlValueMapper<TElement>;

  public initialize(): void {
    super.initialize();

    // If we're the first to touch it, initialize the select2 control
    _tryInitializeSelect2(this);

    // Reset our currently understood values
    this.valueMapper = new SelectControlValueMapper<TElement>(this.captionSelector, this.equalityComparer || ((a, b) => a == b))

    // Get the loading indicator option if it exists
    let listLoadingOption = this.getBoundElements().find("[jquery-controller-list-loading-option]") as JQuery<HTMLOptionElement>;
    if (listLoadingOption.length) {
      this._listLoadingOptionValue =  listLoadingOption[0].value;
    }

    // Tag the element(s) with the appropriate busy indicator
    let boundElements = this.getBoundElements();
    boundElements.attr("jquery-controller-ready", "");
    boundElements.removeAttr("jquery-controller-busy");

    // Run the method the first time to trigger the first load; we need to do this outside
    // of the current execution stack because this is invoked during the base constructor
    // for JQueryController, and we probably don't have fully constructed objects yet (the
    // invoke method would execute on an incompletely constructed controller if we didn't
    // do this via a promise); we only do this if we are not searchable
    Promise.resolve().then(() => this.invoke());
  }

  public destroy(): void {
    // Destroy select2 if we were the first to touch it
    _tryDestroySelect2(this);
    super.destroy();
  }

  public async invokeMethodWithBehavior(targetThis: any, method: Function, args: any[]): Promise<TElement[]> {
    let boundElements = this.getBoundElements() as JQuery<HTMLSelectElement>;

    // Get the currently selected value on-screen
    let currentValue = this.valueMapper.getCurrentListValue(boundElements);

    // If the list has a tagged entry to show when loading, show it now; if not, just clear the
    // value
    boundElements.val(this._listLoadingOptionValue || "");

    // Invoke the method to get the results; if it's async, wait for it
    let results = method.apply(targetThis, args);
    if (results instanceof Promise) {
      boundElements.attr("jquery-controller-busy", "");
      boundElements.removeAttr("jquery-controller-ready");
      results = await results;
      boundElements.attr("jquery-controller-ready", "");
      boundElements.removeAttr("jquery-controller-busy");
    }

    // Fill in the new current list of items
    this.valueMapper.setCurrentListItems(results);

    // Populate the related on-screen list with these options; the bound element will be a select
    // control; because we may end up with different element orders and we don't really need to
    // overcomplicate this we'll just recreate the values from scratch
    if (this._onScreenListItems) {
      for (let item of this._onScreenListItems) {
        item.remove();
      }
    }
    this._onScreenListItems = [];
    for (let item of this.valueMapper.currentListItemsInDisplayOrder!) {
      let newElement = document.createElement("option");
      newElement.value = item.key;
      newElement.text = this.captionSelector(item.item);
      boundElements.append(newElement);
      this._onScreenListItems.push(newElement);
    }

    // Reset any currently selected value; undefined means the same as an empty array
    if (currentValue === undefined || Array.isArray(currentValue) && currentValue.length === 0) {
      boundElements.val("");
    }
    else {
      this.valueMapper.setCurrentListValue(boundElements, currentValue);
    }

    // Return what we queries
    return results;
  }
}


export class ListSearchQueryBinding<TElement> extends JQueryEventBinding {
  public captionSelector!: (value: TElement) => string;
  public equalityComparer?: (a: TElement, b: TElement) => boolean;
  public getOptGroup ?: (a: TElement) => string;
  public delayTimingMS ?: number;
  public valueMapper!: SelectControlValueMapper<TElement>;

  public initialize(): void {
    super.initialize();

    // If we're the first to touch it, initialize the select2 control
    _tryInitializeSelect2(this);

    // Create a place for us to store and track any found searched values
    this.valueMapper = new SelectControlValueMapper<TElement>(this.captionSelector, this.equalityComparer || ((a, b) => a == b))
  }

  public destroy(): void {
    // Destroy select2 if we were the first to touch it
    _tryDestroySelect2(this);
    super.destroy();
  }

  public async executeSearch(searchTerm: string): Promise<any[]> {
    // Invoke the method to get the results; if it's async, wait for it
    let results = this.invokeHandler(searchTerm);
    if (results instanceof Promise) {
      // TODO: Do we want to explicitly support our own notion of busy while this is running?
      // boundElements.attr("jquery-controller-busy", "");
      // boundElements.removeAttr("jquery-controller-ready");
      results = await results;
      // boundElements.attr("jquery-controller-ready", "");
      // boundElements.removeAttr("jquery-controller-busy");
    }

    // Items that we've received become part of a larger collection that we'll need to map items
    // to, add them to our known list for later
    let mappedItems = this.valueMapper.addListItems(results);
    let mappedReturns = [];
    // Return what we mapped in the required format
    if (this.getOptGroup){
      mappedReturns = this.getOptGroupStructure(mappedItems);
    } else {
      mappedReturns =  mappedItems.map(x => { return { id: x.key, text: this.captionSelector(x.item) } });  
    }
    return mappedReturns;
  }

  //This assumes that the items you are passing in from will be in the form of 
  //{key, item} and that item will have a Source property on it. 
  public getOptGroupStructure(data: { key: string, item: TElement }[]): OptGroupSelect[]{
    let displayArr:OptGroupSelect[] = [];
    let groupedSourceObj: LooseObject = {};
    //Return an empty array if the caller didn't provide an optgroup function.
    if (!this.getOptGroup){
      return displayArr;
    }
    data.forEach((x) => {
      //We've already done the check above to ensure that it is defined.
      if (groupedSourceObj[this.getOptGroup!(x.item) as any] == undefined){
        groupedSourceObj[this.getOptGroup!(x.item)] = {
                 text: this.getOptGroup!(x.item),
                 children: []
        };
      }
      groupedSourceObj[this.getOptGroup!(x.item) as any].children.push({
        id : x.key,
        text: this.captionSelector(x.item)
      })
    });

    for (var key in groupedSourceObj){
      displayArr.push(groupedSourceObj[key]);
    }
    return displayArr;
  }

  public getDelayTiming():number {
    return this.delayTimingMS ? this.delayTimingMS :  0;
  }
}