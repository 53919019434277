export class HttpException extends Error {
    
    public readonly httpRequest: XMLHttpRequest;    
    public readonly status: number;
    public readonly errorPayload: any;
    
    constructor(httpRequest: XMLHttpRequest, errorPayload?: any) {
        if(errorPayload instanceof Error) {
            super(errorPayload.message);
        } else if (errorPayload && errorPayload.ErrorMessage){
            super(errorPayload.ErrorMessage)
        }
        else {
            super();
        }
        this.httpRequest = httpRequest;
        this.status = httpRequest.status;
        this.errorPayload = errorPayload;
    }
}