import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { getPasswordHash } from "../model/authentication";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { PersonSingleQuery, PersonListingQuery } from "../model/person"
import { currentUser } from "../shared/currentUser"
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { PaginatedResults } from "model/pagination";
import { SelectOption } from "model/select";
import { Province } from "model/referenceData";
import { Company } from "model/company";
import { DateTime } from "shared/datetime";
let datatablesResponsive = require('datatables.net-responsive')

interface PersonDetails {
  PersonID?: string,
  FirstName: string,
  LastName: string, 
  PhoneNumber: string,
  Email: string,
  Position: string,
  CurrentEmployee: boolean,
  UnionLocal: string,
  RegistrationNumber: string,
  UnionStatus: string,
  Friendly: boolean,
  Street1: string,
  Street2: string,
  PostalCode: string,
  City: string,
  ProvinceID: string | undefined,
  ExternalPerson?: boolean 
}

interface PersonDocumentsDetails {
  PersonID: string,
  ImageURL: string, 
}

interface AddPersonResponse {
  PersonID: string
}

interface CardDetails {
  CardID: string;
  NoteID?: string;
  CompanyName: string
  EffectiveDate: Date;
  ExpiryDate: Date;
}

interface SignatureDetails {
  ImageURL: string;
  TransactionNumber: string;  
  SignedDate: Date;
  Company: string;
  IsVerified: string;
  VerifiedDate: Date;
  PersonDocumentID: string;
  PersonID: string;
}
interface DeleteSignatureDetails {
  PersonDocumentID: string;
}
interface Expired {
  ExpiryDate: Date;
  DateMessage: string;
  IsExpired: boolean;
}

interface SignatureEmailSend {
  EmailAddress: string;
  PersonName: string;
  ExpiredOn: string;
}

interface VerifyEmailRequest {
  EmailAddress: string;
  PersonName: string;
  PersonDocumentID: string;
}
interface NewEmailRequest {
  EmailAddress: string;
  PersonName: string;
}
interface MemberEmailInUse {
  PersonName: string;
  PersonID: string;
}
interface OeSystemComment {
  Comment: string;
  CreatedBy: string;
  CreatedOn: Date;
}

interface OeSystemComments {
  Critical: OeSystemComment[];
  NonCritical: OeSystemComment[];
}


export class PersonSingleController extends JQueryController {
  // View
  @JQueryController.bindVisibility('#new-person-header') public newPersonHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#update-person-header') public updatePersonHeaderVisibility!: boolean;
  @JQueryController.bindDisplayText("#cookieName") public cookieName!: string;

  @JQueryController.bindVisibility(".new-person-button") public newPersonButtonVisibility: boolean;
  @JQueryController.bindVisibility(".edit-person-button") public editPersonButtonVisiblity: boolean;
  
  @JQueryController.bindAttribute('#signed-card-image', 'src') public imageUrlSrc!:string;
  @JQueryController.bindAttribute('#image-url-link', 'href') public imageUrlLink!:string;
  @JQueryController.bindAttribute('#image-download-link', 'href') public imageDownloadLink!:string;
  
  // General
  @JQueryController.bindInputValue("#firstname") public firstName!: string;
  @JQueryController.bindInputValue("#lastname") public lastName!: string;
  @JQueryController.bindInputValue("#phone") public phone!: string;
  @JQueryController.bindInputValue("#email") public emailAddress!: string;

  // Union
  @JQueryController.bindInputValue("#position") public position!: string;
  @JQueryController.bindInputChecked("#current-employee") public currentEmployee!: boolean;
  @JQueryController.bindInputValue("#union-local") public unionLocal!: string;
  @JQueryController.bindInputValue("#registration-number") public registrationNumber!: string;
  @JQueryController.bindInputValue("#union-status") public unionStatus!: string;
  @JQueryController.bindInputChecked("#friendly") public friendly!: boolean;

  // Address
  @JQueryController.bindInputValue("#street1") public street1!: string;
  @JQueryController.bindInputValue("#street2") public street2!: string;
  @JQueryController.bindInputValue("#postal-code") public postalCode!: string;
  @JQueryController.bindInputValue("#city") public city!: string;
  @JQueryController.bindSelectOptions("#province") public provinceOptions!: SelectOption[]
  @JQueryController.bindInputValue('#province') public provinceID?: string;

  // Company
  @JQueryController.bindInputValue("#branch-location") public branchLocation!: string;
  @JQueryController.bindInputValue("#locked-id") public lockedID!: string;
  
  // Validation
  @JQueryController.bindParsleyValidation("#main-form") public isMainFormValid!: boolean;

  @JQueryController.bindRepeatingController("#person-critical-comment") public criticalComments!: OeSystemComment[];
  
  @JQueryController.bindRepeatingController("#person-non-critical-comment") public nonCriticalComments!: OeSystemComment[];
  @JQueryController.bindVisibility("#oesystem-comments") public oeSystemCommentSectionVisibility: boolean = false;
  // Cards
  @JQueryController.bindVisibility("#cards-grid") public cardsGridVisisblity = false;

  @JQueryController.bindDisplayText("#expired-note") public expiredNote!: string;
  @JQueryController.bindDisplayText("#expired-on-date") public expiredOnDate!: string;
  @JQueryController.bindDisplayText("#expired-date-btn") public expiredDateBtn!: string;
  @JQueryController.bindVisibility("#expired-signature-visible") public expiredSignatureVisibility: boolean;
  @JQueryController.bindVisibility("#expired-button-visible") public expiredButtonVisibility: boolean; 
  
  @JQueryController.bindDisplayHtml("#ext-person-email") public extPersonEmail!: string;
  @JQueryController.bindDisplayText("#verify-note") public verifyNote!: string;
  @JQueryController.bindDisplayText("#verify-btn") public verifyBtn!: string;
  @JQueryController.bindVisibility("#verify-visible") public verifyVisibility: boolean;
  @JQueryController.bindVisibility("#verify-button-visible") public verifyButtonVisibility: boolean; 
  @JQueryController.bindAttribute(".btn-delete", "data-target") public modalBind!: string; 
  @JQueryController.bindAttribute(".modal", "id") public modalWrapperBind!: string; 
  @JQueryController.bindAttribute(".btn-success-modal", "id") public successModalBind!: string; 
  
  @JQueryController.defineDataTableColumn("ImageURL",  {    
    createdCell: function(cell: any, cellData: any, rowData: any, row: number, col: number){     
      if(cellData){
        var image = new Image();
        var src = cellData;
        image.src = src
        image.width = 250;
        cell.innerHTML += cell.innerHTML.replace(cellData, 'Pdf');   
        var imgAdd = cell.innerHTML.replace(cellData, image.outerHTML);                     
        cell.insertAdjacentHTML('beforeend', imgAdd);
        }        
     }     
     , className: "TTF"
  })
  @JQueryController.defineDataTableColumn("IsVerified")
  @JQueryController.defineDataTableColumn("TransactionNumber")  
  @JQueryController.defineDataTableColumn("SignedDate")
  @JQueryController.defineDataTableColumn("Company")
  @JQueryController.defineDataTableColumnFromTemplate("PersonDocumentID", "#inventory-pushed-column-template")
  @JQueryController.bindDataTable("#cards-table", {
    responsive: {
      details: { 
        //type:'dtr-inline',       
        display: datatablesResponsive.display.modal({          
          header: function(row: any){           
              var data = row.data();                     
                if(data.SignedDate != "Completed") {
                  return '<h6>Signed Date: '+data.SignedDate.toDateString()+'</h6>'+'<h6>Transaction: $2.00 - '+data.TransactionNumber+'</h6>'+'<img width="400" src="'+data.ImageURL+'"/>'
                }
              },
          })
          , render: datatablesResponsive.renderer.tableAll( {            
            tableClass: 'table'
          })
          , renderer: function(url: any, type: any, full: any) {
            $('#cards-table').addClass('dtr-inline');
            $('#cards-table').addClass('collapsed');
            var personDocuments: any = $('.personDocuments');
            var transactionRef: any = document.getElementById('locked-id');            
            for (var i = 0; i < personDocuments.length; i++) {            
                if (personDocuments[i].id == transactionRef.defaultValue) {
                  personDocuments[i].classList.add('disabled');              
                }
            }
          }
        }
        , breakpoints: [
          {name: 'bigdesktop', width: Infinity},
          {name: 'meddesktop', width: 1480},
          {name: 'smalldesktop', width: 1280},
          {name: 'medium', width: 1188},
          {name: 'tabletl', width: 1024},
          {name: 'btwtabllandp', width: 848},
          {name: 'tabletp', width: 768},
          {name: 'mobilel', width: 480},
          {name: 'mobilep', width: 320}
        ]
        , render : function ( ) {
            $('#cards-table').addClass('dtr-inline');
            $('#cards-table').addClass('collapsed');            
        }       
      }
    , scrollCollapse: true
    , scrollX: true  
    //, order: [[3,"desc"]]  
    , columnDefs: [      
      { responsivePriority: 0, targets: 0 } //Img
      ,{ responsivePriority: 1, targets: 1 } //Transcation
      ,{ responsivePriority: 2, targets: 2 } //Date Submitted 
      ,{ responsivePriority: 3, targets: 3 } //Company      
      ,{ width: "25%", targets: 0 } 
    ]

  })
  public signatures: SignatureDetails[] = [];
  public expiredInfo: Expired = {
    DateMessage: "",
    IsExpired: false,
    ExpiryDate: new Date(),
  };
  private _personID?: string;
  private personDocumentID?: string;
  private _personDocumentID?: string;
  
  private transactionPersonDocumentID?: string;
  private _cleanPerson: PersonDetails = {
    PersonID: "",
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    Email: "",
    Position: "",
    CurrentEmployee: false,
    UnionLocal: "",
    RegistrationNumber: "",
    UnionStatus: "",
    Friendly: false,
    Street1: "",
    Street2: "",
    PostalCode: "",
    City: "",
    ProvinceID: ""
  };
  public comments: OeSystemComments = {Critical: [], NonCritical: []};
 
  public isCriticalCommentGrey = false;
  public isNonCriticalCommentGrey = false;
  constructor(rootElement: JQuery<HTMLElement>, personID?: string) {
    super(rootElement);
    this.newPersonButtonVisibility = false;
    this.editPersonButtonVisiblity = false;
    this.expiredSignatureVisibility = false;
    this.verifyVisibility = false;
    this.verifyButtonVisibility = false;

    this.expiredButtonVisibility = false;
    var query = QueryParser.getQuery<PersonSingleQuery>(false);

    this.populateSelects().then(() => {
      if(query && query.personID && currentUser) {
        this._personID = query.personID;
        this.LoadMemberInformation();        
        //this.LoadPersonImageURL();
        this.setNewUserVisiblity(false);
        this.setHeader(false);
      } else {
        this.cookieName = "New Person";
        this.setHeader(true);
        this.setNewUserVisiblity(true);
      }
    });
  }
  
  public validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private async populateSelects() {
    await this.populateProvinceSelect();
  }

  private async populateProvinceSelect() {
    this.provinceOptions = (await executeServiceCall<Province[]>('referenceData/getProvinces')).map(x => { return { text: x.Province, value: x.ProvinceID }})
  }

  public isdisabled(row: any): boolean {
    return row.VerifiedDate == null ? false : true
  }

  public async signaturesExists(emails: any) {
    var note = 'This email is in use. Click to ';
    for (var i = 0; i < emails.length; i++) {
      if (emails[i].PersonName != null) {
        note += ' <a style=\'color:white\' href=\'/person-single.html?personID='+ emails[i].PersonID + '\'>Edit '+ emails[i].PersonName + '</a> ';
      }
    }    

    this.extPersonEmail = note;
    JQueryMessageController.showMessage("email-error");    
  }

  public async signaturesNotExists(emails: any) {
    
    this.extPersonEmail = '';
    JQueryMessageController.hideAllMessages();    
  }

  private setHeader(isNew: boolean) {
    this.newPersonHeaderVisibility = isNew;
    this.updatePersonHeaderVisibility = !isNew;
  }
  
  private setNewUserVisiblity(isNewUser: boolean) {
      this.editPersonButtonVisiblity = !isNewUser;
      this.newPersonButtonVisibility = isNewUser;
  }

  private setUIValues(person: PersonDetails) {
    this.firstName = person.FirstName;
    this.lastName = person.LastName;
    this.cookieName = `${this.firstName} ${this.lastName}`
    this.phone = person.PhoneNumber;
    this.emailAddress = person.Email;
    this.position = person.Position;
    this.currentEmployee = person.CurrentEmployee;
    this.unionLocal = person.UnionLocal;
    this.registrationNumber = person.RegistrationNumber;
    this.unionStatus = person.UnionStatus;
    this.friendly = person.Friendly;
    this.street1 = person.Street1;
    this.street2 = person.Street2;
    this.postalCode = person.PostalCode;
    this.city = person.City;
    this.provinceID = person.ProvinceID;
    this._cleanPerson = {...person};
  }

  public async deleteSignatureConfirm() {
    this.executeServiceCall<DeleteSignatureDetails[]>("signature/removeSignatureByPersonDocument", {personDocumentID: this._personDocumentID})
      .then((signatures) => {       
        this.LoadMemberInformation();
        JQueryMessageController.showMessage("signature-removed");
      }).catch((error) => { 
        JQueryMessageController.showMessage("signature-removed-error") 
      });
  }

  public async deleteClick(event: any, signatureDetails: SignatureDetails) {
    try {
      this._personDocumentID = signatureDetails.PersonDocumentID;
      var y: any = $('.modal');
      y.on('show.bs.modal', function() {}).modal('show');    
    } catch (error) {   
      throw error;
    }
  }

  public async LoadMemberInformation() {
    var userInformation = await this.executeServiceCall<PersonDetails>("persons/getPerson",
    {
      personID: this._personID
    });
    this.setUIValues(userInformation);
    //make the cards grid visible.
    this.cardsGridVisisblity = true;
    this.executeServiceCall<SignatureDetails[]>("signature/getSignaturesByPerson", {personID: this._personID})
      .then((signatures) => {
        this.signatures = signatures;
        if(signatures.length >= 1)
        {
          var isVerified = false;
          for (var i = 0; i < signatures.length; i++) {
            if (signatures[i].VerifiedDate != null) {
              isVerified = true;
            }
            if(signatures[i].TransactionNumber.substring(0,3)== 'ch_')
            {
              this.lockedID = signatures[i].PersonDocumentID;
            }            
          }
          if(!isVerified) {            
            this.verifyVisibility = true;
            this.verifyNote = "Signature found but email was not verified"
            this.verifyButtonVisibility = true;
            this.verifyBtn = "Resend verification";
            this.personDocumentID = signatures[0].PersonDocumentID;
          }
        } else if(signatures.length == 0 || signatures == []) {
          this.verifyVisibility = true;
          this.verifyNote = "No signature. Send email to sign card?"
          this.verifyButtonVisibility = true;
          this.verifyBtn = "Send email";
        }
    });

    this.expiredInfo = await this.executeServiceCall<Expired>("signature/getExpiredSignatureDate", {personID: this._personID});

    if (userInformation.ExternalPerson && userInformation.RegistrationNumber != null){
      this.executeServiceCall<OeSystemComments>("persons/getOeSystemComments", {regID: userInformation.RegistrationNumber})
      .then((comments) => {
        this.comments = comments;
        this.criticalComments = this.comments.Critical;
        this.nonCriticalComments = this.comments.NonCritical;
        this.oeSystemCommentSectionVisibility = true;
      })
    }
    if(this.expiredInfo.IsExpired != null) {
      this.expiredSignatureVisibility = true;  
      this.expiredButtonVisibility = true;
      this.expiredOnDate = this.expiredInfo.ExpiryDate.toDateString();
      if(this.expiredInfo.IsExpired){
        this.expiredNote = this.expiredInfo.DateMessage;
        this.expiredNote += " - Expired on " + this.expiredInfo.ExpiryDate.toDateString();                  
      } else {
        this.expiredNote = this.expiredInfo.DateMessage;
        this.expiredNote += " - Expires on " + this.expiredInfo.ExpiryDate.toDateString();         
      }
    }
    
  }

  @JQueryController.bindClick("#cancel") 
  public cancelInput() {
    JQueryMessageController.hideAllMessages();   
    this.setUIValues(this._cleanPerson);    
  }

  @JQueryController.bindClick(".btn-cancel-modal") 
  public btnCancelSignature() {
    var y: any = $('.modal');
    y.on('hide.bs.modal', function() {}).modal('hide');
  }

  @JQueryController.bindClick(".btn-success-modal") 
  public btnSuccessSignature() {
    this.deleteSignatureConfirm();
  }
  
  @JQueryController.bindInputChange("#email") 
  public emailChanged() {
    var isEmail = this.validateEmail(this.emailAddress)
    if (this.emailAddress != null){      
      if(isEmail){
        this.executeServiceCall<MemberEmailInUse[]>("signature/checkExistingEmail", {personID: this._personID, emailAddress: this.emailAddress})
          .then((emails) => {
            if(emails.length >= 1){
              this.signaturesExists(emails)   
            } else {
              this.signaturesNotExists(emails)  
              
            }
          })
      }
    }
  }

  @JQueryController.bindClick("#cancelCreate") 
  public cancelCreatePerson() {
    window.location.href = '/persons.html'
  }

  @JQueryController.bindClick("#save")
  @JQueryController.bindValidation("#person-form")
  public async UpdatePerson() {
    if(this._personID) {
      this._cleanPerson = {
        PersonID: this._personID,
        FirstName: this.firstName,
        LastName: this.lastName,
        PhoneNumber: this.phone,
        Email: this.emailAddress,
        Position: this.position,
        CurrentEmployee: this.currentEmployee,
        UnionLocal: this.unionLocal,
        RegistrationNumber: this.registrationNumber,
        UnionStatus: this.unionStatus,
        Friendly: this.friendly,
        Street1: this.street1,
        Street2: this.street2,
        PostalCode: this.postalCode,
        City: this.city,
        ProvinceID: this.provinceID
      }
      
      var response = await this.executeServiceCall("persons/updatePerson",
      {
        person: this._cleanPerson
      });

      JQueryMessageController.showMessage("updated-person");
    }
  }

  @JQueryController.bindClick("#createPerson")
  @JQueryController.bindValidation("#person-form")
  public async CreatePerson(e: JQuery.Event): Promise<void> {
    var response = await this.executeServiceCall<AddPersonResponse>("persons/addPerson",
    {
      person: {
        FirstName: this.firstName,
        LastName: this.lastName,
        PhoneNumber: this.phone,
        Email: this.emailAddress,
        Position: this.position,
        CurrentEmployee: this.currentEmployee,
        UnionLocal: this.unionLocal,
        RegistrationNumber: this.registrationNumber,
        UnionStatus: this.unionStatus,
        Friendly: this.friendly,
        Street1: this.street1,
        Street2: this.street2,
        PostalCode: this.postalCode,
        City: this.city,
        ProvinceID: this.provinceID,
        BranchLocation: this.branchLocation
      }
    });

    this._personID = response.PersonID;
    this.setNewUserVisiblity(false);
    
    window.location.href = "/persons.html"+ QueryParser.BuildQueryString<PersonListingQuery>({createdPerson: `${this.firstName} ${this.lastName}`});
  }

  @JQueryController.bindClick("#expired-button-visible")
  public async SendSignatureToPerson() {
      var signatureEmailSend: SignatureEmailSend = {
        EmailAddress: this.emailAddress,
        PersonName: this.firstName + " " + this.lastName,
        ExpiredOn: this.expiredOnDate,
      }
      var response = await this.executeServiceCall<SignatureEmailSend>("api/sendSignatureToPerson",
      {
        signatureEmailSend: signatureEmailSend       
      });
      JQueryMessageController.showMessage("signature-sent-person");    
  }
  
  @JQueryController.bindClick("#verify-button-visible")
  public async SendVerifiedToPerson() {
    if(this.personDocumentID == "" || this.personDocumentID == undefined){
      var newEmailRequest: NewEmailRequest = {
        EmailAddress: this.emailAddress,
        PersonName: this.firstName + " " + this.lastName,
      }
      var response = await this.executeServiceCall<NewEmailRequest>("signature/newEmailVerification",
      {
        newEmailRequest: newEmailRequest       
      });
      JQueryMessageController.showMessage("new-sent-person");   
    } else {
      var veifiedEmailRequest: VerifyEmailRequest = {
        EmailAddress: this.emailAddress,
        PersonName: this.firstName + " " + this.lastName,
        PersonDocumentID: this.signatures[0].PersonDocumentID,
      }
      var responseRe = await this.executeServiceCall<VerifyEmailRequest>("signature/resendEmailVerification",
      {
        verifyEmailRequest: veifiedEmailRequest       
      });
      JQueryMessageController.showMessage("verify-sent-person");    
    }
  }
}

export class CriticalCommentsController extends JQueryController {
  @JQueryController.bindDisplayText(".critical-comment-date") public criticalCommentDate !: string;
  @JQueryController.bindDisplayText(".critical-comment-content") public criticalCommentContent !: string;
  @JQueryController.bindDisplayText(".critical-comment-author") public criticalCommentAuthor !: string;
  @JQueryController.bindAttribute('.critical-comment', 'style') public criticalCommentBackgroundColor !: string;
  public constructor(rootElement: JQuery, public parentController: PersonSingleController, public comment: OeSystemComment){
    super (rootElement);
    comment.CreatedOn = new Date(comment.CreatedOn);
    this.criticalCommentDate = "Date Created: " +  comment.CreatedOn.toLocaleDateString();
    this.criticalCommentContent = comment.CreatedBy + " wrote: " + comment.Comment;
    this.criticalCommentAuthor = comment.CreatedBy;
    if (parentController.isCriticalCommentGrey){
      parentController.isCriticalCommentGrey = false;
      this.criticalCommentBackgroundColor = "background-color: #f5f5f5; padding-bottom:0.5px; padding-top:0.5px"
    } else {
       parentController.isCriticalCommentGrey = true;
    }
  }
}

export class NonCriticalCommentsController extends JQueryController {
  @JQueryController.bindDisplayText(".non-critical-comment-date") public nonCriticalCommentDate !: string;
  @JQueryController.bindDisplayText(".non-critical-comment-content") public nonCriticalCommentContent !: string;
  @JQueryController.bindDisplayText(".non-critical-comment-author") public nonCriticalCommentAuthor !: string;
  @JQueryController.bindAttribute('.non-critical-comment', 'style') public nonCriticalCommentBackgroundColor !: string;
  
  public constructor(rootElement: JQuery, public parentController: PersonSingleController, public comment: OeSystemComment){
    super (rootElement);
    comment.CreatedOn = new Date(comment.CreatedOn);
    this.nonCriticalCommentDate = "Date Created: " + comment.CreatedOn.toLocaleDateString();
    this.nonCriticalCommentContent = comment.CreatedBy + " wrote: " + comment.Comment;
    this.nonCriticalCommentAuthor = comment.CreatedBy;
    if (parentController.isNonCriticalCommentGrey){
      parentController.isNonCriticalCommentGrey = false;
      this.nonCriticalCommentBackgroundColor = "background-color: #f5f5f5; padding-bottom:0.5px; padding-top:0.5px"
    } else {
       parentController.isNonCriticalCommentGrey = true;
    }
  }
}
