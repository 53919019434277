import * as echarts from "echarts";
import { JQueryBinding, JQueryPropertyBinding, JQueryBackedPropertyBinding } from "../jqueryBinding";
import { DimensionalModel } from "../serviceCalls";

// TODO: The old ECharts binding is deprecated
export class EChartsBinding extends JQueryPropertyBinding<echarts.EChartOption> {
  public echartsRef!: echarts.ECharts;
  private _resizeHandler!: (this: Window, ev: UIEvent) => void;

  public get suppressInitialValueRestoration(): boolean { return true; }

  public getBoundValue(boundElements: JQuery): echarts.EChartOption {
    return this.echartsRef.getOption();
  }
  public setBoundValue(boundElements: JQuery, value: echarts.EChartOption): void {
    if (boundElements[0] instanceof HTMLCanvasElement) {
      (boundElements[0] as HTMLCanvasElement).width = boundElements.width() as number;
      (boundElements[0] as HTMLCanvasElement).height = boundElements.height() as number;
    }
    this.echartsRef.setOption(value, true);
    setTimeout(() => this.handleResize(), 100);
  }

  public handleResize(): void {
    let boundElements = this.getBoundElements();
    if (this.echartsRef) {
      this.echartsRef.resize({
        width: boundElements.width(),
        height: boundElements.height()
      });
    }
  }

  public initialize(): void {
    this.echartsRef = echarts.init(this.getBoundElements()[0] as HTMLDivElement | HTMLCanvasElement);
    this._resizeHandler = () => this.handleResize();
    window.addEventListener("resize", this._resizeHandler)
    super.initialize();
  }

  public destroy(): void {
    super.destroy();
    window.removeEventListener("resize", this._resizeHandler);
    this.echartsRef.dispose();
  }
}

export enum ChartType {
  Unspecified = 0,
  BarChart, StackedBarChart, MultiBarChart, PieChart, MultiPieChart,
  GroupedStackedBarChart, GroupedMultiBarChart, GroupedMultiPieChart
}

export class ChartDataBinding extends JQueryBackedPropertyBinding<DimensionalModel | null> {
  private _chartType: ChartType = ChartType.Unspecified;
  private _echartsRef!: echarts.ECharts;
  private _resizeHandler!: (this: Window, ev: UIEvent) => void;

  private _handleResize(): void {
    let boundElements = this.getBoundElements();
    if (this._echartsRef) {
      this._echartsRef.resize({
        width: boundElements.width(),
        height: boundElements.height()
      });
    }
  }

  public initialize(): void {
    this._echartsRef = echarts.init(this.getBoundElements()[0] as HTMLDivElement | HTMLCanvasElement);
    this._resizeHandler = () => this._handleResize();
    window.addEventListener("resize", this._resizeHandler)
    super.initialize();
  }

  public destroy(): void {
    super.destroy();
    window.removeEventListener("resize", this._resizeHandler);
    this._echartsRef.dispose();
  }

  protected notifyBoundValueChanged(boundElements: JQuery, value: DimensionalModel | null): void {
    // Notify related bindings that we've been changed
    let typeSelectors = this.getOtherPropertyBindings(ChartTypeSelectorBinding);
    for (let typeSelector of typeSelectors) {
      typeSelector.updateChartTypeOptions(value);
    }

    // Update the charts if we have a selected chart type
    if (this._chartType !== ChartType.Unspecified) {
      this.updateChart();
    }
  }

  public updateChartType(chartType: ChartType): void {
    if (this._chartType != chartType) {
      this._chartType = chartType;
      this.updateChart();
    }
  }

  public updateChart(): void {
    // If we have no data, tear down the chart
    if (this._chartType === ChartType.Unspecified
      || !this.storedValue
      || this.storedValue.measures.length === 0) {
      return;
    }
    
    // Get the config for the current data and run it through ECharts
    let echartsConfig = this._getEChartsConfig(this.storedValue);
    let boundElements = this.getBoundElements();
    if (boundElements[0] instanceof HTMLCanvasElement) {
      (boundElements[0] as HTMLCanvasElement).width = boundElements.width() as number;
      (boundElements[0] as HTMLCanvasElement).height = boundElements.height() as number;
    }
    this._echartsRef.setOption(echartsConfig, true);
    setTimeout(() => this._handleResize(), 100);
  }

  private _getEChartsConfig(data: DimensionalModel): echarts.EChartOption {
    // We've named our methods within a certain standard using our enumeration as part of the
    // name; anything with a proper ChartType value will work
    let configMethodName = `_get${ChartType[this._chartType]}Config` as keyof ChartDataBinding;
    return (this[configMethodName] as Function)(data);
  }

  private _getBarChartConfig(data: DimensionalModel<[any]>): echarts.EChartOption {
    return {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow"
        }
      },
      color: this._createLegendColors(data.dimensions[0].length),
      legend: {
        data: data.dimensions[0]
      },
      xAxis: {
        type: "value"
      },
      yAxis: {
        type: "category",
        data: ["Total"],
        show: false
      },
      series: data.dimensions[0].map(
        dimension => {
          let rawValue = data.measures.find(
            measure => measure.dimensions[0] == dimension);
          return {
            name: dimension as unknown as string,
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "insideLeft",
                formatter: "{a} - {c}",
              }
            },
            data: [rawValue ? rawValue.value : 0]
          };
        }
      )
    };
  }
  
  private _getStackedBarChartConfig(data: DimensionalModel<[any, any]>): echarts.EChartOption {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      color: this._createLegendColors(data.dimensions[1].length),
      legend: {
        data: data.dimensions[1]
      },
      xAxis: {
        type: "value"
      },
      yAxis: {
        type: "category",
        data: data.dimensions[0]
      },
      series: data.dimensions[1].map(
        innerDimension => {
          return {
            name: innerDimension as unknown as string,
            type: "bar",
            stack: "stack",
            data: data.dimensions[0].map(
              outerDimension => {
                let rawValue = data.measures.find(
                  measure => measure.dimensions[0] == outerDimension && measure.dimensions[1] == innerDimension);
                return {
                  name: outerDimension,
                  value: rawValue ? rawValue.value : 0
                }
              }
            )
          };
        }
      )
    };
  }
  
  private _getMultiBarChartConfig(data: DimensionalModel<[any, any]>): echarts.EChartOption {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      color: this._createLegendColors(data.dimensions[1].length),
      legend: {
        data: data.dimensions[1]
      },
      xAxis: {
        type: "value"
      },
      yAxis: {
        type: "category",
        data: data.dimensions[0]
      },
      series: data.dimensions[1].map(
        innerDimension => {
          return {
            name: innerDimension as unknown as string,
            type: "bar",
            data: data.dimensions[0].map(
              outerDimension => {
                let rawValue = data.measures.find(
                  measure => measure.dimensions[0] == outerDimension && measure.dimensions[1] == innerDimension);
                return {
                  name: outerDimension,
                  value: rawValue ? rawValue.value : 0
                }
              }
            )
          };
        }
      )
    };
  }
  
  private _getPieChartConfig(data: DimensionalModel<[any]>): echarts.EChartOption {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      color: this._createLegendColors(data.dimensions[0].length),
      legend: {
        data: data.dimensions[0]
      },
      series: [{
          name: "Total",
          type: "pie",
          label: {
            normal: {
              show: false
            }
          },
          data: data.dimensions[0].map(
            dimension => {
              let rawValue = data.measures.find(
                measure => measure.dimensions[0] == dimension);
              return {
                name: dimension,
                value: rawValue ? rawValue.value : 0
              };
            }
          )
        }]
      };
  }
  
  // For limited numbers of pie placements we hard-code values
  private readonly _PerPiePlacements: { left: string, top: string, radius: string }[][] = [
    [{ left: "50%", top: "50%", radius: "80%"}],
    [
      { left: "25%", top: "50%", radius: "60%"},
      { left: "75%", top: "50%", radius: "60%"}
    ],
    [
      { left: "20%", top: "35%", radius: "50%"},
      { left: "50%", top: "65%", radius: "50%"},
      { left: "80%", top: "35%", radius: "50%"}
    ],
  ];
  
  private _getMultiPieChartConfig(data: DimensionalModel<[any, any]>): echarts.EChartOption {
    // Fill in some basics on pie placement; our defaults enable some nicer formatting than
    // just a straight grid would allow
    let pieWidth = 100 / data.dimensions[0].length;
    let perPiePlacement: { left: string, top: string, radius: string }[]
      = this._PerPiePlacements[data.dimensions[0].length - 1]
      || data.dimensions[0].map((outerDimension, index) => {
        return {
          left: `${pieWidth * index + pieWidth / 2}%`,
          top: "50%",
          radius: `${pieWidth}%`
        };
      });
  
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      color: this._createLegendColors(data.dimensions[1].length),
      legend: {
        data: data.dimensions[1]
      },
      title: data.dimensions[0].map((outerDimension, index) => {
        return {
          text: outerDimension,
          top: perPiePlacement[index].top,
          left: perPiePlacement[index].left,
          textAlign: "center",
          textStyle: {
            textBorderColor: "#000000",
            textBorderWidth: 3,
            color: "#FFFFFF"
          }
        };
      }) as any, // TODO: Need newer mapping?
      series: data.dimensions[0].map((outerDimension, index) => {
        return {
          name: "Total",
          type: "pie",
          radius: perPiePlacement[index].radius,
          center: [perPiePlacement[index].left, perPiePlacement[index].top],
          label: {
            normal: {
              show: false
            }
          },
          data: data.dimensions[1].map(
            innerDimension => {
              let rawValue = data.measures.find(
                measure => measure.dimensions[0] == outerDimension && measure.dimensions[1] == innerDimension);
              return {
                name: innerDimension,
                value: rawValue ? rawValue.value : 0
              };
            }
          )
        };
      })
    };
  }

  private _crossJoinDimensions(dimensionA: any[], dimensionB: any[]): any[] {
    let returnValue = [];
    for (let a of dimensionA) {
      for (let b of dimensionB) {
        returnValue.push(`${a} - ${b}`);
      }
    }
    return returnValue;
  }

  private _mergeDimensionalModel3To2(data: DimensionalModel<[any, any, any]>): DimensionalModel<[any, any]> {
    return {
      dimensions: [this._crossJoinDimensions(data.dimensions[0], data.dimensions[1]), data.dimensions[2]],
      measures: data.measures.map(x => {
        return {
          dimensions: [`${x.dimensions[0]} - ${x.dimensions[1]}`, x.dimensions[2]] as [any, any],
          value: x.value
        };
      })
    }
  }

  private _getGroupedStackedBarChartConfig(data: DimensionalModel<[any, any, any]>): echarts.EChartOption {
    return this._getStackedBarChartConfig(this._mergeDimensionalModel3To2(data));
  }

  private _getGroupedMultiBarChartConfig(data: DimensionalModel<[any, any, any]>): echarts.EChartOption {
    return this._getMultiBarChartConfig(this._mergeDimensionalModel3To2(data));
  }

  private _getGroupedMultiPieChartConfig(data: DimensionalModel<[any, any, any]>): echarts.EChartOption {
    return this._getMultiPieChartConfig(this._mergeDimensionalModel3To2(data));
  }
  
  private _createLegendColors(steps: number): string[] {
    let returnValue: string[] = [];
    let hueIncrement = 1 / steps;
    for (let i = 0; i < steps; i++) {
      let rawColor = this._hsvToRgb(i * hueIncrement, 1, 0.6);
      returnValue.push(`#${this._numberTo2DigitHex(rawColor.r)}${this._numberTo2DigitHex(rawColor.g)}${this._numberTo2DigitHex(rawColor.b)}`)
    }
    return returnValue;
  }
  
  private _numberTo2DigitHex(value: number): string {
    let rawReturnValue = value.toString(16);
    return rawReturnValue.length == 1 ? "0" + rawReturnValue : rawReturnValue;
  }
  
  private _hsvToRgb(h: number, s: number, v: number): { r: number, g: number, b: number } {
    let r: number, g: number, b: number, i: number, f: number, p: number, q: number, t: number;
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        default: r = v, g = p, b = q; break; // case 5
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    };
  }
}

export class ChartTypeSelectorBinding extends JQueryBinding<DimensionalModel | null> {
  private _addedChartTypes: HTMLOptionElement[] = [];
  private _handler!: (e: JQuery.Event) => void;

  public initialize(): void {
    super.initialize();
    this._handler = () => this.onChartTypeChange();
    this.getBoundElements().on("change", this._handler);
  }

  public destroy(): void {
    this.getBoundElements().off("change", this._handler);
    this._removeAddedChartTypes();
    super.destroy();
  }

  public onChartTypeChange(): void {
    // Let any active graph data binding know that the chart type is different
    let rawChartType = this.getBoundElements().val() as keyof typeof ChartType;
    let chartType = ChartType[rawChartType] || ChartType.Unspecified
    for (let chartDataBinding of this.getOtherPropertyBindings(ChartDataBinding)) {
      chartDataBinding.updateChartType(chartType);
    }
  }

  public updateChartTypeOptions(dimensionalModel: DimensionalModel | null): void {
    // Capture the existing value so we can try to set it back once we've refreshed the list
    let boundElement = this.getBoundElements() as JQuery<HTMLSelectElement>;
    let existingValue = boundElement.val() as string;

    // Replace all options; see if the existing value is still allowed
    this._removeAddedChartTypes();
    let existingValueStillAllowed = false;
    for (let option of this._getChartTypeOptions(dimensionalModel ? dimensionalModel.dimensions.length : 0)) {
      if (option.key == existingValue) {
        existingValueStillAllowed = true;
      }
      let htmlOption = document.createElement("option");
      htmlOption.text = option.caption;
      htmlOption.value = option.key;
      boundElement.append(htmlOption);
      this._addedChartTypes.push(htmlOption);
    }

    // Set the option to what it was if it's still available; if not, set it to nothing (this
    // lets the implementer provide a blank value of their own using their preferred styling)
    boundElement.val(existingValueStillAllowed ? existingValue : "");
  }

  private _getChartTypeOptions(dimensionCount: number): { key: string, caption: string }[] {
    switch (dimensionCount) {
      case 1: return [
        { key: ChartType[ChartType.BarChart], caption: "Bar" },
        { key: ChartType[ChartType.PieChart], caption: "Pie" }
      ];
      case 2: return [
        { key: ChartType[ChartType.StackedBarChart], caption: "Stacked Bar" },
        { key: ChartType[ChartType.MultiBarChart], caption: "Bar" },
        { key: ChartType[ChartType.MultiPieChart], caption: "Pie" }
      ];
      case 3: return [
        { key: ChartType[ChartType.GroupedStackedBarChart], caption: "Stacked Bar" },
        { key: ChartType[ChartType.GroupedMultiBarChart], caption: "Bar" },
        { key: ChartType[ChartType.GroupedMultiPieChart], caption: "Pie" }
      ];
      default: return [];
    }
  }

  private _removeAddedChartTypes(): void {
    for (let addedChartType of this._addedChartTypes) {
      addedChartType.remove();
    }
    this._addedChartTypes = [];
  }
}