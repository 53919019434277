import { JQueryBehavioralBinding } from "../jqueryBinding";

export interface MessageController {
  hideAllMessages(): void;
  showMessage(selector: string): void;
}

var _messageController: MessageController;

export function registerMessageController(messageController: MessageController): void {
  _messageController = messageController;
}

export class ErrorMessageBinding extends JQueryBehavioralBinding<Promise<any>> {
  public errorValidator?: (error: any) => boolean

  public async invokeMethodWithBehavior(targetThis: any, method: Function, args: any[]): Promise<any> {
    try {
      _messageController.hideAllMessages();
      return await method.apply(targetThis, args);
    }
    catch (error) {
      // HACK: Current TypeScript incorrectly detects this value as possibly undefined
      if (!this.errorValidator || this.errorValidator(error)) {
        _messageController.showMessage(this.selector);
      }
      throw error;
    }
  }
}

export class SuccessMessageBinding extends JQueryBehavioralBinding<Promise<any>> {
  public resultValidator?: (result: any) => boolean

  public async invokeMethodWithBehavior(targetThis: any, method: Function, args: any[]): Promise<any> {
    _messageController.hideAllMessages();
    let returnValue = await method.apply(targetThis, args);
    if (!this.resultValidator || this.resultValidator(returnValue)) {
      _messageController.showMessage(this.selector);
    }
    return returnValue;
  }
}

export class ValidationBinding extends JQueryBehavioralBinding<any> {
  public initialize() {
    super.initialize();
    this.getBoundElements().parsley();
  }
  public invokeMethodWithBehavior(targetThis: any, method: Function, args: any[]): any {
    if (this.getBoundElements().parsley().validate()) {
      return method.apply(targetThis, args);
    }
  }

  public destroy(): void {
    super.destroy();
    let parsley = this.getBoundElements().parsley();
    if (parsley)
      parsley.destroy();
  }
}

export class ResetFormBinding extends JQueryBehavioralBinding<any> {
  public invokeMethodWithBehavior(targetThis: any, method: Function, args: any[]): any {
    (this.getBoundElements()[0] as HTMLFormElement).reset();

    let parsley = this.getBoundElements().parsley();
    if (parsley)
      parsley.reset();

    return method.apply(targetThis, args);
  }
}

// TODO: The targetThis has no typing, is that dangerous?
export class BusyVisibilityBinding extends JQueryBehavioralBinding<Promise<any>> {
  public async invokeMethodWithBehavior(targetThis: any, method: Function, args: any[]): Promise<any> {
    try {
      targetThis.reverseVisibility(this.selector);
      return await method.apply(targetThis, args);
    }
    finally {
      targetThis.restoreVisibility(this.selector);
    }
  }
}