import { JQueryController } from "./jqueryController";
import { JQueryMessageController } from "./jqueryMessageController";
import { JQueryLoadingIndicator } from "./jqueryLoadingIndicator";

export interface RunningController<TResponse> {
  closeController(response?: TResponse): void;
  completionPromise: Promise<TResponse>;
}

export class JQueryRouter {
  // TODO:Do we want to handle "autoloading" differently?
  public static initializeAutoloadedControllers(): void {
    // Run garbage collection for any existing controllers
    JQueryController.runControllerGarbageCollection();

    // Initialize a new controller at all V1-style controller declarations
    for (let controllerTarget of jQuery("[jquery-controller-v1]")) {
      let controllerName = controllerTarget.getAttribute("jquery-controller-v1") as string;
      JQueryController.bindNewController(jQuery(controllerTarget), controllerName, []);
    }
    
    JQueryController.notifyAllControllersInitialized();
  }

  // This method needs to be refactored to something new - we needed to split the dependency
  // to properly link to messages and base controller functions, and we are needing to access
  // numerous private methods to make this work - basically, anything that needs conversion
  // to 'any' below is indicative of a problem
  // TODO: Refactor this to move all routing and construction outside of the JQueryController base class
  public static _showChildController<TResponse>(parentController: JQueryController, controllerName: string, ... args: any[]): RunningController<TResponse> {
    // Whenever we show a new child controller we should probably clear any messages
    // TODO: Is this the right place to do this work?
    //JQueryMessageController.hideAllMessages();

    // Find the place on-screen corresponding to the correct controller, then load it
    let childControllerElement = jQuery(`[jquery-child-controller-v1=${controllerName}]`);
    if (!childControllerElement.length) {
      throw new Error(`Template for child controller "${controllerName}" not found.`);
    }
    // HACK: Should we deal with multiple templates, either via error or allowing it?
    let controllerInfo = (JQueryController as any).bindNewController(childControllerElement, controllerName, args);
    (controllerInfo.controller as any)._parentController = parentController;

    // Show the child controller until it reports back to us that it's done
    let baseChildControllerPromise = new Promise<TResponse>((resolve, reject) => {
      (controllerInfo.controller as any)._parentControllerResultReporter = resolve;
    });
    let childControllerPromise = (async function () {
      try {
        childControllerElement.removeAttr("hidden");
        return await baseChildControllerPromise;
      }
      finally {
        childControllerElement.attr("hidden", "hidden");
        controllerInfo.controller.destroy();
      }
    })();

    // Return a complex object that allows the caller to additionally manipulate the controller
    return {
      closeController: controllerInfo.controller.returnResultToParent.bind(controllerInfo.controller),
      completionPromise: childControllerPromise
    }
  }

  protected setBreadcrumb(caption: string): void {
    throw new Error("TODO: Not done yet");
  }

  protected switchToController<TResult = void>(): Promise<TResult> {
    throw new Error("TODO: Not done yet");
  }

  public static showController<TResult = void>(controllerName: string, ... args: any[]): Promise<TResult> {
    // Whenever we show a new child controller we should probably clear any messages
    // TODO: Is this the right place to do this work?
    JQueryMessageController.hideAllMessages();

    // Find the place on-screen corresponding to the correct controller, then load it
    // TODO: Remove deprecated [jquery-child-controller-v1]
    let controllerElement = jQuery(`[jquery-controller-v1=${controllerName}], [jquery-child-controller-v1=${controllerName}]`);
    if (!controllerElement.length) {
      throw new Error(`Template for child controller "${controllerName}" not found.`);
    }
    // HACK: Should we deal with multiple templates, either via error or allowing it?
    // TODO: Don't depend on private properties - this should be exposed as a public method
    let controllerInfo = (JQueryController as any).bindNewController(controllerElement, controllerName, args);

    // Show the child controller until it reports back to us that it's done
    let baseControllerPromise = new Promise<TResult>((resolve, reject) => {
      (controllerInfo.controller as any)._parentControllerResultReporter = resolve;
    });
    let controllerPromise = (async function () {
      try {
        controllerElement.removeAttr("hidden");
        return await baseControllerPromise;
      }
      finally {
        controllerElement.attr("hidden", "hidden");
        controllerInfo.controller.destroy();
      }
    })();

    // Return a complex object that allows the caller to additionally manipulate the controller
    return controllerPromise;
  }

  protected showControllerModal<TResult = void>(): Promise<TResult> {
    throw new Error("TODO: Not done yet");
  }
}