import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { DropzoneController } from "../../web-uitoolkit/src/bindings/dropzone-v2";
import { NoZoneDate } from "@legacyx/web-uitoolkit/noZoneDate";
import { DateTime } from "../shared/datetime";
import { currentUser } from "../shared/currentUser";
import { SelectOption } from "model/select";

interface Inventory {
  InventoryID?: string;
  StockNumber: string;
  Make: string;
  Model: string;
  Year: number;
  //HACK: the html seems to only accept lowercase in their attributes
  dopush?: boolean;
  PushedDate?: string;
}

interface NonAJAXValue {
  text: string;
  id: string;
}

interface Drive {
  DriveID: string;
  Name: string;
  StartDate: string;
  EndDate?: string;
  NumberOfCompanies: boolean;
}

interface NoteRow {
  IsPrivate: boolean | null;
}

export class NotesListingController extends JQueryController {
  @JQueryController.bindVisibility("#media-note-section") public mediaNoteSectionVisibility: boolean = false;
  @JQueryController.bindInputValue("#media-note-comment") public mediaNoteComment!: string;
  @JQueryController.bindInputChecked('#private-note-check') public isPrivate!: boolean;
  @JQueryController.bindInputChange('#private-note-check')
      public privateNoteChange() {
        this.setPrivateVisability(this.isPrivate);
      };
  @JQueryController.bindAttribute('#media-note-section', 'class') public cardClass!:string;
  @JQueryController.bindAttribute('#media-note-section label, #media-note-section .card-subtitle', 'style') public labelStyle!:string;
  @JQueryController.bindSelectOptions("#drives") public driveOptions!: SelectOption[]
  @JQueryController.bindInputValue('#drives') public drive?: string;

  @JQueryController.defineDataTableColumn("User")
  @JQueryController.defineDataTableColumn("Person")
  @JQueryController.defineDataTableColumn("CompanyName")
  @JQueryController.defineDataTableColumn("DriveName")
  @JQueryController.defineDataTableColumnFromTemplate("IsPrivate", "#private-column")
  @JQueryController.defineDataTableColumnFromTemplate("NoteDate","#date-column")
  @JQueryController.defineDataTableColumnFromTemplate("NoteDate","#long-date-column")
  @JQueryController.defineDataTableColumnFromTemplate("NoteID", "#action-column-template")
  @JQueryController.bindDataTable("#notes-table", {
    dom:
    "<'row mai-datatable-header' "+
      "<'row col-sm-12 pr-0'<'col-sm-6 notes-drop'l><'col-sm-6 pr-0 dataTables_length messageTypeFilterDiv'f>>"+
      "<'row mt-3 col-sm-12 pr-0' "+
        "<'col-sm-6 user-select'>"+
        "<'col-sm-6 pr-0 person-select messageTypeFilterDiv'>"+
      ">" +
      "<'row mt-2 col-sm-12 pr-0'" +
        "<'col-sm-6 company-select'>"+
        "<'col-sm-6 pr-0 equipment-select messageTypeFilterDiv'>"+
      ">"+
      "<'row mt-2 col-sm-12 pr-0'"+
        "<'row no-gutters col-sm-6'" +
          "<'col-xxl-6 col-xl-12 from-date-filter'>" +
          "<'col-xxl-6 col-xl-12 pr-0 to-date-filter'>" +
        ">" +
        "<'col-sm-6 pr-0 drive-select messageTypeFilterDiv'>"+
      ">" +
    ">"+
      
    "<'row mai-datatable-body'<'col-sm-12'tr>>" +
    "<'row mai-datatable-footer'<'col-sm-5'i><'col-sm-7'p>>",
    responsive: {
      details: false
    },
    rowCallback: function(row, data){
      let noteData = <NoteRow> data;
      if (noteData.IsPrivate){
        $(row).css("background-color", "#837e82")
        $(row).css("color", "white"); 
      }
    },
    language: {
      search: "Search"
    },
    columnDefs: [
      { responsivePriority: 6, targets: 0 }, //User
      { responsivePriority: 3, targets: 1 }, //Person
      { responsivePriority: 2, targets: 2 }, //Company
      { responsivePriority: 4, targets: 3 }, //Drive
      { responsivePriority: 4, targets: 4 }, //Drive
      { responsivePriority: 2, targets: 5 }, //Date
      { responsivePriority: 1, targets: -1 } //Button
    ],
    order: [[6,"desc"]]
  })
  public inventory: Inventory[] = [];
  public drives: NonAJAXValue[] = [];
  private _userSelect!: JQuery<HTMLElement>;
  private _companySelect!: JQuery<HTMLElement>;
  private _personSelect!: JQuery<HTMLElement>;
  private _driveSelect!: JQuery<HTMLElement>;

  private _viewSetup: boolean = false;

  private setPrivateVisability(isPrivate: boolean){
    //this.personSelectorVisibility = isPrivate;
    if (isPrivate){
      this.cardClass = "card text-white bg-secondary card-border-color card-border-color-primary inner-form";
      this.labelStyle= "color:white";
    }
    else {
      this.cardClass = "card card-border-color inner-form-background-color inner-form";
      this.labelStyle = "color:#666666";
    }
  }
  constructor(rootElement: JQuery){
    super(rootElement);
    this.buildUserSelect();
    this.buildCompanySelect();
    this.buildPersonSelect();
    this.buildDateFilters();
    this.buildDriveSelect().then(() => {
      //This is a check to make sure my select2s are 
      // setup before calling populate table
      // incase one select loads before the others causeing populate table to be called
      if(currentUser){
        this.drive = currentUser.currentDriveID
      }
      this. _viewSetup = true;
      this._populateTable();
    });
    
    
  }

  private buildDateFilters() {
    var currentDate = new Date();
    var lastMonth = new Date();
    lastMonth.setMonth(currentDate.getMonth()-1);
    $('div.from-date-filter').append(`
    <label style="">
      From <input id="filter-from-date" type="text" style="display: inline-block; width: auto; margin-left: 24px" class="form-control form-control-sm datepicker">
    </label>`).find('input').datepicker('setDate',lastMonth);

    $('div.to-date-filter').append(`
    <label class="datepicker-notes-to-date">
      To <input id="filter-to-date" type="text" style="display: inline-block; width: auto; "class="form-control form-control-sm datepicker">
    </label>`).find('input').datepicker('setDate',currentDate);

    $('div.from-date-filter input').change(() => { this._populateTable() });
    $('div.to-date-filter input').change(() => { this._populateTable() });
  }
  @JQueryController.bindDatePickerValue('#filter-from-date', "NoZoneDate") public fromDateFilter?: NoZoneDate;
  @JQueryController.bindDatePickerValue('#filter-to-date', "NoZoneDate") public toDateFilter?: NoZoneDate;

  private _buildGenericSelect(selector: string, label: string, waterMark: string, url: string, processResults: (response: any) => Select2AjaxResponse) : JQuery<HTMLElement> {
    var newSelect = $('div'+selector).html(`
    <label style="width: 100%">
      ${label}
      <select style="width: 65%" class="form-control custom-select ">
      </select>
    </label>`).find('select')
    .select2({
      placeholder: waterMark,
      ajax: {
        url: url,
        delay: 200,
        minimumInputLength: 3,
        processResults: processResults,
        dataType: 'json'
      },
      allowClear: true,     
      multiple: true
    });
    newSelect.on("select2:select  select2:unselect ", () => {
      this._populateTable();
    })
    return newSelect;
  }

  private _buildNonAJAXSelect(selector: string, label: string, waterMark: string, values: NonAJAXValue[], defaultValue?: NonAJAXValue) :JQuery<HTMLElement> {
    var newSelect;
    if (defaultValue){
      newSelect = $('div'+selector).html(`
                    <label style="width: 100%">
                      ${label}
                      <select style="width: 65%" class="form-control custom-select ">
                        <option id=${defaultValue.id} value=${defaultValue.id} selected="selected">${defaultValue.text}</option>
                      </select>
                    </label>`).find('select')
    } else {
      newSelect = $('div'+selector).html(`
                    <label style="width: 100%">
                      ${label}
                      <select style="width: 65%" class="form-control custom-select ">
                      </select>
                    </label>`).find('select')
    }
    newSelect.select2({
      placeholder: waterMark,
      allowClear:true,
      multiple:true,
      data: values
    })
    newSelect.on("select2:select  select2:unselect ", () => {
      this._populateTable();
    })
    return newSelect;
  }

  public async buildDriveSelect() {
    await this.getDrives();
    //Need to grab the default
    let defaultValue = undefined
    if (currentUser){
      if (currentUser.currentDriveID){
        for(let i = 0;i<this.drives.length;i++){
          if(this.drives[i].id == currentUser.currentDriveID){
            defaultValue = this.drives[i]
          }
        }
      }
    } 
    this._driveSelect = this._buildNonAJAXSelect('.drive-select', 'Drive', 'Search for a drive', this.drives, defaultValue);
  }

  public async getDrives() {
    let drives = (await this.executeServiceCall<Drive[]>("drives/getDrives"))
    this.drives = drives.map(x => {
      return {id:x.DriveID, text:x.Name}
    })
    this.driveOptions = drives.map(x => {
      return {value: x.DriveID, text:x.Name}
    })
    
  }

  private buildUserSelect() : void {
    this._userSelect = this._buildGenericSelect('.user-select','User', 'Search for a user', '/services/notes/getUsersForSelect',
      function(response) {
        return {
          results: response.results.map((x: any) => {
            return {id: x.UserAccountID, text: `${x.FirstName} ${x.LastName}`};
          }),
          pagination : response.pagination
        }
      }
    );
  }

  private buildCompanySelect() : void {
    this._companySelect = this._buildGenericSelect('.company-select','Company', 'Search for a company', '/services/companies/getCompaniesForSelect',
      function(response) {
        return {
          results: response.results.map((x: any) => {
            return {id: x.CompanyID, text: x.CompanyName};
          }),
          pagination : response.pagination
        }
      }
    );
  }
  private buildPersonSelect() : void {
    this._personSelect = this._buildGenericSelect('.person-select','Person', 'Search for a person', '/services/notes/getPeopleForSelect',
      function(response) {
        return {
          results: response.results.map((x: any) => {
            return {id: x.PersonID, text:  `${x.FirstName} ${x.LastName}`};
          }),
          pagination : response.pagination
        }
      }
    );
  }

  public getDateString(requestTime: string): string {
    return DateTime.getDateString(new Date(requestTime));
  }

  public getLongDateString(requestTime: string): string {
    return DateTime.getDate24TimeString(new Date(requestTime));
  }

  private idPairToID(idTextPair: IdTextPair ) : string {
    return idTextPair.id;
  }

  private async _populateTable() {
    let userAccountID = "";
    if (currentUser){
      userAccountID = currentUser.userAccountID!
    }
    if(this._viewSetup) {
      var getNotesQuery = {
        userIDs: this._userSelect.select2('data').map(this.idPairToID),
        companyIDs: this._companySelect.select2('data').map(this.idPairToID),
        driveIDs: this._driveSelect.select2('data').map(this.idPairToID),
        personIDs: this._personSelect.select2('data').map(this.idPairToID),
        fromDate: this.fromDateFilter ? this.fromDateFilter.getServerDate() : null,
        toDate: this.toDateFilter ? this.toDateFilter.getServerDate() : null,
        currentUserID: userAccountID,
      };
      this.inventory = await this.executeServiceCall<Inventory[]>(`notes/getNotes`, getNotesQuery);
    }
  }

  @JQueryController.bindClick("#new-media-note-button") 
  public showMediaNoteSection() {
    this.mediaNoteSectionVisibility = true;
  }

  @JQueryController.bindDropzoneController("#media-note-dropzone", "/services/notes/addNoteFile", {
    autoProcessQueue: false,
    parallelUploads: 1,
    addRemoveLinks: true
  })
  public dropzone!: DropzoneController;

  @JQueryController.bindClick("#save-media-note-button")
  @JQueryController.bindValidation("#media-note-section")
  public async saveMediaNote() {

    // Ensure that the dropzone has files
    if(!(this.dropzone.dropzones[0].files.length > 0)) {
      JQueryMessageController.showMessage('no-files-error')
      return;
    } else
      JQueryMessageController.hideMessage('no-files-error')

    // Create new Note
    var newMediaNote = {
      comment: this.mediaNoteComment,
      isPrivate: this.isPrivate,
      driveID: this.drive,
    }

    var newMediaNoteID = await this.executeServiceCall<string>(`notes/createMediaNote`, newMediaNote);

    // After created note add files
    var result = await this.dropzone.sendFiles({noteID: newMediaNoteID});

    if(result.failed.length > 0)
      JQueryMessageController.showMessage("media-note-failed");
    else {
      this._populateTable();
      this.closeMediaNote();
    }
  }

  
  @JQueryController.bindClick("#cancel-media-note-button") 
  public closeMediaNote(){
    this.mediaNoteSectionVisibility = false;
    this.isPrivate = false;
    this.dropzone.dropzones.forEach((x: any) => x.removeAllFiles());
    this.mediaNoteComment = "";
    this.cardClass = "card card-border-color inner-form-background-color inner-form";
    this.labelStyle = "color:#666666";
    if(currentUser){
      this.drive = currentUser.currentDriveID
    }
    JQueryMessageController.hideAllMessages()
  }

  public GetPrivateMessage(isPrivate:boolean){
    if(isPrivate){
      return "Yes"
    } else {
      return "No"
    }
  }
}