﻿import { JQueryPropertyBinding } from "../jqueryBinding";

abstract class BasePhoneNumberBinding extends JQueryPropertyBinding<string | null> {

  protected formatPhoneNumber(numberString: string) {
    switch (numberString.length) {
      //(999) 999-9999
      case 10:
        numberString = `(${numberString.slice(0, 3)}) ${numberString.slice(3, 6)}-${numberString.slice(6, 10)}`;
        break;
      //1(999) 999-9999
      case 11:
        numberString = `${numberString.slice(0,1)}(${numberString.slice(1, 4)}) ${numberString.slice(4, 7)}-${numberString.slice(7, 11)}`;
        break;
    }

    return numberString;
  } 
}

export class PhoneNumberInputBinding extends BasePhoneNumberBinding{
  public getBoundValue(boundElements: JQuery): string | null {
    var value = boundElements.val() as string;
    return value ? value.replace(/\D/g, '') : null;
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    let numberString;

    if (value) {
      numberString = value.replace(/\D/g, '');

      if (numberString.length != 10 && numberString.length != 11)
        throw "Not a Valid Phone Number.";

      numberString = this.formatPhoneNumber(numberString);
    }

    boundElements.val(numberString || value);
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }
}

export class PhoneNumberDisplayBinding extends BasePhoneNumberBinding {
  public getBoundValue(boundElements: JQuery): string {
    var value = boundElements.text();
    return value.replace(/\D/g, '');
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    let numberString;

    if (value) {
      numberString = value.replace(/\D/g, '');

      numberString = this.formatPhoneNumber(numberString);
    }

    boundElements.text(numberString || value);
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }
}