import { JQueryComponent } from "./jqueryComponent";

export class JQueryLoadingIndicator extends JQueryComponent {
  public static current: JQueryLoadingIndicator = new JQueryLoadingIndicator();

  private _loadingStarted: boolean = false;
  private _loadingFinished: boolean = false;

  private constructor() {
    super(jQuery("[jquery-controller-loading-indicator]"));
  }

  public notifyLoadingStarted(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Any component method that returns a promise turns on the "busy" state for the controller,
      // which means that at this point in the code we know that we are busy and that we'll receive
      // at least one idle notification in the future; because initialization has started we can
      // say that the next time we go idle is when the system has finished loading; we only do this
      // if we haven't already indicated that loading is finished
      if (!this._loadingFinished) {
        this._loadingStarted = true;
        resolve();
      }
    });
  }

  public notifyLoadingFinished(): void {
    // When loading is actually finished, we remove all of our HTML from the view permanently; we
    // only do this once
    if (!this._loadingFinished) {
      this._loadingFinished = true;
      this._loadingStarted = true; // Just in case someone jumps straight to finishing without starting
      for (let element of this.rootElement) {
        element.remove();
      }
    }
  }

  public onReady(): void {
    // The first time we hit the ready state after the loading state has been triggered, we do
    // our ready call
    if (this._loadingStarted && !this._loadingFinished) {
      this.notifyLoadingFinished();
    }
  }
}