import { JQueryPropertyBinding } from "../jqueryBinding";


//Bound element should be the unorder list
//the selected value will be found on data-value
// https://getbootstrap.com/docs/4.0/components/navs/#methods
export class TabSelectedBinding extends JQueryPropertyBinding<string> {
  public getBoundValue(boundElements: JQuery): string {
    return boundElements.find("li.nav-item a[aria-selected='true']").data("value");
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    boundElements.find(`li.nav-item a[data-value='${value}']`).tab("show")
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }
}


