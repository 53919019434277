export abstract class JQueryComponent {
  @JQueryComponent.prototypeValue(JQueryComponent.prototype) protected baseClassPrototype!: JQueryComponent;

  public constructor(
    protected readonly rootElement: JQuery) {
    // Any time a method is called the controller will enter the busy state until that method
    // has completed its work; we do this for all methods declared on the base class
    let baseClassPrototype = this.baseClassPrototype;
    let thisPrototype = Object.getPrototypeOf(this);
    while (thisPrototype !== baseClassPrototype) {
      for (let key of Object.getOwnPropertyNames(thisPrototype)) {
        let propertyDescriptor = Object.getOwnPropertyDescriptor(thisPrototype, key)!;
        let requiresUpdate = false;
        if (propertyDescriptor.get) {
          propertyDescriptor.get = this._getMonitoredMethod(propertyDescriptor.get);
          requiresUpdate = true;
        }
        if (propertyDescriptor.set) {
          propertyDescriptor.set = this._getMonitoredMethod(propertyDescriptor.set);
          requiresUpdate = true;
        }
        if (typeof propertyDescriptor.value === "function") {
          propertyDescriptor.value = this._getMonitoredMethod(propertyDescriptor.value);
          requiresUpdate = true;
        }
        if (requiresUpdate) {
          Object.defineProperty(this, key, propertyDescriptor);
        }
      }
      thisPrototype = Object.getPrototypeOf(thisPrototype);
    }

    // Ready state should happen after construction, which might internally contain other busy/idle
    // calls; to handle this, we enter busy right now, then we return from the current message
    // handler stack via a promise, and finally we exit busy using the proper means
    this.enterBusy();
    Promise.resolve().then(() => this.exitBusy());
  }

  private _destroyCallbacks?: (() => void)[];
  protected addDestroyCallback(callback: () => void): void {
    if (!this._destroyCallbacks) {
      this._destroyCallbacks = [callback];
    }
    else {
      this._destroyCallbacks.push(callback);
    }
  }
  protected destroy(): void {
    if (this._destroyCallbacks) {
      for (let destroyCallback of this._destroyCallbacks) {
        destroyCallback();
      }
    }
  }

  private _busyCount: number = 0;

  private _getMonitoredMethod<TFunction extends Function>(method: TFunction): TFunction {
    return function (this: JQueryComponent) {
      try {
        var returnValue = method.apply(this, arguments);
        if (returnValue instanceof Promise) {
          return new Promise<any>(async (resolve, reject) => {
            try {
              this.enterBusy();
              resolve(await returnValue);
              this.exitBusy();
            }
            catch (error) {
              this.notifyError(error);
              this.exitBusy();
              reject(error);
            }
          });
        }
        else {
          return returnValue;
        }
      }
      catch (error) {
        this.notifyError(error);
        throw error;
      }
    } as any as TFunction; // TODO: Use unknown once TypeScript is correct
  }

  public enterBusy(): void {
    if (!this._busyCount++) this.onBusy();
  }

  public exitBusy(): void {
    if (!--this._busyCount) this.onReady();
  }

  public notifyError(error: any): void {
    this.onError(error);
  }

  protected onBusy(): void { }

  protected onReady(): void { }

  protected onError(error: any): void { }

  public static prototypeValue(value: any): (target: JQueryComponent, propertyKey: string) => void {
    // The target here will not be the instance of a given class, it will be that classes'
    // prototype
    return function (target, propertyKey) {
      (target as any)[propertyKey] = value;
    }
  }

  public elementsContainComponent(otherComponent: JQueryComponent): boolean {
    for (let parentElement of this.rootElement) {
      for (let childElement of otherComponent.rootElement) {
        if (jQuery.contains(parentElement, childElement)) {
          return true;
        }
      }
    }
    return false;
  }
}