const escapeableCharacters: { readonly [char: string]: string } = {
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&apos;",
  '&': "&amp;"
}

const escapeableCharactersRegex = /[<>&"']/gm;

export function escapeHtml(text: string): string {
  return text.replace(escapeableCharactersRegex, x => escapeableCharacters[x]);
}