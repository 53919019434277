import { JQueryPropertyBinding } from "../jqueryBinding";

var _nextBoundItemID = 1;

export class SummernoteBinding extends JQueryPropertyBinding<string> {
  public placeholder?: string;
  public getBoundValue(boundElements: JQuery): string {
    // HACK: The summernote typings file returns the incorrect type for this operation
    return boundElements.summernote("code") as any;
  }

  public setBoundValue(boundElements: JQuery, value: string): void {
    boundElements.summernote("code", value);
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }

  public initialize(): void {
    let boundElements = this.getBoundElements();
    let baseOptions = {
      placeholder: this.placeholder,
      minHeight: 115
    } as Summernote.Options;

    /**Take any content of the bound element and add it to the tool bar
     * The elements need to have names and groups for the summernote api
     * https://summernote.org/deep-dive/#custom-button
     * 
     * putting two differnt elements in the same group will just remove any margin between them
     */
    let extraButtons = {} as any;
    boundElements.find("[jquery-header-button-name]").each(function(index, element) {
      let jqueryElement = $(element);
      let name = jqueryElement.attr('jquery-header-button-name') || 'name'
      let group = jqueryElement.attr('jquery-header-button-group') || 'group'
      if(!extraButtons.hasOwnProperty(group)) {
        extraButtons[group] = {};
      }
      extraButtons[group][name] = jqueryElement;
    })
      // Just need to group the "buttons" into arrays where the first element is the group name
      // and the second element is an array of the names of the buttons
    let toolBar = [];
    // an object with keys being the names of the defined buttons
    // the proeprties are functions that return jquery objects
    let definedButtons: any = {};
    for(let groupKey in extraButtons) {
      let buttonGroup = [];
      
      for(let nameKey in extraButtons[groupKey]) {
        buttonGroup.push(nameKey);
        definedButtons[nameKey] = () => extraButtons[groupKey][nameKey]
      }
      toolBar.push(groupKey,buttonGroup )
    }
    if(toolBar.length) {
      // Adding our toolbar elements onto the end of the 
      // default tool bar
      baseOptions.toolbar = ($ as any).summernote.options.toolbar.concat(toolBar),
      (baseOptions as any).buttons = definedButtons
    }
    boundElements.summernote(baseOptions);

    // The following adds the "caret" icon to the "More Color" button.
    let MoreColorDropDownButton = $('.note-color .dropdown-toggle');
    MoreColorDropDownButton.append('<span class="note-icon-caret"></span>');
    super.initialize(); // TODO: Base initialization tries to read the uncreated value, which causes problems in Summernote - do we want to change initialization order everywhere?
  }

  public destroy(): void {
    super.destroy();
    let boundElements = this.getBoundElements();
    boundElements.summernote("destroy");
  }
}