import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { DriveSingleQuery, DriveListingQuery} from "../model/drive"
import { currentUser } from "../shared/currentUser"
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { PaginatedResults } from "model/pagination";
import { SelectOption } from "model/select";
import { Company } from "model/company";

interface DriveDetails {
  DriveID: string,
  Name: string,
}

interface ReportRequest {
  DriveID: string,
  WantsNotes: boolean,
  WantsCards: boolean,
  WantsPeople: boolean,
  WantsCompanies: boolean
}

export class ReportController extends JQueryController {

  // General
  @JQueryController.bindInputValue("#drive") public driveID!: string;
  @JQueryController.bindSelectOptions("#drive") public driveOptions!: SelectOption[]
  @JQueryController.bindInputChecked("#notes-filter") public wantsNotes!: boolean;
  @JQueryController.bindInputChecked("#cards-filter") public wantsCards!: boolean;
  @JQueryController.bindInputChecked("#people-filter") public wantsPeople!: boolean;
  @JQueryController.bindInputChecked("#company-filter") public wantsCompanies!: boolean;
  @JQueryController.bindDatePickerValue("#expired-date") public expiredSetDate!:  Date;
  @JQueryController.bindSelectOptions("#company") public companyOptions!: SelectOption[]
  @JQueryController.bindInputValue('#company') public companyID?: string;
  @JQueryController.bindDisplayText('#expiry-days-text') public expiryDaysText = "";

  // Validation
  @JQueryController.bindParsleyValidation("#main-form") public isMainFormValid!: boolean;
  @JQueryController.bindParsleyValidation("#pdf-form") public isPdfFormValid!: boolean;
  constructor(rootElement: JQuery<HTMLElement>, driveID?: string) {
    super(rootElement);
    if(!currentUser || !currentUser.canManageReports) {
      window.location.href = "/notes.html"
    }
    this.populateSelects().then(() => {
      this.populateDriveSelect();
      this.expiredSetDate = new Date();
    });
  }

  private async populateCompanySelect() {
    this.companyOptions = (await executeServiceCall<PaginatedResults<Company>>('companies/getCompaniesForSelect')).results.map(x => { return { text: x.CompanyName, value: x.CompanyID }})
  }

  private async populateSelects() {
    await this.populateCompanySelect();    
  }

  private async populateDriveSelect() {
    this.driveOptions = (await executeServiceCall<DriveDetails[]>('drives/getDrives')).map(x => {
      return {text: x.Name, value: x.DriveID}
    })
  }

  @JQueryController.bindClick("#create-report")
  @JQueryController.bindValidation("#report-form")
  public async GetReport(e: JQuery.Event): Promise<void> {
    window.open(`/services/reports/getDriveExport?driveID=${this.driveID}&notes=${this.wantsNotes}&cards=${this.wantsCards}&people=${this.wantsPeople}&companies=${this.wantsCompanies}`, '_blank');
  }

  @JQueryController.bindClick("#create-pdf-report")
  @JQueryController.bindValidation("#pdf-form")
  public async GetPDFReport(e: JQuery.Event): Promise<void> {

    var hasReport = await this.GetPDFExportPrefilght();
    if(hasReport){
      JQueryMessageController.hideMessage("no-cards-export");   
      window.open(`/services/reports/getPDFExport?companyID=${this.companyID}`, '_blank');
    } else{
      JQueryMessageController.showMessage("no-cards-export");    
    }
  }

  public async GetPDFExportPrefilght() {
    var response = await this.executeServiceCall("reports/getPDFExportPreflight?companyID="+this.companyID+"");
    return response;      
  }

  @JQueryController.bindInputChange("#expired-date")  
  public checkDates() {
    $("#expired-date").parsley().validate();
  }
  
  @JQueryController.bindClick("#create-expired-report")
  public async GetExpiredReport(e: JQuery.Event): Promise<void> {
    var hasReport = await this.GetExpiredReportPrefilght();
    if(hasReport){
      JQueryMessageController.hideMessage("no-expired-cards"); 
      var sendDate = this.expiredSetDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      this.expiryDaysText = this.expiredSetDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      window.open(`/services/reports/getExpiredSignatures?expiredSetDate=${sendDate}`, '_blank');
    } else{
      this.expiryDaysText = this.expiredSetDate.toLocaleDateString("en-US");
      JQueryMessageController.showMessage("no-expired-cards");    
    }
  }

  public async GetExpiredReportPrefilght() {
      var response = await this.executeServiceCall("reports/getExpiredSignaturesPreflight?expiredSetDate="+this.expiredSetDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' })+"");
      return response;      
  }

}