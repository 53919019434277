import { cookies } from "@legacyx/web-uitoolkit/cookies";
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls"

export interface CurrentUser {
  userAccountID?: string;
  name?: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  currentDriveID?: string;
  canManageAccounts: boolean;
  canManageAgreements: boolean;
  canManageDrives: boolean;
  canManageBilling: boolean;
  canManageReports: boolean;
  isOwner: boolean;
  isPaymentSetup: boolean;
}

export var currentUser: Readonly<CurrentUser> | undefined;

export function setCurrentUserToken(token: string | null): void {
  // If we don't have a token (for instance on logout), ensure that our currentUser is cleared out.
  if(!token) {
    currentUser = undefined;
    return;
  }

  try {
    // We don't verify the signature on the client side because any sort of
    // permissions checks and validation will be done on server calls.
    let splitToken = token.split(".");
    if(splitToken.length >= 2) {
      let rawBody = splitToken[1].replace(/-/g, "+").replace(/_/g, "/");
      rawBody += "=".repeat((4 - (rawBody.length % 4)) % 4);
      let body = JSON.parse(atob(rawBody));
      currentUser = {
        userAccountID: body["sub"],
        name: body["name"],
        isAdmin: body["isAdmin"],
        isSuperAdmin: body["isSuperAdmin"],
        currentDriveID: body["currentDriveID"],
        canManageAccounts: body["canManageAccounts"],
        canManageAgreements: body["canManageAgreements"],
        canManageDrives: body["canManageDrives"],
        canManageBilling: body["canManageBilling"],
        canManageReports: body["canManageReports"],
        isOwner: body["isOwner"],
        isPaymentSetup: body['isPaymentSetup'],
      }
    }
  }
  catch (ignored) { }
}

export async function logout(): Promise<void> {
  var success = await executeServiceCall<boolean>('login/logout');
  if(success) {
    // clear out CurrentUser object
    setCurrentUserToken(null);
    window.location.href = 'login.html';
  }
}

if (cookies["userToken"]) {
  setCurrentUserToken(cookies["userToken"]);
}

if (currentUser) {
  if (!currentUser.isPaymentSetup && window.location.pathname != '/billing.html'){
    window.location.href = 'billing.html';
  }
}
