import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { DriveSingleQuery, DriveListingQuery} from "../model/drive"
import { currentUser } from "../shared/currentUser"
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { PaginatedResults } from "model/pagination";
import { SelectOption } from "model/select";
import { Company } from "model/company";
import { NoZoneDate } from "@legacyx/web-uitoolkit/noZoneDate";
import { AgreementListingQuery, DriveAgreementQuery } from "../model/agreement";

interface AddAgreementResponse {
  AgreementID: string
}

interface AgreementDetails {
  AgreementID?: string,
  Name: string,
  Union: string,
  EffectiveDate: Date, 
  ExpiryDate: Date,
  AgreementLink: string,
  DriveID?: string,
}

interface AgreementSingleQuery {
  agreementID?: string
}


export class AgreementSingleController extends JQueryController {
  
  // View
  @JQueryController.bindVisibility('#new-agreement-header') public newAgreementHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#update-agreement-header') public updateAgreementHeaderVisibility!: boolean;
  @JQueryController.bindAttribute('#drive-agreement-button', 'class') public driveButtonClass!:string;
  @JQueryController.bindVisibility('#drive-agreement-button') public driveButtonVisibility!: boolean;
  @JQueryController.bindDisplayText("#cookieName") public cookieName!: string;
  @JQueryController.bindDisplayText('#drive-agreement-button') public driveButtonText!: string;
  @JQueryController.bindVisibility(".new-agreement-button") public newAgreementButtonVisibility: boolean;
  @JQueryController.bindVisibility(".edit-agreement-button") public editAgreementButtonVisiblity: boolean;
  @JQueryController.bindVisibility("#link-button") public linkButtonVisibility!: boolean;
  @JQueryController.bindVisibility("#agreement-link") public linkInputVisibility!: boolean;
  @JQueryController.bindDisplayText("#link-button") public link!: string;
  @JQueryController.bindAttribute('#link-button-ref', 'href') public href!:string;

  // General
  @JQueryController.bindInputValue("#name") public name!: string;
  @JQueryController.bindInputValue("#union") public union!: string;
  @JQueryController.bindInputValue("#agreement-link") public agreementLink!: string;
  @JQueryController.bindDatePickerValue('#effective-date', "NoZoneDate") public effectiveDate?: NoZoneDate;
  @JQueryController.bindDatePickerValue('#expiry-date', "NoZoneDate") public expiryDate?: NoZoneDate;

  private _agreementID?: string;
  private _driveID?: string;
  private _cleanAgreement: AgreementDetails = {
    AgreementID: "",
    Name: "",
    EffectiveDate: new Date(),
    ExpiryDate: new Date(),
    Union: "",
    AgreementLink: "",
  };

  constructor(rootElement: JQuery<HTMLElement>, agreementID?: string) {
    super(rootElement);
    this.newAgreementButtonVisibility = false;
    this.editAgreementButtonVisiblity = false;
    this.driveButtonText = "Create Drive Based On Agreement";
    this.driveButtonClass = "btn btn-space btn-success";
    if(!currentUser || !currentUser.canManageAgreements) {
      window.location.href = "/notes.html"
    }
    var query = QueryParser.getQuery<AgreementSingleQuery>(false);
    if(query && query.agreementID && currentUser) {
      this._agreementID = query.agreementID;
      this.LoadAgreementInformation();
      this.setNewUserVisiblity(false);
      this.setHeader(false);
    } else {
      this.cookieName = "New Agreement";
      this.setHeader(true);
      this.setNewUserVisiblity(true);
    }
  }
  
  private setHeader(isNew: boolean) {
    this.newAgreementHeaderVisibility = isNew;
    this.updateAgreementHeaderVisibility = !isNew;
    this.driveButtonVisibility = !isNew;
    this.linkInputVisibility = isNew;
    this.linkButtonVisibility = !isNew;
  }

  private setNewUserVisiblity(isNewUser: boolean) {
      this.editAgreementButtonVisiblity = !isNewUser;
      this.newAgreementButtonVisibility = isNewUser;
  }

  private setUIValues(agreement: AgreementDetails) {
    this.name = agreement.Name;
    this.effectiveDate = new NoZoneDate(agreement.EffectiveDate);
    this.expiryDate = new NoZoneDate(agreement.ExpiryDate);
    this.union = agreement.Union;
    this.agreementLink = agreement.AgreementLink;
    this._cleanAgreement = {...agreement};
  }

  public async LoadAgreementInformation() {
    var agreementInformation = await this.executeServiceCall<AgreementDetails>("agreement/getAgreement",
    {
      agreementID: this._agreementID
    });
    if (agreementInformation.DriveID){
      this._driveID = agreementInformation.DriveID
      this.driveButtonClass = "btn btn-space btn-primary";
      this.driveButtonText = "View Drive Associated with Agreement";
    }
    this.link = "View at " + agreementInformation.AgreementLink;
    this.href = "http://" + agreementInformation.AgreementLink;
    this.cookieName = agreementInformation.Name;
    this.setUIValues(agreementInformation);
  }

  @JQueryController.bindClick("#cancel") 
  public cancelInput() {
    this.setUIValues(this._cleanAgreement);
  }

  @JQueryController.bindClick("#cancelCreate") 
  public cancelCreateAgreement() {
    window.location.href = '/agreements.html'
  }

  @JQueryController.bindInputChange("#effective-date")
  @JQueryController.bindInputChange("#expiry-date") 
  public checkDates() {
    $("#effective-date").parsley().validate();
    $("#expiry-date").parsley().validate();
  }
  
  @JQueryController.bindClick("#save")
  @JQueryController.bindValidation("#agreement-form")
  public async UpdateDrive() {
    if(this._agreementID) {
      this._cleanAgreement = {
        AgreementID: this._agreementID,
        Name: this.name,
        EffectiveDate: (this.effectiveDate! as NoZoneDate).getServerDate(),
        ExpiryDate: (this.expiryDate! as NoZoneDate).getServerDate(),
        Union: this.union,
        AgreementLink: this.agreementLink
      }
      
      var response = await this.executeServiceCall("agreement/upsertAgreement",
      {
        agreement: this._cleanAgreement
      });

      JQueryMessageController.showMessage("updated-agreement");
    }
  }

  @JQueryController.bindClick("#create-agreement")
  @JQueryController.bindValidation("#agreement-form")
  public async CreateAgreement(e: JQuery.Event): Promise<void> {
    var response = await this.executeServiceCall<AddAgreementResponse>("agreement/upsertAgreement",
    {
      agreement: {
        Name: this.name,
        EffectiveDate: (this.effectiveDate! as NoZoneDate).getServerDate(),
        ExpiryDate: (this.expiryDate! as NoZoneDate).getServerDate(),
        Union: this.union,
        AgreementLink: this.agreementLink, 
      }
    });
    this._agreementID = response.AgreementID;
    this.setNewUserVisiblity(false);
    
    window.location.href = "/agreements.html"+ QueryParser.BuildQueryString<AgreementListingQuery>({createdAgreement: this.name});
  }

  @JQueryController.bindClick("#drive-agreement-button") 
  public routeToRelatedDrive() {
    if (this._driveID){
      window.location.href = "/drive-single.html" + QueryParser.BuildQueryString<DriveSingleQuery>({driveID: this._driveID});
    } else {
      window.location.href = "/drive-single.html" + QueryParser.BuildQueryString<DriveAgreementQuery>({agreementID: this._agreementID!, union:this._cleanAgreement.Union, agreementName:this._cleanAgreement.Name});  
    }
  }
}