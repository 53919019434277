//If this grows and is useful later we could add it to 

export class DateTime {
 public static get24TimeString(date: Date) {
  
    let hourString = "00"+date.getHours();
    hourString = hourString.substr(hourString.length-2, 2);

    let minuteString = "00"+date.getMinutes();
    minuteString = minuteString.substr(minuteString.length-2, 2);
    return `${hourString}:${minuteString}`;
 }
 public static getDateString(date: Date) {
  return date.toLocaleDateString();
 }
 public static getDate24TimeString(date: Date) {
  return date.toLocaleDateString() + " " + this.get24TimeString(date);
 }
}