import "jquery";
import { JQueryComponent } from "./jqueryComponent";

import { NoZoneDate } from "./noZoneDate"

import { BindableTarget, BindingFactory, JQueryBinding, JQueryBindingConstructor } from "./jqueryBinding";
import { ErrorMessageBinding, SuccessMessageBinding, ValidationBinding, BusyVisibilityBinding, ResetFormBinding } from "./bindings/userMessaging";
import { ControllerBinding, ChildControllerBinding, ChildControllerOptions } from "./bindings/childController";
import { DisplayTextBinding, DisplayHtmlBinding } from "./bindings/displayText";
import { ParsleyFormValidityBinding } from "./bindings/parsleyFormValidity";
import { ClickBinding, InputChangeBinding, EnterKeyBinding, GeneralEventBinding } from "./bindings/simpleEvents";
import { VisibilityBinding, ClassPresentBinding } from "./bindings/visibility";
import { SelectOptionsBinding, SelectedValueBinding, SelectedTextBinding, ListValueBinding, ListQueryBinding, ListSearchQueryBinding } from "./bindings/selectControls";
import { DatePickerBinding } from "./bindings/datePicker";
import { ProgressBarBinding } from "./bindings/progressBar";
import { SliderBinding } from "./bindings/slider";
import { ExpanderBinding } from "./bindings/expander";
import { CroppieBinding } from "./bindings/croppie";
import { AttributeBinding, InputValueBinding, InputCheckedBinding, EnabledBinding } from "./bindings/simpleProperties";
import { TabSelectedBinding } from "./bindings/tabSelectedBindings";
import { DataTableCurrentPage } from "./bindings/dataTablePropertyBindings";
import { ImageUrlBinding } from "./bindings/image";
import { RepeatingControllerBinding } from "./bindings/repeater";
import { DropzoneBinding, DropzoneManualBinding } from "./bindings/dropzone-v1";
import { DropzoneControllerBinding } from "./bindings/dropzone-v2";
import { SummernoteBinding } from "./bindings/summernote";
import { EChartsBinding, ChartDataBinding, ChartTypeSelectorBinding } from "./bindings/echarts";
import { PhoneNumberInputBinding, PhoneNumberDisplayBinding } from "./bindings/phoneNumber";
import { GlobalErrorBinding, ClearGlobalErrorBinding } from "./bindings/error";
// TODO: Redo the find(...).addBack(...) pattern used here

import globals from "./globals";

import { escapeHtml } from "./htmlTemplates";

import { executeServiceCall, executeBinaryUploadCall } from "./serviceCalls";
import { JQueryMessageController } from "./jqueryMessageController";

interface _EventHandler {
  eventName: string;
  selector: string;
  handler: (eventObject: JQueryEventObject) => void;
}

interface _BoundDataTable {
  dataSourcePropertyKey: string;
  selector: string;
  columns: _BoundDataTableColumnSettings[];
  drawCallbacks: ((settings: DataTables.Settings, tableRoot: JQuery) => void)[];
  options?: DataTables.Settings
}

interface _BoundDataTableColumnSettings extends DataTables.ColumnSettings {
  templateSelector?: string;
  templateHtml?: string;
}

export enum DomVisibility {
  visible = "visible",
  hidden = "hidden",
  collapse = "collapse",
  initial = "initial",
  inherit = "inherit"
}

export interface RunningController<TResponse> {
  closeController(response?: TResponse): void;
  completionPromise: Promise<TResponse>;
}

export enum ControllerAction {
  Add = 1,
  Update = 2,
  Delete = 3,
  Return = 4
}

export class ControllerReturnObject {
  stepsToReturn: number;
  action: ControllerAction;

  constructor(stepsToReturn: number, action: ControllerAction) {
    this.stepsToReturn = stepsToReturn;
    this.action = action;
  }

  next(): ControllerReturnObject {
    return new ControllerReturnObject(--this.stepsToReturn, this.action);
  };

  shouldRefresh(): boolean {
    return this.action != 4;
  }
}

export abstract class JQueryController extends JQueryComponent {
  @JQueryComponent.prototypeValue(JQueryController.prototype) protected baseClassPrototype!: JQueryComponent;

  public static runControllerGarbageCollection(): void {
    // Purge any controllers that are not present in the DOM but that we haven't disposed of yet
    for (let controllerID in JQueryController._constructedControllers) {
      if (jQuery(`[jquery-controller-id=${controllerID}]`).length === 0) {
        this._constructedControllers[controllerID].destroy();
        delete this._constructedControllers;
      }
    }
  }

  public static getControllersOfType<T extends JQueryController>(type: JQueryControllerConstructor<T>): T[] {
    let returnValue: T[] = [];
    for (let key in JQueryController._constructedControllers) {
      let controller = JQueryController._constructedControllers[key];
      if (controller instanceof type) {
        returnValue.push(controller);
      }
    }
    return returnValue;
  }

  public static tryGetControllerByType<T extends JQueryController>(type: JQueryControllerConstructor<T>): T | null {
    let returnValue = JQueryController.getControllersOfType(type);
    return returnValue.length === 1 ? returnValue[0] : null;
  }

  public static getControllerByType<T extends JQueryController>(type: JQueryControllerConstructor<T>): T {
    let returnValue = JQueryController.tryGetControllerByType(type);
    if (returnValue) {
      return returnValue;
    }
    else {
      throw new Error(`Can't find a controller with the type '${type.name}'`);
    }
  }

  public static tryGetControllerBySelector(selector: string): JQueryController | null {
  let controllerTarget = jQuery(selector);
    let controllerID = controllerTarget.attr("jquery-controller-id");
    if (!controllerID) {
      return null;
    }
    return this._constructedControllers[controllerID];
  }

  public static getControllerBySelector(selector: string): JQueryController {
    let returnValue = JQueryController.tryGetControllerBySelector(selector);
    if (returnValue) {
      return returnValue;
    }
    else {
      throw new Error(`Can't find a controller at the selector '${selector}'`);
    }
  }

  public static tryInitializeController(selector: string, args: any[], rootElement?: JQuery): {
    controller: JQueryController,
    controllerID: string
  } | undefined {
    let controllerTarget = rootElement ? rootElement.find(selector) : jQuery(selector);
    let controllerID = controllerTarget.attr("jquery-controller-id");
    if (controllerID) {
      this._constructedControllers[controllerID].destroy();
      delete this._constructedControllers;
      controllerTarget.removeAttr("jquery-controller-id");
    }
    // HACK: We'll deprecate the V1 navigation behaviors later
    let controllerName = controllerTarget.attr("jquery-controller") || controllerTarget.attr("jquery-controller-v1");
    if (controllerName) {
      return JQueryController.bindNewController(jQuery(controllerTarget), controllerName, args);
    }
    else {
      return undefined;
    }
  }

  public static initializeController(selector: string, args: any[], rootElement?: JQuery): {
    controller: JQueryController,
    controllerID: string
  } {
    var returnValue = this.tryInitializeController(selector, args, rootElement);
    if (returnValue) {
      return returnValue;
    }
    else {
      throw new Error(`Selector '${selector}' couldn't be found when constructing a controller`);
    }
  }

  // TODO: Name this better - how is it different from initializeController?
  public static bindNewController(controllerTarget: JQuery, controllerName: string, args: any[]): {
    controller: JQueryController,
    controllerID: string
   } {
    // Because of dynamic arguments we need to invoke the constructor using manual syntax; refer
    // online to the semantics of 'new' in JavaScript for more info
    let controllerID = "JQC" + (JQueryController._nextControllerID++);

    // We need to clone our arguments as we add our target element into the array and
    // this can be called multiple times against the same array.
    var myArgs = args.slice() as [JQuery<HTMLElement>, ...any[]];
    myArgs.unshift(controllerTarget);

    let controllerConstructor = JQueryController._controllerConstructors[controllerName];
    if (!controllerConstructor) {
      console.error("MISSING CONTROLLER: " + controllerName);
    }
    let controller = Object.create(controllerConstructor.prototype) as JQueryController;
    // HACK: It feels like we could construct this better? typeof JQueryController doesn't accept the apply statement
    (controllerConstructor as any).apply(controller, myArgs);
    JQueryController._constructedControllers[controllerID] = controller;
    controllerTarget.attr("jquery-controller-id", controllerID);
    return { controller, controllerID };
  }
  // called once all auto loaded controllers have been initialized
  public static notifyAllControllersInitialized() {
    for(var controllerID in JQueryController._constructedControllers) {
      JQueryController._constructedControllers[controllerID].pageLoaded();
    }
  }
  // called once all auto loaded controllers have been initialized
  public pageLoaded() : void {}

  public get controllerID(): string { return this.rootElement.attr("jquery-controller-id")! }

  private _propertyBackingStore: { [propertyKey: string]: any } = {};

  @JQueryComponent.prototypeValue([]) private _bindingConstructors!: BindingFactory[];
  private _bindings: JQueryBinding<any>[];

  public constructor(rootElement: JQuery) {
    super(rootElement);
    // TODO: Move everything into the extensions model
    // Run all per-instance controller initialization; extensions are used for pretty much all
    // bindings and special attributes
    // TODO: Revisit BindableTarget; this cast seems fishy to me
    this._bindings = this._bindingConstructors.map(x => x(this as any as BindableTarget));
    for (let binding of this._bindings) {
      binding.initialize();
    }
    for (let key in this._boundDataTables) {
      this._initializeDataTable(this._boundDataTables[key]);
    }
  }

  private static _globalErrorRaisedTimeMillis?: number;
  protected onBusy(): void {
    this.rootElement.attr("jquery-controller-busy", "");
    this.rootElement.removeAttr("jquery-controller-ready");

    // When a new request puts us in "busy" mode again, if it's been long enough since the last
    // one we'll deactivate any global errors that we might have
    if (JQueryController._globalErrorRaisedTimeMillis
      && new Date().getTime() - JQueryController._globalErrorRaisedTimeMillis > 3000) {
      ClearGlobalErrorBinding.clearGlobalError();
      delete JQueryController._globalErrorRaisedTimeMillis;
    }
  }
  protected onReady(): void {
    this.rootElement.attr("jquery-controller-ready", "");
    this.rootElement.removeAttr("jquery-controller-busy");
  }
  protected onError(error: any): void {
    JQueryController._globalErrorRaisedTimeMillis = new Date().getTime();
    GlobalErrorBinding.raiseGlobalError(error, this);
  }

  private _parentController?: JQueryController;
  private _parentControllerResultReporter?: (result: any) => void;
  // TODO: Remove returnResultToParent, it's no longer the right one
  protected returnResultToParent<TResult>(result?: TResult): void {
    if (this._parentControllerResultReporter) {
      this._parentControllerResultReporter(result);
    }
  }
  // TODO: This is public - is that OK? Do we want the lifetime of a controller to be manipulated externally?
  public close<TResult>(result?: TResult): void {
    if (this._parentControllerResultReporter) {
      this._parentControllerResultReporter(result);
    }
    this.destroy();
  }

  protected destroy(): void {
    super.destroy();

    // Then do fixed destruction
    for (let binding of this._bindings) {
      binding.destroy();
    }

    // This is a temporary solution to hiding child messages when a controller is cleaned up.
    // We should likely find a better solution for this at a later date but for now it works.
    this.rootElement.find("[role=alert]").attr('hidden', '');

    for (let selector in this._validatedSelectors) {
      // If the implementer didn't actually implement any validations in HTML but still added
      // a controller validation decorator, parsley() will return undefined here
      let parsley = this.rootElement.find(selector).addBack(selector).parsley();
      if (parsley) {
        parsley.reset();
      }
    }

    // Clean up the static values for this controller
    // TODO: Store controller ID as a property of this object?
    let controllerID = this.rootElement.attr("jquery-controller-id")!;
    delete JQueryController._constructedControllers[controllerID];

    // Purge the stored controller ID from the root element, which will hide the element
    // (if our proper styles are still in force) and ready the DOM for a second copy of
    // the controller to be created for the same target, and clean up any other possibly
    // added attributes
    this.rootElement.removeAttr("jquery-controller-id");
    this.rootElement.removeAttr("jquery-controller-ready");
    this.rootElement.removeAttr("jquery-controller-busy");
  }

  public findBindings<T extends JQueryBinding<any>>(bindingClass: JQueryBindingConstructor<T>, searchCriteria: {
    selector?: string;
    propertyKey?: string;
  }): T[] {
    return this._bindings.filter(binding => {
      if (binding instanceof bindingClass) {
        return (!searchCriteria.selector || searchCriteria.selector == binding.selector)
          && (!searchCriteria.propertyKey || searchCriteria.propertyKey == binding.propertyKey);
      }
      else {
        return false;
      }
    }) as T[];
  }

  @JQueryController._attachBindingDecorator(ErrorMessageBinding, ["errorValidator"])
  public static bindErrorMessage(messageID: string, errorValidator?: (error: any) => boolean): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(SuccessMessageBinding, ["resultValidator"])
  public static bindSuccessMessage(messageID: string, resultValidator?: (result: any) => boolean): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ValidationBinding, [])
  public static bindValidation(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ResetFormBinding, [])
  public static bindResetForm(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(BusyVisibilityBinding, [])
  public static bindBusyVisibility(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ControllerBinding, [])
  public static bindController(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  public static bindChildController(selector: string, options?: ChildControllerOptions): (target: JQueryController, propertyKey: string) => void;
  public static bindChildController(selector: string, controllerArgs?: any[] | ((controller: JQueryController) => any[])): (target: JQueryController, propertyKey: string) => void;
  @JQueryController._attachBindingDecorator(ChildControllerBinding, ["controllerArgsOrOptions"])
  public static bindChildController(selector: string, controllerArgsOrOptions?: any[] | ((controller: JQueryController) => any[]) | ChildControllerOptions): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(DisplayTextBinding, [])
  public static bindDisplayText(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(DisplayHtmlBinding, [])
  public static bindDisplayHtml(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(InputChangeBinding, [])
  public static bindInputChange(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  
  @JQueryController._attachBindingDecorator(ParsleyFormValidityBinding, [])
  public static bindParsleyValidation(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ClickBinding, [])
  public static bindClick(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(GeneralEventBinding, ["eventName"])
  public static bindGeneralEvent(selector: string, eventName: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(EnterKeyBinding, [])
  public static bindEnterKey(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(AttributeBinding, ["attribute"])
  public static bindAttribute(selector: string, attribute: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(VisibilityBinding, ["disableControlOnHidden"])
  public static bindVisibility(selector: string, disableControlOnHidden?: boolean): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ClassPresentBinding, ["className"])
  public static bindClassPresent(selector: string, className: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(InputValueBinding, [])
  public static bindInputValue(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(InputCheckedBinding, [])
  public static bindInputChecked(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  /**
   * This works for nav tabs. For each navtab add a data-value="" inorder to read and set selected item.
   * Setup your html tabs as described here https://getbootstrap.com/docs/4.0/components/navs/#using-data-attributes   * 
   * The selector should be for the <ul> item
   */
  @JQueryController._attachBindingDecorator(TabSelectedBinding, [])
  public static bindTabSelected(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(EnabledBinding, [])
  public static bindEnabled(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(SelectOptionsBinding, [])
  public static bindSelectOptions(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(SelectedValueBinding, [])
  public static bindSelectedValue(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(SelectedTextBinding, [])
  public static bindSelectedText(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ListValueBinding, [])
  public static bindListValue(selector: string):
    <TTarget extends JQueryController, TElement>(target: TTarget, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }
 
  // Use with bindListValue to readout selected value
  // You will need select2.min.css for control formatting and material-design-iconic-font.min.css for the drop down icon
  // Possible attributes 
  // jquery-controller-allow-clear --Allows clearing once selected
  // jquery-controller-placeholder-text --Water mark text when nothing is selected
  @JQueryController._attachBindingDecorator(ListQueryBinding, ["captionSelector", "equalityComparer"])
  public static listQuery<TElement>(
    selector: string,
    captionSelector: (value: TElement) => string,
    equalityComparer?: (a: TElement, b: TElement) => boolean
  ):
    <TTarget extends JQueryController>(
      target: TTarget,
      propertyKey: string,
      propertyDesc: TypedPropertyDescriptor<() => Promise<TElement[]>>
    ) => void {
    return bindingImplementedElsewhere();
  }

  // See comments above listquery binding
  // required attribute jquery-controller-searchable
  // Use jquery-controller-searchable to enable select2 functionality
  @JQueryController._attachBindingDecorator(ListSearchQueryBinding, ["captionSelector", "equalityComparer", "getOptGroup", "delayTimingMS"])
  public static listSearchQuery<TElement>(
    selector: string,
    captionSelector: (value: TElement) => string,
    equalityComparer?: (a: TElement, b: TElement) => boolean,
    getOptGroup?: (a: TElement) => string,
    delayTimingMS ?: number,
  ):
    <TTarget extends JQueryController>(
      target: TTarget,
      propertyKey: string,
      propertyDesc: TypedPropertyDescriptor<(searchTerm: string) => Promise<TElement[]>>
    ) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(DatePickerBinding, ["dataType", "containerID"])
  public static bindDatePickerValue(selector: string, dataType?: "NoZoneDate", containerID?: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(SliderBinding, ["sliderOptions"])
  public static bindSlider(selector: string, sliderOptions?: SliderOptions): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(CroppieBinding, ["croppieOptions"])
  public static bindCroppie(selector: string, croppieOptions: Croppie.CroppieOptions): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ExpanderBinding, [])
  public static bindExpanderIsExpanded(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ImageUrlBinding, ["fileNotFoundUrl"])
  public static bindImageUrl(selector: string, fileNotFoundUrl?: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(RepeatingControllerBinding, ["controllerArgs", "refreshAllControllers"])
  public static bindRepeatingController(selector: string, controllerArgs?: any[] | ((controller: JQueryController, item: any) => any[]), refreshAllControllers?: boolean): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(GlobalErrorBinding, [])
  public static bindGlobalErrorHandler(): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ClearGlobalErrorBinding, [])
  public static bindClearGlobalErrorHandler(): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ProgressBarBinding, [])
  public static bindProgressBar(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(DropzoneBinding, ["url", "options"])
  public static bindDropzoneUpload(selector: string, url: string | ((controller: JQueryController) => string), options?: Dropzone.DropzoneOptions ): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(DropzoneManualBinding, ["url"])
  public static bindDropzoneManualUpload(selector: string, url: string | ((controller: JQueryController) => string)): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(DropzoneControllerBinding, ["url", "dropzoneOptions"])
  public static bindDropzoneController(selector: string, url: string | ((controller: JQueryController) => string), dropzoneOptions?: Dropzone.DropzoneOptions): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  /**
   * If you want to add buttons to the summer note control
   * you can add child elements to the boundControl
   * 
   * Each direct child of the bound control must have the attributes
   * jquery-header-button-name and jquery-header-button-group
   * 
   * jquery-header-button-name must have a unique value
   * putting two differnt elements in the same group will remove any margin between the elements
   */
  @JQueryController._attachBindingDecorator(SummernoteBinding, ["placeholder"])
  public static bindSummernote(selector: string, placeholder?: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(EChartsBinding, [])
  public static bindECharts(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ChartDataBinding, [])
  public static bindChartData(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(ChartTypeSelectorBinding, [])
  public static bindChartTypeSelector(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(PhoneNumberInputBinding, [])
  public static bindPhoneNumberInput(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  @JQueryController._attachBindingDecorator(PhoneNumberDisplayBinding, [])
  public static bindPhoneNumberDisplay(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  private static _attachBindingDecorator(
    bindingClass: JQueryBindingConstructor<any>,
    extraArgumentNames: string[]
  ): (targetClass: typeof JQueryController, decoratorName: keyof (typeof JQueryController)) => PropertyDescriptor {
    return function (targetClass: typeof JQueryController, decoratorName: keyof (typeof JQueryController)): PropertyDescriptor {
      // We're getting really funky here... we don't care about the actual prototype but instead
      // we'll rewrite the decorator that this binding is attached to
      return {
        value: function (selector: string, ...otherArgs: any[]): (targetPrototype: JQueryController, propertyKey: string) => void {
          return function (targetPrototype: JQueryController, propertyKey: string): void {
            JQueryController.trySetupControllerPrototype(targetPrototype.constructor as typeof JQueryController);
            // HACK: We need to typecast this object through any to get a BindableTarget due to property visibility
            // The bindable target properties are protected but we don't mind the binding seeing
            // them
            let otherNamedArgs: { [name: string]: any } = {};
            for (let i = 0; i < extraArgumentNames.length; i++) {
              otherNamedArgs[extraArgumentNames[i]] = otherArgs[i];
            }
            targetPrototype._bindingConstructors.push(
              JQueryBinding.setupBinding(
                bindingClass, targetPrototype as any as BindableTarget, selector, propertyKey, otherNamedArgs));
          }
        },
        enumerable: true,
        writable: false,
        configurable: false
      }
    }
  }
  
  // We need a place to store dynamic expression event callbacks; this function creates a
  // globally accessible function expression that can be called from injected HTML code
  // and that will be destroyed when this controller recycles itself
  private static readonly DYNAMIC_EVENT_CALLBACK_GLOBAL_NAME = "_$$jqcDynamicEventCallbacks";
  private static _dynamicEventCallbacks: { [id: number]: Function } = globals[JQueryController.DYNAMIC_EVENT_CALLBACK_GLOBAL_NAME] = {};
  private static _nextDynamicEventCallbackID = 1;
  protected createDynamicEventCallback(callback: Function): string {
    var callbackID = JQueryController._nextDynamicEventCallbackID++;
    var callbackValueExpression = `${JQueryController.DYNAMIC_EVENT_CALLBACK_GLOBAL_NAME}[${callbackID}]`;
    JQueryController._dynamicEventCallbacks[callbackID] = callback;
    this.addDestroyCallback(() => delete JQueryController._dynamicEventCallbacks[callbackID]);
    return callbackValueExpression;
  }

  private static _bindGetterSetter(target: JQueryController, propertyKey: string,
    getter: (this: JQueryController) => any, setter: (this: JQueryController, value: any) => void): void {
    let existingDescriptor = Object.getOwnPropertyDescriptor(target, propertyKey);
    if (existingDescriptor) {
      // TODO: Implement binding when there are getters/setters
      throw new Error("Binding to a getter/setter property not yet supported");
    }
    else {
      Object.defineProperty(target, propertyKey, {
        enumerable: true,
        configurable: true,
        get: getter,
        set: setter
      });
    }
  }

  private _hideIfOriginalVisibilityEquals(selector: string, visibilityToHide: string): void {
    // We use this to flip or revert visibility based on the caller's preference; basically, if
    // we want to reverse/restore visibility to/from its original state, which we capture the
    // first time we call this method - this allows us to be less picky about how other methods
    // manipulate the DOM (i.e. we don't need to explicitly prevent others from modifying DOM
    // node visibility for other purposes because we know the original state we want)
    for (let element of this.rootElement.find(selector).addBack(selector)) {
      let originalVisibility = element.getAttribute("jquery-controller-v1-original-visibility");
      if (!originalVisibility) {
        originalVisibility = element.hidden ? "hidden" : "visible";
        element.setAttribute("jquery-controller-v1-original-visibility", originalVisibility);
      }
      element.hidden = originalVisibility === visibilityToHide;
    }
  }

  protected reverseVisibility(selector: string): void {
    // Hide the element if the object is visible, show otherwise
    this._hideIfOriginalVisibilityEquals(selector, "visible");
  }

  protected restoreVisibility(selector: string): void {
    // Hide or show the element based on whatever they were when they started (this rolls back
    // calls to reverseVisibility but won't do anything to an unmodified DOM)
    this._hideIfOriginalVisibilityEquals(selector, "hidden");
  }

  public static bindSwitchEnabled(selector: string): (target: JQueryController, propertyKey: string) => void {
    return function (target: JQueryController, propertyKey: string): void {
      JQueryController.trySetupControllerPrototype(target.constructor as typeof JQueryController);
      JQueryController._bindGetterSetter(target, propertyKey,
        function () { return this.rootElement.find(selector).addBack(selector).attr('disabled') == 'disabled' ? false : true; },
        function (value: boolean) {
          value ? this.rootElement.find(selector).parent().removeClass('switch-disabled') : this.rootElement.find(selector).parent().addClass('switch-disabled');
          value ? this.rootElement.find(selector).addBack(selector).removeAttr('disabled') : this.rootElement.find(selector).addBack(selector).attr('disabled', 'disabled');
        }
      );
    }
  }
  
  private _dataTableApis: { [selector: string]: DataTables.Api } = {};

  // TODO: Make generic template replacement regex?
  private static _templateColumnRegex = /{{([^}]+)}}/gm;
  private static _nextTemplateID = 1;

  private _initializeDataTable(bdt: _BoundDataTable): void {
    // The render method below is invoked during data table initialization; because this results
    // in template rows being re-rendered and possibly having template data calls being passed
    // back into the controller down the road, we need to suppress custom template HTML from
    // being parsed if we haven't completed initialization yet
    let initializing = true;

    // There are a bunch of things related to templated columns that we can't fully process
    // until we have a concrete HTML template to work from, so we do that here
    let tableRoot = this.rootElement.find(bdt.selector).addBack(bdt.selector);
    let columns: _BoundDataTableColumnSettings[] = [];
    for (let column of bdt.columns) {
      // The first time we create a data table the HTML template will be in its pristine state;
      // after we create and destroy our first grid this template will be destroyed and we'll
      // need to have cached it; bearing in mind that the column specification we have is a
      // global class object, we'll need to make some compromises on our read-only specification
      // design to get this to work - basically, two different controllers will not be able to
      // use the same data table template, and the same data table template won't be usable on
      // more than one HTML DOM node (the first one probably won't ever be tried and the second
      // would never happen normally using our attributes); this one has to be done against the
      // underlying global column and not our cloned copy of it
      if (!column.templateHtml && column.templateSelector) {
        let templateElement = tableRoot.find(column.templateSelector).addBack(column.templateSelector);
        if (templateElement.length) {
          column.templateHtml = templateElement.html();
          column.className = templateElement.attr("class");
        }
      }

      // Now that we've done prior initialization we clone the column and do the rest of the
      // initialization; DataTables doesn't clone our input and uses the config object as a
      // data store for its own stuff, and if we don't clone it our new settings get ignored
      // when we try to use it again with a different parent controller
      column = { ... column };
      columns.push(column);
      if (column.templateHtml) {
        let templateHtml = column.templateHtml;
        column.render = (columnData, type, rowData) => {
          switch (type) {
            case "filter":
            case "type":
            case "sort":
              return columnData;
            case "display":
              // For the injected function result, if the expression produces a function data
              // type we will make a globally accessible invokable function from it and return
              // an expression that points to it, instead of just returning the expression
              let nextTemplateID = JQueryController._nextTemplateID++;
              return templateHtml.replace(JQueryController._templateColumnRegex,
                (substring, templateExpression) => {
                  try {
                    let returnValue =
                      eval(`(function(celldata,rowdata,id,controller){return ${templateExpression}})`)
                        (columnData, rowData, "JQTC" + nextTemplateID, this);
                    if (typeof returnValue === "function") {
                      returnValue = this.createDynamicEventCallback(returnValue) + ".apply(this,arguments)";
                    }
                    return returnValue;
                  }
                  catch (ignored) {
                    return "";
                  }
                });
          }
        }
      }
      else {
        column.render = (columnData, type, rowData) => {
          switch (type) {
            case "filter":
            case "type":
            case "sort":
              return columnData;
            case "display":
              if (columnData instanceof Date) {
                return new NoZoneDate(columnData).getClientDate().toLocaleDateString();
              }
              else if (typeof columnData === "string") {
                return escapeHtml(columnData);
              }
              else {
                return columnData;
              }
          }
        }
      }
    }

    // Initialize the settings with our full copy of all nodes to avoid polluting our global
    // metadata objects
    let dataTableSettings: DataTables.Settings = {
      columns,
      drawCallback: (rootsettings: DataTables.Settings) => {
        for(let drawCallback of bdt.drawCallbacks) {
          drawCallback.call(this, rootsettings, tableRoot);
        }
      }
    };

    Object.assign(dataTableSettings, bdt.options);

    // The data table requires disposal and we use the API handle for further accesses rather than
    // trying to find the element every time it's needed
    let dataTableApi = tableRoot.DataTable(dataTableSettings);

    this._dataTableApis[bdt.selector] = dataTableApi;
    this.addDestroyCallback(() => {
      delete this._dataTableApis[bdt.selector];
      dataTableApi.destroy();
    });
  }

  @JQueryController._attachBindingDecorator(DataTableCurrentPage, [])
  public static bindDataTableCurrentPage(selector: string): (target: JQueryController, propertyKey: string) => void {
    return bindingImplementedElsewhere();
  }

  public static bindDataTable(selector: string, datatableOptions?: DataTables.Settings | ((controller: JQueryController) => DataTables.Settings)): (target: JQueryController, propertyKey: string) => void {
    return function (target: JQueryController, propertyKey: string): void {
      JQueryController.trySetupControllerPrototype(target.constructor as typeof JQueryController);
      let options: DataTables.Settings | undefined;
      if(datatableOptions)
      {
        if(typeof datatableOptions== "function") {
            options = datatableOptions(target)
        } else {
          options = datatableOptions;
        }
      }
      
      let bdt = JQueryController._initializeDataTableSourceBinding(target, propertyKey, selector, options );
      bdt.dataSourcePropertyKey = propertyKey;
      JQueryController._bindGetterSetter(target, propertyKey,
        function () { return this._propertyBackingStore[propertyKey] },
        function (value) {
          this._propertyBackingStore[propertyKey] = value;
          let dataTableApi = this._dataTableApis[selector];
          if (dataTableApi) {
            dataTableApi.clear();
            dataTableApi.rows.add(value);
            dataTableApi.draw();

            // If responsive is present, do a recalculation of column widths
            // once the datatable is aware of it's height / width
            if(dataTableApi.responsive)
              dataTableApi.responsive.recalc();
          }
        });
    }
  }

  private static _initializeDataTableSourceBinding(target: JQueryController, propertyKey: string, selector: string, datatableOptions?: DataTables.Settings): _BoundDataTable {
    if (!(selector in target._boundDataTables)) {
      return target._boundDataTables[propertyKey] = {
        dataSourcePropertyKey: propertyKey, selector, columns: [], options: datatableOptions, drawCallbacks: []
      };
    }
    else {
      return target._boundDataTables[selector]
    }
  }

  private static _getDataTableColumnBinding(target: JQueryController, propertyKey: string): _BoundDataTable {
    if (propertyKey in target._boundDataTables) {
      return target._boundDataTables[propertyKey];
    }
    else {
      throw new Error("Column bindings have to be placed before data source bindings for data tables");
    }
  }

  private static _defineDataTableColumn(target: JQueryController, propertyKey: string,
    columnSettings: _BoundDataTableColumnSettings): void {
    JQueryController.trySetupControllerPrototype(target.constructor as typeof JQueryController);
    let bdt = JQueryController._getDataTableColumnBinding(target, propertyKey);
    // Decorators are called bottom-to-top, so to maintain parameter order we need to add
    // them to the start and not the end of the list
    bdt.columns.unshift(columnSettings);
  }

  public static defineDataTableColumn(dataPath: string, columnOptions?: DataTables.ColumnSettings): (target: JQueryController, propertyKey: string) => void {

    var extendedColumnOptions: _BoundDataTableColumnSettings = {
      data: dataPath
    };

    if (columnOptions)
      Object.assign(extendedColumnOptions, columnOptions);

    return function (target: JQueryController, propertyKey: string): void {
      JQueryController._defineDataTableColumn(target, propertyKey, extendedColumnOptions);
    }
  }

  public static defineSelectTableColumn(columnOptions?: DataTables.ColumnSettings): (target: JQueryController, propertyKey: string) => void {

    var extendedColumnOptions: _BoundDataTableColumnSettings = {
      defaultContent: ''
      //className: 'select-checkbox',
      //orderable: false
    };

    if (columnOptions)
      Object.assign(extendedColumnOptions, columnOptions);

    return function (target: JQueryController, propertyKey: string): void {
      JQueryController._defineDataTableColumn(target, propertyKey, extendedColumnOptions);
    }
  }

  public static defineDataTableColumnFromTemplate(dataPath: string, templateSelector: string): (target: JQueryController, propertyKey: string) => void {
    return function (target: JQueryController, propertyKey: string): void {
      // We don't have the template yet, so we'll have to process this again later once the
      // controller is instantiated and we actually have the HTML data to work from
      JQueryController._defineDataTableColumn(target, propertyKey, {
        data: dataPath,
        templateSelector
      });
    }
  }

  //Function called after the datatable draws
  public static defineDrawFunction(selector: string, drawCallback: (element: JQuery) => void) {
    return function (target: JQueryController, propertyKey: string): void {
      JQueryController.trySetupControllerPrototype(target.constructor as typeof JQueryController);
      let bdt = JQueryController._getDataTableColumnBinding(target, propertyKey);
      bdt.drawCallbacks.push(function (this: JQueryController, settings: DataTables.Settings, tableRoot: JQuery) {
        drawCallback.call(this, tableRoot.find(selector));
      });
    }
  }

  private static _controllerConstructors: { [controllerName: string]: typeof JQueryController } = {};
  private static _constructedControllers: { [controllerID: string]: JQueryController } = {};
  private static _nextControllerID = 1;

  public static trySetupControllerPrototype(targetClass: typeof JQueryController): void {
    // Set up controller prototypes have their own copy of controllerName; if we don't have
    // one in this class, it's the first time through for this setup operation and we have
    // something to do
    if (!targetClass.prototype.hasOwnProperty("controllerName")) {
      // We assume that prototype setup will have happened for all of our bases; however, we may have
      // a base class with no decorators that requires setup, so we still manually set up our base
      // class instances first
      // TODO: This seems like superstition at best and should probably not be done - the compiler will ensure that bases are already set up
      let basePrototype = Object.getPrototypeOf(targetClass.prototype);
      if (basePrototype !== JQueryController.prototype) {
        JQueryController.trySetupControllerPrototype(basePrototype.constructor);
      }

      // Webpack seems to rewire certain things with respect to class constructors such that
      // I don't see the name of the class in the constructor function name on hot module
      // replacements; I like the idea of having a separate property for this anyhow so I'm
      // going to clone the name I want into a prototype property
      targetClass.prototype.controllerName = targetClass.name;
  
      // Clone the base prototype arrays that we will want to extend
      targetClass.prototype._bindingConstructors = targetClass.prototype._bindingConstructors.slice();
  
      // In pretty much any scenario I can think of, decorators will always execute in an order
      // that guarantees that our parents have complete metadata before we get called here; just
      // in case there's a scenario I didn't think of (to maximize flexibility and given the fact
      // that it won't change the end-user experience of the system) we use prototypical
      // inheritance so our child metadata is updated when parent metadata is added
      targetClass.prototype._boundDataTables = Object.create(targetClass.prototype._boundDataTables);
      targetClass.prototype._validatedSelectors = Object.create(targetClass.prototype._validatedSelectors);
  
      // Finally, register our name so that we can be bound to HTML
      JQueryController._controllerConstructors[targetClass.prototype.controllerName] = targetClass;
    }
  }

  public controllerName!: string;

  @JQueryComponent.prototypeValue({}) private _boundDataTables!: { [dataPropertyKey: string]: _BoundDataTable };
  @JQueryComponent.prototypeValue({}) private _validatedSelectors!: { [selector: string]: boolean };

  public static updateControllers(... controllerNames: string[]): void {
    let controllersToReplace: JQueryController[] = [];
    for (let controllerID in JQueryController._constructedControllers) {
      let controller = JQueryController._constructedControllers[controllerID];
      // HACK: TypeScript detects this situation as being never fulfilled, so we have to cast the controller class to any
      if (!(controller instanceof (JQueryController._controllerConstructors[controller.controllerName] as any))) {
        // We only replace controllers if there is no parent controller that also has a new
        // controller class (if there is, it will recreate these controllers when it is re-
        // instantiated anyhow)
        let parentController = controller._parentController;
        while (parentController) {
          if (!(controller instanceof (JQueryController._controllerConstructors[controller.controllerName] as any))) {
            // If we get here, a parent controller needs reconstruction too, and we won't need
            // to manually construct this one
            continue;
          }
        }

        // If we get here, this controller is using a different class than what we expect and
        // all of its parents are set to expected class values; we need to reinitialize this
        // controller
        // TODO: The hot module replacement feels like it should be implemented in a separate file?
        // TODO: Maintain current state and constructor arguments
        let controllerTarget = controller.rootElement;
        controller.destroy();
        // TODO: Propagate args instead of using an empty argument array
        JQueryController.bindNewController(jQuery(controllerTarget), controller.controllerName, []);
      }
    }
  }

  public executeServiceCall<TResponse>(servicePath: string, body?: any): Promise<TResponse> {
    return body instanceof Blob ? executeBinaryUploadCall<TResponse>(servicePath, body) : executeServiceCall<TResponse>(servicePath, body);
  }

  public getQueryString(): { [key: string]: string | boolean } {
    // Get the string, ignore it if there's nothing there, and strip off the leading '?'
    let queryString = location.search;
    if (!queryString || queryString === "?") {
      return {};
    }
    queryString = queryString.substr(1);

    // Split the string by ampersand, then equals sign, then map the results to the return value
    let returnValue: { [key: string]: string | boolean } = {};
    for (var keyValuePair of queryString.split("&").map(x => x.split("="))) {
      returnValue[keyValuePair[0]] = keyValuePair.length !== 1 ? keyValuePair[1] : true;
    }
    return returnValue;
  }
}

export interface JQueryControllerConstructor<T extends JQueryController = JQueryController> {
  new(rootElement: JQuery, ... parentArguments: any[]): T;
}

export interface HttpResponse<TResponseBody> {
  statusCode: number;
  statusText: string;
  body: TResponseBody;
  bodyText: string;
}

function bindingImplementedElsewhere(): never {
  throw new Error("A binding was not correctly attached to a JQueryController binding method");
}