import { JQueryPropertyBinding } from "../jqueryBinding";

// The user of this binding should use a selector that is associated with the element containing
// the data-toggle="collapse"

export class ExpanderBinding extends JQueryPropertyBinding<boolean> {
  public getBoundValue(boundElements: JQuery): boolean {
    return boundElements.attr("aria-expanded") === "true" ? true : false;
  }
  public setBoundValue(boundElements: JQuery, value: boolean): void {
    let contentDiv = jQuery(boundElements.attr("data-target")!);
    if (value) {
      boundElements.attr("aria-expanded", "true");
      if (boundElements.hasClass("collapsed")) boundElements.removeClass("collapsed");
      if (!contentDiv.hasClass("show")) contentDiv.addClass("show");
    }
    else {
      boundElements.attr("aria-expanded", "false");
      if (!boundElements.hasClass("collapsed")) boundElements.addClass("collapsed");
      if (contentDiv.hasClass("show")) contentDiv.removeClass("show");
    }
  }
}