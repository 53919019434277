import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { currentUser } from "../shared/currentUser"
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";


interface Drive {
  DriveID: string;
  Name: string;
  StartDate: string;
  EndDate?: string;
  NumberOfCompanies: boolean;
}

interface DriveListingQuery {
  createdDrive?: string;
}

export class DriveListingController extends JQueryController {
  
  @JQueryController.bindDisplayText("#created-drive")
  public createdUser!: string;
  
  @JQueryController.defineDataTableColumn("Name", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("StartDate", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("EndDate", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("NumberOfCompanies")
  @JQueryController.defineDataTableColumnFromTemplate("DriveID", "#user-list-table-action-column-template")
  @JQueryController.bindDataTable("#user-list-table", {
    responsive: {
      details: false
    },
    language: {
      search: "Search"
    },
    columnDefs: [
      { responsivePriority: 1, targets: 0 },
      { responsivePriority: 1, targets: 1 },
      { responsivePriority: 3, targets: 2 },
      { responsivePriority: 3, targets: 3 },
      { responsivePriority: 2, targets: 4 }
    ]
  })
  public drives: Drive[] = [];

  public constructor(rootElement: JQuery) {
    super(rootElement);
    if(!currentUser || !currentUser.canManageDrives) {
      window.location.href = "/notes.html"
    }
    this._populateTable();
  }


  public pageLoaded() : void {
    var query = QueryParser.getQuery<DriveListingQuery>(true);
    if(query && query.createdDrive) {
      this.createdUser = query.createdDrive;
      JQueryMessageController.showMessage("created-drive-message");
    }
  }

  
  private async _populateTable() {
    if (currentUser && currentUser.canManageDrives) {
      this.drives = await this.executeServiceCall<Drive[]>("drives/getDrives");
    }
  }

}