import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { QueryParser } from "../shared/queryParser";
import { currentUser } from "../shared/currentUser"
import { DateTime } from "shared/datetime";
import { HttpException } from "@legacyx/web-uitoolkit/httpException";


interface APIKey {
  Name: string;
  Key: string;
  //HTML only accepts lowercase
  isactive?: boolean;
  CreationDate: DateTime;
}

export class APIKeyListingController extends JQueryController {
  
  @JQueryController.bindDisplayText("#created-company")
  public createdCompany!: string;
  
  @JQueryController.bindInputValue("#APIName") public name ?: string;

  @JQueryController.bindEnabled(".switchInput") public areTogglesEnabled!: boolean;

  //Disabled opacity controls
  @JQueryController.bindAttribute('.switchToggle', 'style') public toggleStyle!:string;

  @JQueryController.defineDataTableColumn("Name", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("Key", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("CreationDate", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumnFromTemplate("isactive", "#company-list-table-action-column-template")
  @JQueryController.defineDataTableColumn("Name")
  @JQueryController.bindDataTable("#company-list-table", {
    responsive: {
      details: false
    },
    language: {
      search: "Search"
    },
    columnDefs: [
      { responsivePriority: 1, targets: 0 },
      { responsivePriority: 3, targets: 1 },
      { responsivePriority: 2, targets: 2 },
      { responsivePriority: 1, targets: 3 },
      { responsivePriority: 1, targets: 4}
    ]
  })
  public apiKeys: APIKey[] = [];

  public constructor(rootElement: JQuery) {
    super(rootElement);
    
    this._populateTable();
  }

  public pageLoaded() : void {
    var query = QueryParser.getQuery<{createdCompany: string}>(true);
    if(query && query.createdCompany) {
      this.createdCompany = query.createdCompany;
      JQueryMessageController.showMessage("created-company-message");
    }
  }

  private async _populateTable() {
      this.apiKeys = await this.executeServiceCall<APIKey[]>("keys/getKeys");
  }

  @JQueryController.bindClick("#generateAPIKey")
  public async generateAPIKey(e: Event) {
    e.preventDefault();
    var APIKey = await this.executeServiceCall<string>("keys/createKey", {
      name: this.name
    })
  }

  public async toggleAPIKey(event: JQuery.TriggeredEvent<HTMLElement>, apikey: APIKey) {
    try {
      this.toggleStyle = "opacity: 0.5";
      this.areTogglesEnabled = false;
      await this.executeServiceCall("keys/toggleKey",{
        key: apikey.Key,
        isActive: !apikey.isactive
      });
    } catch (error) {
      let httpError = error as HttpException;
      if(httpError.errorPayload && httpError.errorPayload.ErrorMessage) {
        // TODO: how am I getting a message onto the screen? I guess the controller just catches the 409.
      }
      throw httpError;
    }
    this.toggleStyle = "";
    this.areTogglesEnabled = true;
  }

  @JQueryController.bindClick("#bsButton")
  public async bsButton(e: Event){
  }

}