import "./globalSetup";

// Initialize other jQuery plugins once we have our global objects
import "parsleyjs/src/parsley";
import "bootstrap-datepicker";
import "croppie";
import "select2";
//import "select2/dist/css/select2.min.css"; Doesn't seem to work properly
import "summernote/dist/summernote-bs4";
//import "summernote/dist/summernote-bs4.css"; Not supported because of embedded fonts

// Dropzone doesn't properly initialize itself as a jQuery extension unless we have jQuery in the
// global space first; we also want to initialize it ourselves and not use the autoloader
let Dropzone = require("dropzone");
Dropzone.autoDiscover = false;

// DataTables.net doesn't set any global state but requires things like jQuery to be installed
// first; I think these things work properly with the factory setup we have (i.e. they don't
// depend on globals if they can load dependencies via require) but just to be safe I'll add
// these things after setting up the globals above
// TODO: This doesn't appear to load the full set of dependencies i.e. colvis, flash, print
// Here's a list of tags in the original HTML files that I think are being ignored by the new NPM-based imports:
// <script src="assets/lib/datatables/datatables.net-buttons/js/buttons.html5.min.js" type="text/javascript"></script>
// <script src="assets/lib/datatables/datatables.net-buttons/js/buttons.flash.min.js" type="text/javascript"></script>
// <script src="assets/lib/datatables/datatables.net-buttons/js/buttons.print.min.js" type="text/javascript"></script>
// <script src="assets/lib/datatables/datatables.net-buttons/js/buttons.colVis.min.js" type="text/javascript"></script>
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-select";

// TODO: These likely shouldn't have to be imported manually, but they don't work unless you reference them one by one.
// We should figure out why and fix this.
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";

// Apply any styling that we extracted from the theme's sample files
// TODO: Uncomment this once we have data tables properly merged here
import "@legacyx/web-uitoolkit/themeInitialization";

// Import additional parsley rules that we've added
import "@legacyx/web-uitoolkit/parsleyValidators";

// Import globally used controllers
import "@legacyx/web-uitoolkit/jqueryMessageController";
import "@legacyx/web-uitoolkit/jqueryErrorController";
import "@legacyx/web-uitoolkit/jqueryBreadcrumbController";