import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { currentUser } from "../shared/currentUser"
import { QueryParser } from "../shared/queryParser";
import { AgreementListingQuery } from "../model/agreement";


interface Agreement {
  AgreementID: string;
  Name: string;
  Union: string;
  EffectiveDate: string;
  ExpiryDate: string;
  NumberOfCompanies: boolean;
}

export class AgreementListingController extends JQueryController {
  
  @JQueryController.bindDisplayText("#created-agreement")
  public createdAgreement!: string;
  
  @JQueryController.defineDataTableColumn("Name", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("Union", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("EffectiveDate", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("ExpiryDate", { className: 'hide-table-column-at-small-responsive-size' })
  @JQueryController.defineDataTableColumn("AgreementLink")
  @JQueryController.defineDataTableColumnFromTemplate("AgreementID", "#agreement-list-table-action-column-template")
  @JQueryController.bindDataTable("#agreement-list-table", {
    responsive: {
      details: false
    },
    language: {
      search: "Search"
    },
    columnDefs: [
      { responsivePriority: 1, targets: 0 },
      { responsivePriority: 1, targets: 1 },
      { responsivePriority: 3, targets: 2 },
      { responsivePriority: 3, targets: 3 },
      { responsivePriority: 3, targets: 4 },
      { responsivePriority: 2, targets: 5 }
    ]
  })
  public agreements: Agreement[] = [];

  public constructor(rootElement: JQuery) {
    super(rootElement);
    if(!currentUser || !currentUser.canManageDrives) {
      window.location.href = "/notes.html"
    }
    this._populateTable();
  }

  public pageLoaded() : void {
    var query = QueryParser.getQuery<AgreementListingQuery>(true);
    if(query && query.createdAgreement) {
      this.createdAgreement = query.createdAgreement;
      JQueryMessageController.showMessage("created-agreement-message");
    }
  }

  private async _populateTable() {
    if (currentUser && (currentUser.canManageReports)) {
      this.agreements = await this.executeServiceCall<Agreement[]>("agreement/getAgreements");
    }
  }

}