import { JQuerySingleEventBinding } from "../jqueryBinding";

export class ClickBinding extends JQuerySingleEventBinding {
  public readonly handlerName = "click";
}

export class InputChangeBinding extends JQuerySingleEventBinding {
  public readonly handlerName = "change";
}

export class EnterKeyBinding extends JQuerySingleEventBinding {
  public readonly handlerName = "keypress";

  public jqueryEventHandler(e: JQuery.Event): void {
    if (e.keyCode == 13) {
      e.preventDefault();
      this.invokeHandler();
    }
  }
}

export class GeneralEventBinding extends JQuerySingleEventBinding {
  public eventName!: string;
  public get handlerName() {
    return this.eventName;
  }
}