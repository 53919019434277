import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { getPasswordHash } from "../model/authentication";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { AccountListingQuery, AccountSingleQuery } from "../model/account"
import { currentUser } from "../shared/currentUser"

interface AddAdminChallengeResponse {
  UserAccountID: string,
  InternalUserLoginID: string, 
  PublicSalt: string
}

interface UserDetails {
  FirstName: string,
  LastName: string, 
  LoginName: string,
  IsEnabled: boolean,
  IsAdminAccount: boolean,
  IsSuperAdminAccount: boolean,
  UserAccountID: string,
  CanManageAccounts: boolean,
  CanManageDrives: boolean,
  CanManageReports: boolean,
  CanManageAgreements: boolean,
  CanManageBilling: boolean,
  IsOwner: boolean,
}

interface UserCreationAbility {
  StripeAdminUserCount: number,
  StripeRegularUserCount: number,
  CurrentAdminUserCount: number,
  CurrentRegularUserCount: number
}

export class AccountSingleController extends JQueryController {
  @JQueryController.bindVisibility('#new-account-header') public newAccountHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#update-account-header') public updateAccountHeaderVisibility!: boolean;
  @JQueryController.bindDisplayText("#cookieName") public cookieName!: string;
  @JQueryController.bindVisibility(".new-user-button") public newUserButonVisibility: boolean;
  @JQueryController.bindVisibility(".edit-user-button") public editUserButtonVisiblity: boolean;

  @JQueryController.bindDisplayHtml("#account-warning-message-regular-user") public regularUsersWarningMessage = "";
  @JQueryController.bindDisplayHtml("#account-warning-message-admin-user") public adminUsersWarningMessage = "";
  

  @JQueryController.bindInputValue("#firstname") public firstName!: string;
  @JQueryController.bindInputValue("#lastname") public lastName!: string;
  @JQueryController.bindInputValue("#email") public emailAddress!: string;

  @JQueryController.bindInputValue("#password1") public password!: string;
  @JQueryController.bindInputValue("#password2") public confirmPassword!: string;

  
  
  //Form elements
  @JQueryController.bindInputChecked("#enabled") public isEnabled!: boolean;
  @JQueryController.bindInputChecked("#admin-swt") public isAdmin!: boolean;
  @JQueryController.bindInputChecked("#super-admin-swt") public isSuperAdmin!: boolean;
  @JQueryController.bindInputChecked("#accounts-swt") public canManageAccounts!: boolean;
  @JQueryController.bindInputChecked("#drives-swt") public canManageDrives!: boolean;
  @JQueryController.bindInputChecked("#reports-swt") public canManageReports!: boolean;
  @JQueryController.bindInputChecked("#agreements-swt") public canManageAgreements!: boolean;
  @JQueryController.bindInputChecked("#billing-swt") public canManageBilling!: boolean;
  @JQueryController.bindInputChecked("#owner-swt") public isOwner!: boolean;

  
  //Form Enabled Elements
  @JQueryController.bindEnabled(".general-field") public areFieldsEnabled!: boolean;
  @JQueryController.bindEnabled("#enabled") public isEnabledIsEnabled!: boolean;
  @JQueryController.bindEnabled("#admin-swt") public isAdminIsEnabled!: boolean;
  @JQueryController.bindEnabled("#super-admin-swt") public isSuperAdminIsEnabled!: boolean;
  @JQueryController.bindEnabled("#accounts-swt") public isAccountsEnabled!: boolean;
  @JQueryController.bindEnabled("#drives-swt") public isDrivesEnabled!: boolean;
  @JQueryController.bindEnabled("#reports-swt") public isReportsEnabled!: boolean;
  @JQueryController.bindEnabled("#agreements-swt") public isAgreementsEnabled!: boolean;
  @JQueryController.bindEnabled("#billing-swt") public isBillingEnabled!: boolean;
  @JQueryController.bindEnabled("#owner-swt") public isOwnerEnabled!: boolean;
  @JQueryController.bindEnabled(".update-create-buttons") public isCreateButtonEnabled!: boolean;

  //Disabled opacity controls
  @JQueryController.bindAttribute('#accounts-swt-toggle', 'style') public accountsToggleStyle!:string;
  @JQueryController.bindAttribute('#agreements-swt-toggle', 'style') public agreementsToggleStyle!:string;
  @JQueryController.bindAttribute('#drives-swt-toggle', 'style') public drivesToggleStyle!:string;
  @JQueryController.bindAttribute('#owner-swt-toggle', 'style') public ownerToggleStyle!:string;
  @JQueryController.bindAttribute('#reports-swt-toggle', 'style') public reportsToggleStyle!:string;
  @JQueryController.bindAttribute('#billing-swt-toggle', 'style') public billingToggleStyle!:string;
  @JQueryController.bindVisibility('#permission-alert') public permissionAlertVisibility = false;
  @JQueryController.bindDisplayHtml('#permission-alert-message') public permissionAlertMessage = "";


  @JQueryController.bindParsleyValidation("#main-form") public isMainFormValid!: boolean;
  @JQueryController.bindParsleyValidation("#password-form") public isPasswordFormValid!: boolean;

  private _userAccountID?: string;
  private _cleanUser: UserDetails = {
    UserAccountID: "",
    LoginName: "",
    FirstName: "",
    LastName: "",
    IsEnabled: true,
    IsAdminAccount: false,
    IsSuperAdminAccount: false,
    CanManageAccounts: false,
    CanManageAgreements: false,
    CanManageBilling: false,
    CanManageDrives: false,
    CanManageReports: false,
    IsOwner: false,
};

    private _isAdminAccount = false;
  private isNewUser = true;
    constructor(rootElement: JQuery<HTMLElement>, userAccountID?: string) {
    super(rootElement);
    this.newUserButonVisibility = false;
    this.editUserButtonVisiblity = false;
    var query = QueryParser.getQuery<AccountSingleQuery>(false);
    if(!currentUser || !currentUser.canManageAccounts) {
      window.location.href = "/notes.html"
    }
    if(query && query.userAccountID && currentUser) {
      this.areFieldsEnabled = this.areFieldsEnabled || query.userAccountID == currentUser.userAccountID;
      this._userAccountID = query.userAccountID;
      this.LoadMemberInformation();
      this.setNewUserVisiblity(false);
      this.setHeader(false);
    } else {
      this.cookieName = "New Account";
      this.setHeader(true);
      this.setNewUserVisiblity(true);

      this.areFieldsEnabled = currentUser != undefined && currentUser.canManageAccounts;
      this.isEnabledIsEnabled = currentUser != undefined && currentUser.canManageAccounts;
      this.isOwnerEnabled = currentUser != undefined && currentUser.isOwner;
      this.isAccountsEnabled = currentUser != undefined && currentUser.canManageAccounts;
      this.isAgreementsEnabled = currentUser != undefined && currentUser.canManageAgreements;
      this.isBillingEnabled = currentUser != undefined && currentUser.canManageBilling;
      this.isReportsEnabled = currentUser != undefined && currentUser.canManageReports;
      this.isDrivesEnabled = currentUser != undefined && currentUser.canManageDrives;
      this.setToggleOpacity()
    }
  }
  
  private setHeader(isNew: boolean) {
    this.newAccountHeaderVisibility = isNew;
    this.updateAccountHeaderVisibility = !isNew;
  }

  private setNewUserVisiblity(isNewUser: boolean) {
      this.editUserButtonVisiblity = !isNewUser;
      this.newUserButonVisibility = isNewUser;
  }

  private setUIValues(userInformation: UserDetails) {
    this.firstName = userInformation.FirstName;
    this.lastName = userInformation.LastName;
    this.cookieName = `${this.firstName} ${this.lastName}`
    this.emailAddress = userInformation.LoginName;
    this.isEnabled = userInformation.IsEnabled;
    this.canManageAccounts = userInformation.CanManageAccounts;
    this.canManageAgreements = userInformation.CanManageAgreements;
    this.canManageBilling = userInformation.CanManageBilling;
    this.canManageDrives = userInformation.CanManageDrives;
    this.canManageReports = userInformation.CanManageReports;
    this.isOwner = userInformation.IsOwner;
    this._cleanUser = {...userInformation};
  }

  public async LoadMemberInformation() {
    this.isNewUser = false;
    var userInformation = await this.executeServiceCall<UserDetails>("users/getUser",
    {
      userAccountID: this._userAccountID
    });
    this.setUIValues(userInformation);
    //We want admins to be able to edit everyone except other admins, unless they are the owner.
    this.areFieldsEnabled = currentUser != undefined && (currentUser.isOwner || currentUser.canManageAccounts || currentUser.userAccountID == userInformation.UserAccountID);
    this.isEnabledIsEnabled = currentUser != undefined && (currentUser.isOwner || currentUser.canManageAccounts || currentUser.userAccountID == userInformation.UserAccountID);
    this.isOwnerEnabled = currentUser != undefined && currentUser.isOwner && userInformation.UserAccountID != currentUser.userAccountID;
    if (currentUser != undefined && currentUser.isOwner && userInformation.UserAccountID == currentUser.userAccountID) {
      this.permissionAlertVisibility = true;
      this.ownerToggleStyle = "opacity: 0.5";
      this.permissionAlertMessage = "You are unable to modify your own owner permission, so it has been disabled";
    }
    this.isAccountsEnabled = currentUser != undefined && (currentUser.isOwner || currentUser.canManageAccounts || currentUser.userAccountID == userInformation.UserAccountID) && !userInformation.IsOwner;
    this.isAgreementsEnabled = currentUser != undefined && (currentUser.isOwner || currentUser.canManageAgreements || currentUser.userAccountID == userInformation.UserAccountID) && !userInformation.IsOwner;
    this.isBillingEnabled = currentUser != undefined && (currentUser.isOwner || currentUser.canManageBilling  || currentUser.userAccountID == userInformation.UserAccountID) && !userInformation.IsOwner;
    this.isReportsEnabled = currentUser != undefined && (currentUser.isOwner || currentUser.canManageReports  || currentUser.userAccountID == userInformation.UserAccountID) && !userInformation.IsOwner;
    this.isDrivesEnabled = currentUser != undefined && (currentUser.isOwner || currentUser.canManageDrives  || currentUser.userAccountID == userInformation.UserAccountID) && !userInformation.IsOwner;
    this.setToggleOpacity()
  }

  @JQueryController.bindClick("#cancelMainForm") 
  public cancelInput() {
    this.setUIValues(this._cleanUser);
  }

  @JQueryController.bindClick("#discardPassword") 
  public discardPassword() {
    this.password = "";
    this.confirmPassword = ""
  }
  
  @JQueryController.bindInputChange("#admin-swt")
  public isAdminChanged() {
    if(!this.isAdmin) {
      this.isSuperAdmin = false;
    }
  }

  @JQueryController.bindInputChange("#super-admin-swt")
  public isSuperAdminChanged() {
    if(this.isSuperAdmin) {
      this.isAdmin = true;
    }   
  }

  @JQueryController.bindInputChange("#enabled")
  public enabledChanged() {
    if(!this.isEnabled) {
      
    }   
  }

  @JQueryController.bindInputChange("#owner-swt")
  public isOwnerChanged() {
      this.isAccountsEnabled = !this.isOwner; 
      this.isAgreementsEnabled = !this.isOwner;
      this.isBillingEnabled = !this.isOwner;
      this.isReportsEnabled = !this.isOwner;
      this.isDrivesEnabled = !this.isOwner;
      this.canManageAccounts = this.isOwner;
      this.canManageAgreements = this.isOwner;
      this.canManageBilling = this.isOwner;
      this.canManageDrives = this.isOwner;
      this.canManageReports = this.isOwner;
      this.setToggleOpacityOwner(this.isOwner);
  }

  @JQueryController.bindClick("#cancelCreate") 
  public cancelCreateAccount() {
    window.location.href = '/accounts.html'
  }

  @JQueryController.bindClick("#save")
  @JQueryController.bindValidation("#main-form")
  public async UpdateUser() {
    let canCreateUser = true;

    if (canCreateUser){
      if(this._userAccountID) {
        this._cleanUser = {
          UserAccountID: this._userAccountID,
          LoginName: this.emailAddress,
          FirstName: this.firstName,
          LastName: this.lastName,
          IsEnabled: this.isEnabled,
          IsAdminAccount: this.isAdmin,
          IsSuperAdminAccount: this.isSuperAdmin,
          CanManageAccounts: this.canManageAccounts,
          CanManageAgreements: this.canManageAgreements,
          CanManageBilling: this.canManageBilling,
          CanManageDrives: this.canManageDrives,
          CanManageReports: this.canManageReports,
          IsOwner: this.isOwner
        }
        
        var response = await this.executeServiceCall<AddAdminChallengeResponse>("users/updateAdmin",
        {
          payload: this._cleanUser
        });
        JQueryMessageController.showMessage("updated-account");
      }
    }
  }

  public get isAdminAccount(): boolean {
    return this._isAdminAccount;
  }

  public set isAdminAccount(status :boolean){
    this._isAdminAccount = status;
    this.showAccountWarnings(this.isNewUser);
  }

  @JQueryController.bindInputChange(".admin-toggle")
  public CheckAdminStatus(){
    this.isAdminAccount = this.canManageAccounts || this.canManageAgreements || this.canManageBilling || this.canManageDrives || this.canManageReports || this.isOwner;
  }

  public showAccountWarnings(isNewUser:boolean) {
    JQueryMessageController.hideAllMessages()
    let canCreateRegularUsers = true;
    let canCreateAdminUsers = true;
    
    this.isCreateButtonEnabled = true;
    
  }

  


  @JQueryController.bindClick("#updatePassword")
  @JQueryController.bindValidation("#password-form")
  public async UpdatePasswords() {
    var response = await this.executeServiceCall<{PublicSalt: string, InternalUserLoginID: string}>("users/requestPasswordReset",
    {userAccountID: this._userAccountID});

    await this.executeServiceCall<null>("users/addAdminResponse", {
      internalUserLoginID: response.InternalUserLoginID,
      publicHash: getPasswordHash(this.password, response.PublicSalt)
    });
    JQueryMessageController.showMessage("changed-password");
  }

  @JQueryController.bindClick("#createUser")
  // Bind validation accross two forms will stop if the first form is not valid
  // @JQueryController.bindValidation("#main-form")
  // @JQueryController.bindValidation("#password-form")
  public async CreateUser(e: JQuery.Event): Promise<void> {
    
    //inorder to get both to be evaluated read them into
    //variables then use the variables in the if statement.
    //If we don't do this then the user won't see that the password is required
    //until the first form is valid
    var mainFormValid = this.isMainFormValid;
    var passwordFormValid = this.isPasswordFormValid;

    if(!mainFormValid || !passwordFormValid) {
      return Promise.resolve();
    }
    let canCreateAccount = true;

    if (canCreateAccount){

      var response = await this.executeServiceCall<AddAdminChallengeResponse>("users/addAdminChallenge",
      {
        payload: {
          LoginName: this.emailAddress,
          FirstName: this.firstName,
          IsEnabled: this.isEnabled,
          LastName: this.lastName,
          CanManageAccounts: this.canManageAccounts,
          CanManageAgreements: this.canManageAgreements,
          CanManageBilling: this.canManageBilling,
          CanManageDrives: this.canManageDrives,
          CanManageReports: this.canManageReports,
          IsOwner: this.isOwner
        }
      });
      await this.executeServiceCall<null>("users/addAdminResponse", {
        internalUserLoginID: response.InternalUserLoginID,
        publicHash: getPasswordHash(this.password, response.PublicSalt)
      });
      this._userAccountID = response.UserAccountID;
      this.setNewUserVisiblity(false);
      
      window.location.href = "/accounts.html"+ QueryParser.BuildQueryString<AccountListingQuery>({createdUser: `${this.firstName} ${this.lastName}`});
  }
  }

  private setToggleOpacity(){
      this.accountsToggleStyle = this.isAccountsEnabled ? "" : "opacity: 0.5"
      this.agreementsToggleStyle = this.isAgreementsEnabled ? "" : "opacity: 0.5"
      this.billingToggleStyle = this.isBillingEnabled ? "" : "opacity: 0.5"
      this.drivesToggleStyle = this.isDrivesEnabled ? "" : "opacity: 0.5"
      this.reportsToggleStyle = this.isReportsEnabled ? "" : "opacity: 0.5"
      this.ownerToggleStyle = this.isOwnerEnabled ? "" : "opacity: 0.5"
    if (this.accountsToggleStyle || this.agreementsToggleStyle || this.billingToggleStyle || this.drivesToggleStyle || this.reportsToggleStyle) {
      this.permissionAlertVisibility = true;
      this.permissionAlertMessage = "One or more permissions have been disabled as you do not have permission to grant or modify them";
    }
  }

  private setToggleOpacityOwner(isOwner: boolean){
      this.accountsToggleStyle = !isOwner ? "" : "opacity: 0.5"
      this.agreementsToggleStyle = !isOwner ? "" : "opacity: 0.5"
      this.billingToggleStyle = !isOwner ? "" : "opacity: 0.5"
      this.drivesToggleStyle = !isOwner ? "" : "opacity: 0.5"
      this.reportsToggleStyle = !isOwner ? "" : "opacity: 0.5"
      if (isOwner) {
        this.permissionAlertVisibility = true;
        this.permissionAlertMessage = "Granting the owner permission grants all other permissions, and they cannot be toggled off";
    } else {
      this.permissionAlertVisibility = false;
    }
  }

 

  private IsOriginalAccountAdmin():boolean {
    let originalUser = this._cleanUser;
    return originalUser.CanManageAccounts || originalUser.CanManageAgreements || originalUser.CanManageBilling || originalUser.CanManageDrives || originalUser.CanManageReports || originalUser.IsOwner;
  }
}