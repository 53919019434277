import { JQueryReadOnlyPropertyBinding, BindableTarget } from "../jqueryBinding";

// TODO: This binding breaks if it has a repeating controller as a parent - fixing this may require breaking changes

// TODO: This is a circular reference! We should eliminate this!
import { JQueryController } from "../jqueryController";
import { options } from "dropzone";

export class ControllerBinding extends JQueryReadOnlyPropertyBinding<JQueryController> {
  public childController!: JQueryController;
  public controllerArgs?: any[] | ((context: BindableTarget) => any[]);

  public getBoundValue(boundElements: JQuery): JQueryController {
    return JQueryController.getControllerBySelector(this.selector);
  }
}

export interface ChildControllerOptions {
  controllerArgs?: any[] | ((context: BindableTarget) => any[]);
  // TODO: The default for restrictChildControllerToRootElement should be "true" but that would be a breaking change; this update needs a broader API fix
  restrictChildControllerToRootElement: boolean;
}

export class ChildControllerBinding extends JQueryReadOnlyPropertyBinding<JQueryController | undefined> {
  public childController?: JQueryController;
  public controllerArgsOrOptions?: any[] | ((context: BindableTarget) => any[]) | ChildControllerOptions;

  public getBoundValue(boundElements: JQuery): JQueryController | undefined {
    return this.childController;
  }

  public initialize(): void {
    super.initialize();

    // TODO: Revisit the use of timeouts to delay work - is this the right approach?
    setTimeout(() => {
      // Get the controller args if provided; we support some backwards-compatible syntax so this
      // gets a bit convoluted
      // TODO: Deprecate the non-option-object syntax
      let controllerArgs: any[] | ((context: BindableTarget) => any[]) | undefined;
      if (typeof this.controllerArgsOrOptions === "function" || Array.isArray(this.controllerArgsOrOptions)) {
        controllerArgs = this.controllerArgsOrOptions
      }
      else if (this.controllerArgsOrOptions) {
        controllerArgs = this.controllerArgsOrOptions.controllerArgs;
      }

      // Get the resolved controller args
      let resolvedControllerArgs: any[];
      if (typeof controllerArgs === "function") {
        resolvedControllerArgs = controllerArgs(this.target);
      }
      else if (controllerArgs) {
        resolvedControllerArgs = controllerArgs;
      }
      else {
        resolvedControllerArgs = [];
      }

      // Get the target root element
      let targetRootElement: JQuery | undefined;
      if (typeof this.controllerArgsOrOptions === "object" && !Array.isArray(this.controllerArgsOrOptions)) {
        targetRootElement = this.controllerArgsOrOptions.restrictChildControllerToRootElement ? this.target.rootElement : undefined;
      }

      // Create the controller
      var controllerInfo = JQueryController.tryInitializeController(this.selector, resolvedControllerArgs, targetRootElement);
      if (controllerInfo) {
        this.childController = controllerInfo.controller;
      }
      else {
        this.childController = undefined;
      }
    }, 0);
  }

  public destroy(): void {
    super.destroy();
    
    if (this.childController) {
      this.childController.close();
    }
  }

  public disconnect(): any {
    debugger; // TODO: Implement
  }

  public reconnect(state: any): void {
    debugger; // TODO: Implement
  }
}
