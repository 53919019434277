import { JQueryController } from "./jqueryController";
import { HttpException } from "./httpException";

export enum ErrorStatus {
  Unexpected = -1,
  AccessDenied = 403,
  Conflict = 409
}

export enum ErrorScope {
  Unspecified = 0,
  EncompassingController, Global
}

export interface ErrorDisplayConditions {
  status: ErrorStatus;
  scope: ErrorScope;
}

export class JQueryErrorController extends JQueryController {
  public static activeControllers: JQueryErrorController[] = [];
  public static readonly defaultErrorDisplayConditions: Readonly<ErrorDisplayConditions> = {
    status: ErrorStatus.Unexpected,
    scope: ErrorScope.Global
  };

  // TODO: The syntax below is unwieldy enough that I think we might need some overloads/API tweaks
  @JQueryController.bindAttribute("[jquery-controller-show-on-error]", "jquery-controller-show-on-error") public rawShowOnErrorConditions!: string;
  public get showOnErrorConditions(): ErrorDisplayConditions {
    if (!this.rawShowOnErrorConditions) {
      return JQueryErrorController.defaultErrorDisplayConditions;
    }
    var splitErrorStatus = this.rawShowOnErrorConditions.split(":");
    switch (splitErrorStatus.length) {
      case 1: return {
        scope: ErrorScope.EncompassingController,
        status: ErrorStatus[splitErrorStatus[0] as any] as any as ErrorStatus
      };
      case 2: return {
        scope: ErrorScope[splitErrorStatus[0] as any] as any as ErrorScope,
        status: ErrorStatus[splitErrorStatus[1] as any] as any as ErrorStatus
      };
      default: return JQueryErrorController.defaultErrorDisplayConditions;
    }
  }

  @JQueryController.bindAttribute("[jquery-controller-has-custom-message]","jquery-controller-has-custom-message") public rawHasCustomErrorMessage!: string;
  public get hasCustomErrorMessage(): boolean {
    return this.rawHasCustomErrorMessage === "true";
  }
  @JQueryController.bindDisplayText(".custom-message") public customErrorMessage!: string;

  public constructor(rootElement: JQuery) {
    super(rootElement);

    JQueryErrorController.activeControllers.push(this);
    this.addDestroyCallback(() => JQueryErrorController.activeControllers.splice(JQueryErrorController.activeControllers.indexOf(this), 1));
  }

  @JQueryController.bindClick("button")
  public hide(): void {
    this.rootElement.attr("hidden", "");
  }

  public show(): void {
    this.rootElement.removeAttr("hidden");
  }

  @JQueryController.bindGlobalErrorHandler()
  public globalErrorRaised(error: HttpException, source: JQueryController): void {
    let errorConditions = this.showOnErrorConditions;
    let errorCode = error.status;

    // For unexpected codes, only show this message if it isn't handled by some other handler;
    // for anything else, check to make sure we respond to the requested code
    if (errorConditions.status === ErrorStatus.Unexpected) {
      for (let activeController of JQueryErrorController.activeControllers) {
        if (activeController != this && activeController.showOnErrorConditions.status == errorCode) {
          return;
        }
      }
    }
    else if (errorConditions.status != error.status) {
      return;
    }

    // If we are in controller scope, ensure that we are currently embedded in a controller
    // whose messages we care about
    switch (errorConditions.scope) {
      case ErrorScope.EncompassingController:
        if (source.elementsContainComponent(this)) break; else return;
      default: // Treat everything else as global
    }

    // If we make it to here, we are the controller to show; set any custom error messages and
    // then show them
    if(this.hasCustomErrorMessage) {
      this.customErrorMessage = error.message
    }
    this.show();
  }

  @JQueryController.bindClearGlobalErrorHandler()
  public globalErrorCleared(): void {
    this.hide();
  }
}