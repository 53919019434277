export class QueryParser {


  public static getQuery<T>(clearQueryAfterReading: boolean) : T | null {
     //remove ? from query
     var searchString = decodeURIComponent(window.location.search.substr(1));
     if(searchString === ""){
       return null;
     }
     var parameterArray = searchString.split('&')
     var returnObject: any = {};
     for(var keyValuePair of parameterArray) {
      var keyValuePairArray = keyValuePair.split('=');
      if(keyValuePairArray.length != 2) {
        throw new Error("Unexpected URL query format");
      }
      var key = keyValuePairArray[0];
      var value = keyValuePairArray[1];
      if(returnObject[key]) {
        if(returnObject[key] instanceof Array) {
          returnObject[key].push(value);
        } else {
          returnObject[key] = [returnObject[key], value];
        }
      } else {
        returnObject[key]=value;
      }
     }
     if(clearQueryAfterReading)
      window.history.replaceState(undefined, "", window.location.pathname);
     return returnObject as T;
  }

  public static BuildQueryString<T>(queryObject: T) : string {
    let queryString = "";
    for(var key in queryObject) {
      if(queryObject[key] instanceof Array) {
        for(var value in queryObject[key]) {
          queryString += `${key}=${value}&`;
        }
      } else {
        queryString += `${key}=${queryObject[key]}&` 
      }
    }
    if(queryString.length == 0) {
      return "";
    }
    return "?"+ encodeURIComponent(queryString.substr(0, queryString.length - 1));
 }
}