import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { PersonListingQuery } from "../model/person"
import { QueryParser } from "../shared/queryParser";
import { currentUser } from "../shared/currentUser"

interface Person {
  PersonID?: string;
  FirstName: string;
  LastName: string;
  RegistrationNumber?: string;
  Phone: string;
  Email: string;
  City: string;
}

export class PersonListingController extends JQueryController {
  public createdPerson!: string;

  @JQueryController.defineDataTableColumn("FirstName")
  @JQueryController.defineDataTableColumn("LastName")
  @JQueryController.defineDataTableColumn("RegistrationNumber")
  @JQueryController.defineDataTableColumn("Phone")
  @JQueryController.defineDataTableColumn("Email")
  @JQueryController.defineDataTableColumn("City")
  @JQueryController.defineDataTableColumnFromTemplate("PersonID", "#action-column-template")
  @JQueryController.bindDataTable("#persons-table", {
    responsive: {
      details: false
    },
    language: {
      search: "Search"
    },
    columnDefs: [
      { responsivePriority: 1, targets: 0 },
      { responsivePriority: 2, targets: 1 },
      { responsivePriority: 3, targets: 2 },
      { responsivePriority: 4, targets: 3 },
      { responsivePriority: 5, targets: 4 },
      { responsivePriority: 1, targets: -1 }
    ]
  })
  public persons: Person[] = [];

  public constructor(rootElement: JQuery) {
    super(rootElement);
    
    this._populateTable();
  }

  public pageLoaded() : void {
    var query = QueryParser.getQuery<PersonListingQuery>(true);
    if(query && query.createdPerson) {
      this.createdPerson = query.createdPerson;
      JQueryMessageController.showMessage("created-person-message");
    }
  }

  private async _populateTable() {
    if (currentUser && (currentUser.canManageAccounts || currentUser.canManageAgreements || currentUser.canManageBilling || currentUser.canManageDrives || currentUser.canManageReports))
      this.persons = await this.executeServiceCall<Person[]>("persons/getPersons");
  }
}