﻿/**************************************
 * In this file we store extra validators that we wish to use in parsley validation.
 * By putting these in a shared file we can keep all of our rules consolidated and
 * reduce the risk of us putting in duplicate validators / keep our verbage consistent.
 *************************************/

//TODO: Find how to add Parsley to the Window definition, so we don't have to cast window as any.

//has uppercase
(window as any).Parsley.addValidator('uppercase', {
  requirementType: 'number',
  validateString: function (value: any, requirement: any) {
    var uppercases = value.match(/[A-Z]/g) || [];
    return uppercases.length >= requirement;
  },
  messages: {
    en: 'Your password must contain at least (%s) uppercase letter.'
  }
});

//has lowercase
(window as any).Parsley.addValidator('lowercase', {
  requirementType: 'number',
  validateString: function (value: any, requirement: any) {
    var lowecases = value.match(/[a-z]/g) || [];
    return lowecases.length >= requirement;
  },
  messages: {
    en: 'Your password must contain at least (%s) lowercase letter.'
  }
});

//has number
(window as any).Parsley.addValidator('number', {
  requirementType: 'number',
  validateString: function (value: any, requirement: any) {
    var numbers = value.match(/[0-9]/g) || [];
    return numbers.length >= requirement;
  },
  messages: {
    en: 'Your password must contain at least (%s) number.'
  }
});

//has special char
(window as any).Parsley.addValidator('special', {
  requirementType: 'number',
  validateString: function (value: any, requirement: any) {
    var specials = value.match(/[^a-zA-Z0-9]/g) || [];
    return specials.length >= requirement;
  },
  messages: {
      en: 'Your password must contain at least (%s) special characters (e.g. #$%).'
  }
});

// gt, gte, lt, lte, notequalto extra validators
var parseRequirement = function (requirement: any) {
  if (isNaN(+requirement))
    return parseFloat(jQuery(requirement).val() as string);
  else
    return +requirement;
};

// Greater than validator
(window as any).Parsley.addValidator('gt', {
  validateString: function (value: any, requirement: any) {
    return parseFloat(value) > parseRequirement(requirement);
  },
  priority: 32
});

// Greater than or equal to validator for date strings
(window as any).Parsley.addValidator('gtedate', {
  validateString: function (value: any, requirement: any) {
    var requirementVal = jQuery(requirement).val() as string;

    if (!value || !requirementVal)
      return true;

    return new Date(value) >= new Date(requirementVal);
  },
  messages: {
    en: 'Your date range appears to be invalid.'
  },
  priority: 32
});

// Less than or equal to today
(window as any).Parsley.addValidator('beforeToday', {
  validateString: function (value: any, requirement: any) {

    if (!value)
      return true;

    return new Date(value) <= new Date();
  },
  messages: {
    en: 'This date needs to be before today.'
  },
  priority: 32
});

// Date greater than or equal to today
(window as any).Parsley.addValidator('afterToday', {
  validateString: function (value: any, requirement: any) {

    if (!value)
      return true;

    return new Date(value) >= new Date();
  },
  messages: {
    en: 'This date needs to be after today.'
  },
  priority: 32
});

// Lesser than or equal to validator for date strings
(window as any).Parsley.addValidator('ltedate', {
  validateString: function (value: any, requirement: any) {
    var requirementVal = jQuery(requirement).val() as string;

    if (!value || !requirementVal)
      return true;

    return new Date(value) <= new Date(requirementVal);
  },
  messages: {
    en: 'Your date range appears to be invalid.'
  },
  priority: 32
});

// Less than validator
(window as any).Parsley.addValidator('lt', {
  validateString: function (value: any, requirement: any) {
    return parseFloat(value) < parseRequirement(requirement);
  },
  priority: 32
});

// Less than or equal to validator
(window as any).Parsley.addValidator('lte', {
  validateString: function (value: any, requirement: any) {
    return parseFloat(value) <= parseRequirement(requirement);
  },
  priority: 32
});

/**
 * When using this set the value of the validator to the selector of the other fieled that is required
 * add two data properties.
 * 
 * data-parsley-error-message this is the error message that will appear
 * data-parsley-shared-id this is to be a unique identifier that identifies the pair
 * 
 * we use the data-parsley-shared-id to prevent recursion when validating
 *  */
var preventRecursion = {} as any;
(window as any).Parsley.addValidator('requiredIfNotNull', {
  messages: { en: 'One of these two values must not be empty.' },
  validateString: function (value: any, requirement: any, instance: any) {
    var data = instance.$element.data() as any;
    var $partner = $(requirement); 
    var isValid = false;

    if ($partner.val() || value)
      isValid = true;

    if (preventRecursion[data.sharedId]) {
      preventRecursion[data.sharedId] = false;
    } else {
      preventRecursion[data.sharedId] = true;
      $partner.parsley().validate();
    }
    if(!isValid) {
      (window as any).ParsleyUI.updateError(instance, 'requiredIfNotNull' , data.parsleyErrorMessage);
    }
    return isValid;
  }
});

(window as any).Parsley.addValidator('bothRequiredIfNotNull', {
  messages: { en: 'Either both or neither of these fields must be filled in.' },
  validateString: function (value: any, requirement: any, instance: any) {
    var data = instance.$element.data() as any;
    var $partner = $(requirement);
    var isValid = false;

    if ($partner.val() && value || (!$partner.val() && !value))
      isValid = true;

    if (preventRecursion[data.sharedId]) {
      preventRecursion[data.sharedId] = false;
    } else {
      preventRecursion[data.sharedId] = true;
      $partner.parsley().validate();
    }
    if (!isValid) {
      (window as any).ParsleyUI.updateError(instance, 'bothRequiredIfNotNull', data.parsleyBothRequiredIfNotNullMessage || data.parsleyErrorMessage);
    }
    return isValid;
  }
});

// Use this to prevent more than one checkbox in a pair from being true at one time
// pass the selector for the other checkbox as the value of the validator 
// ie data-parsley-not-both-on="#other-control-id"
// identify grouping and prevent recursion using 
// data-shared-id="groupid"
// must have the property data-parsley-validate-if-empty so validation fires when the switches are turned off
var checkBoxRecursionCheck = {} as any;
(window as any).Parsley.addValidator('notBothOn', {
  requirementType: 'string',
  validateMultiple: function (value: any, requirement: any, element: any) {
    var partner= $(requirement);
    var partnerValue = partner.prop("checked");
    var currentValue = element.$element.prop("checked");

    var data = element.$element.data() as any;
    if (checkBoxRecursionCheck[data.sharedId]) {
      checkBoxRecursionCheck[data.sharedId] = false;
    } else {
      checkBoxRecursionCheck[data.sharedId] = true;
      partner.parsley().validate();
    }

    return !(partnerValue && currentValue);
  }  
});

(window as any).Parsley.addValidator('requiredIfPartnerHasValue', {
  validateString: function (value: any, requirement: any, instance: any) {
    var data = instance.$element.data() as any;
    var $partner = $(requirement);
    var isValid = false;

    if (($partner.val() && value) || !$partner.val())
      isValid = true;

    return isValid;
  }
});

// Validate Phone Number
(window as any).Parsley.addValidator('phoneNumber', {
  validateString: function (value: string) {
    let numberLength = value.replace(/\D/g, '').length;
    return numberLength == 11 || numberLength == 10;
  },
  messages: {
    en: 'Phone Numbers must be 10 or 11 digits.'
  },
  priority: 32
});

