import { JQueryPropertyBinding } from "../jqueryBinding";

export class ImageUrlBinding extends JQueryPropertyBinding<string> {
  public fileNotFoundUrl?: string;
  public lastIgnoreCacheTag?: string;
  private static _lastDateTimeMillis: number = 0;

  public getBoundValue(boundElements: JQuery): string {
    let returnValue = boundElements.attr("src")!;
    if (this.lastIgnoreCacheTag && returnValue.endsWith(this.lastIgnoreCacheTag)) {
      returnValue = returnValue.substr(0, returnValue.length - this.lastIgnoreCacheTag.length);
    }
    return returnValue;;
  }
  public setBoundValue(boundElements: JQuery, value: string): void {
    // We only create a new timestamp every 5 seconds, it's unlikely that we'll need to accommodate
    // more rapid changes in any real user request
    let candidateNewDateTimeMillis = new Date().getTime();
    if (candidateNewDateTimeMillis - ImageUrlBinding._lastDateTimeMillis > 5000) {
      console.log("resetting " + ImageUrlBinding._lastDateTimeMillis + " to " + candidateNewDateTimeMillis)
      ImageUrlBinding._lastDateTimeMillis = candidateNewDateTimeMillis;
    }
    else console.log("recycling " + ImageUrlBinding._lastDateTimeMillis)
    if (value.indexOf("?") !== -1) {
      value += `&_ignoreCache=${ImageUrlBinding._lastDateTimeMillis}`;
    }
    else {
      value += `?_ignoreCache=${ImageUrlBinding._lastDateTimeMillis}`;
    }
    boundElements.attr("src", value);
    boundElements.trigger('jquery-change'); // TODO: Deprecate jquery-change and implement real change events (this will require discussion)
  }

  public initialize(): void {
    super.initialize();
    this._onerror = this._onerrorImpl.bind(this);
    this.getBoundElements().on("error", this._onerror);
  }

  public destroy(): void {
    super.destroy();
    this.getBoundElements().off<"error">("error", this._onerror);
  }

  private _onerrorImpl(e: JQuery.Event): void {
    this.getBoundElements().attr("src", this.fileNotFoundUrl || this.initialValue!);
  }

  private _onerror!: (e: JQuery.Event) => void;
}