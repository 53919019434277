import { JQueryPropertyBinding } from "../jqueryBinding";

export class VisibilityBinding extends JQueryPropertyBinding<boolean> {
  public disableControlOnHidden?: boolean;

  public getBoundValue(boundElements: JQuery): boolean {
    return boundElements.attr("hidden") === "hidden" ? false : true;
  }
  public setBoundValue(boundElements: JQuery, value: boolean): void {
    // Disable the control if hidden && disableControlOnHidden is set to true
    if (this.disableControlOnHidden)
      !value ? boundElements.attr('disabled', 'disabled') : boundElements.removeAttr('disabled');
    // Hide/Show based on value
    value ? boundElements.removeAttr('hidden') : boundElements.attr('hidden', 'hidden');
  }
}

export class ClassPresentBinding extends JQueryPropertyBinding<boolean> {
  public className!: string;

  public getBoundValue(boundElements: JQuery): boolean {
    return boundElements.hasClass(this.className);
  }
  public setBoundValue(boundElements: JQuery, value: boolean): void {
    if (boundElements.hasClass(this.className)) {
      if (!value) boundElements.removeClass(this.className);
    }
    else {
      if (value) boundElements.addClass(this.className);
    }
  }
}