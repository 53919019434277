import * as jQuery from "jquery";
import PerfectScrollbar from "perfect-scrollbar";
import * as bootstrap from "bootstrap";
import * as Popper from "popper.js";
import * as JSZip from "jszip";

import global from "../globals";

// TODO: perfect-scrollbar 0.6.16 is what is in the template by default, need to verify that 1.4.0 works too
// TODO: decide if we even need perfect-scrollbar; it looks like it's only used for notifications and popups that we aren't touching now
// All of these things need to be presented in the global namespace as well as in properly included
// files, that's why they are here (other dependencies that present themselves or that don't need
// a global presence are left in bootstrap/index.ts)
global.jQuery = global.$ = jQuery;
global.PerfectScrollbar = global.Ps = PerfectScrollbar;
global.bootstrap = bootstrap;
global.Popper = Popper;
global.JSZip = JSZip;