import { JQueryPropertyBinding } from "../jqueryBinding";

export class ParsleyFormValidityBinding extends JQueryPropertyBinding<boolean> {
  
  public get suppressInitialValueRestoration(): boolean { return true; }
  
  public getBoundValue(boundElements: JQuery): boolean {
    return boundElements.parsley().validate();
  }
  public setBoundValue(boundElements: JQuery, value: boolean): void {
    throw new Error("Cannot set form validity")
  }

}