import { JQueryPropertyBinding, BindableTarget } from "../jqueryBinding";

// TODO: This is a circular reference! We shouldn't do that! This class needs access to instantiating new controllers
import { JQueryController } from "../jqueryController";

var _nextBoundItemID = 1;

export class RepeatingControllerBinding extends JQueryPropertyBinding<any[]> {
  private controllerArgs?: any[] | ((controller: JQueryController, item: any) => any[]);
  private refreshAllControllers?: boolean;

  private _getRealRefreshAllControllers(): boolean {
    // Unlike normal, the default for this flag is true so we have some math to do
    return !(this.refreshAllControllers === false);
  }

  private _originalDomNode!: HTMLElement;
  private _instantiatedControllerDom: HTMLElement[] = [];
  private _instantiatedControllerDomIDs: string[] = [];
  private _instantiatedControllers: BindableTarget[] = [];
  private _lastSetData: any[] = [];
  private _lastDisplayedData: any[] = [];

  public getBoundValue(boundElements: JQuery): any[] {
    return this._lastSetData;
  }
  public setBoundValue(boundElements: JQuery, value: any[]): void {
    // Most controllers don't handle child property updates well at this point due to how we
    // wrote the first version of this function; we allow the binder to opt in to smart updates
    // for child components
    if (this._getRealRefreshAllControllers()) {
      this.resetBoundValue(value);
    }
    else {
      this.smartUpdateBoundValue(value);
    }
  }
  
  public smartUpdateBoundValue(value: any[]): void {
    let lastDisplayedData = this._lastDisplayedData;
    let lastInstantiatedControllerDom = this._instantiatedControllerDom;
    let lastInstantiatedControllerDomIDs = this._instantiatedControllerDomIDs;
    let lastInstantiatedControllers = this._instantiatedControllers;
    this._lastSetData = value;
    this._lastDisplayedData = value.slice();
    this._instantiatedControllerDom = [];
    this._instantiatedControllerDomIDs = [];
    this._instantiatedControllers = [];

    // For each element in the list, check to see if we have it already; if we do, we're recycling
    let existingDomElementsByNewIndex: (HTMLElement | undefined)[] = [];
    let existingDomIDsByNewIndex: (string | undefined)[] = [];
    let existingControllersByNewIndex: (BindableTarget | undefined)[] = [];
    let inUseExistingDomElements: boolean[] = [];
    for (let i = 0; i < value.length; i++) {
      let lastIndex = lastDisplayedData.indexOf(value[i]);
      if (lastIndex != -1) {
        existingDomElementsByNewIndex[i] = lastInstantiatedControllerDom[lastIndex];
        existingDomIDsByNewIndex[i] = lastInstantiatedControllerDomIDs[lastIndex];
        existingControllersByNewIndex[i] = lastInstantiatedControllers[lastIndex];
        inUseExistingDomElements[lastIndex] = true;
      }
    }

    // Go through each element, adding new items and updating existing items as we see fit
    let beforeDomNode = this._originalDomNode;
    for (let i = 0; i < value.length; i++) {
      // Find or create our DOM nodes; we don't initialize controllers until they are on-screen
      // so we save the need for construction
      let newDomNode: HTMLElement;
      let needsConstruction: boolean;
      let domID: string;
      if (existingDomElementsByNewIndex[i]) {
        newDomNode = existingDomElementsByNewIndex[i] as HTMLElement;
        domID = existingDomIDsByNewIndex[i] as string;
        needsConstruction = false;
      }
      else {
        newDomNode = this._originalDomNode.cloneNode(true) as HTMLElement;
        domID = "JQR" + (_nextBoundItemID++).toString();
        newDomNode.id = domID;
        this.uniqueifyCheckboxIDs(newDomNode);
        needsConstruction = true;
      }

      // Add the DOM node to the screen and cache
      this._instantiatedControllerDom.push(newDomNode);
      this._instantiatedControllerDomIDs.push(domID);
      beforeDomNode.parentNode!.insertBefore(newDomNode, beforeDomNode.nextSibling);
      beforeDomNode = newDomNode;

      // Create or add our controller as needed
      let controller: BindableTarget;
      if (needsConstruction) {
        let controllerArgs: any[];
        let item = value[i];
        if (this.controllerArgs) {
          if (typeof this.controllerArgs === "function") {
            controllerArgs = this.controllerArgs(this.target as any as JQueryController, item);
          }
          else {
            controllerArgs = [...this.controllerArgs, this.target, item];
          }
        }
        else {
          controllerArgs = [this.target, item];
        }
        controller = JQueryController.initializeController("#" + domID, controllerArgs).controller as any as BindableTarget;
      }
      else {
        controller = existingControllersByNewIndex[i] as BindableTarget;
      }
      this._instantiatedControllers.push(controller);
    }

    // Finally, purge any unused controllers
    for (let i = 0; i < lastInstantiatedControllers.length; i++) {
      if (!inUseExistingDomElements[i]) {
        lastInstantiatedControllers[i].destroy();
        lastInstantiatedControllerDom[i].remove();
      }
    }
  }

  private uniqueifyCheckboxIDs(newElement: HTMLElement) {
    // This will messup any binding using id on a checkbox
    // however the repeater by its self breaks checkboxes
    // if you have a checkbox inside a repeater use name to read value
    // when a label is for a checkbox we change both so they use the same
    // unique ID
    var labelsFor = newElement.querySelectorAll("label[for]");
    for (let i = 0; i < labelsFor.length; i++ ) {
      let label = labelsFor[i] as HTMLLabelElement;
      //being as specific as possible to avoid breaking other repeated content
      var input = newElement.querySelector(`input#${label.htmlFor}[type=checkbox]`)
      if(input == null) {
        console.warn('label "for" attribute is not fully supported in repeater')  
        continue;
      }
      //here newElement.id is the jqueryController count which is unique for each repeated element
      label.htmlFor = label.htmlFor + newElement.id;
      input.id = input.id + newElement.id;
    }
  }

  public resetBoundValue(value: any[]): void {
    // This version just re-creates all child nodes from scratch each time; for each element in
    // the list, create a new controller based on the expected type, after deleting the existing
    // ones
    this._lastSetData = value;
    this._deleteAllNodes();
    let beforeDomNode = this._originalDomNode;
    for (let item of value) {
      // TODO: This doesn't replicate event handlers - should it?
      let newDomNode = this._originalDomNode.cloneNode(true) as HTMLElement;
      beforeDomNode.parentNode!.insertBefore(newDomNode, beforeDomNode.nextSibling);
      let domID = "JQR" + (_nextBoundItemID++).toString();
      newDomNode.id = domID;
      this.uniqueifyCheckboxIDs(newDomNode);
      this._instantiatedControllerDom.push(newDomNode);
      this._instantiatedControllerDomIDs.push(domID);
      var controllerArgs: any[];
      if (this.controllerArgs) {
        if (typeof this.controllerArgs === "function") {
          controllerArgs = this.controllerArgs(this.target as any as JQueryController, item);
        }
        else {
          controllerArgs = [...this.controllerArgs, this.target, item];
        }
      }
      else {
        controllerArgs = [this.target, item];
      }
      let controller = JQueryController.initializeController("#" + domID, controllerArgs).controller;
      // TODO: Still need to figure out a more neutral way of talking bidirectionally to controllers
      this._instantiatedControllers.push(controller as any as BindableTarget);
      beforeDomNode = newDomNode;
    }
  }

  private _deleteAllNodes(): void {
    for (let instantiatedController of this._instantiatedControllers) {
      instantiatedController.destroy();
    }
    for (let instantiatedControllerDom of this._instantiatedControllerDom) {
      instantiatedControllerDom.remove();
    }
    this._instantiatedControllerDom = [];
    this._instantiatedControllerDomIDs = [];
    this._instantiatedControllers = [];
  }

  public initialize(): void {
    super.initialize();

    // We only support a single bound element result
    let boundElements = this.getBoundElements();
    switch (boundElements.length) {
      case 1: break;
      case 0: throw new Error("Repeater bindings don't work for empty jQuery results");
      default: throw new Error("Repeater bindings don't work for multiple elements on the same binding");
    }

    // Capture the original DOM node, which will be hidden by CSS because it has not attached
    // controller
    this._originalDomNode = boundElements[0];
  }

  public destroy(): void {
    super.destroy();
    this._deleteAllNodes();
  }

  public disconnect(): any {
    debugger; // TODO: Implement
  }

  public reconnect(state: any): void {
    debugger; // TODO: Implement
  }
}