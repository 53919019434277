export class NoZoneDate {
  private innerTime: Date;

  constructor(date?: string | Date | undefined) {
    if (typeof date === "string")
      this.innerTime = new Date(date);
    else if (date instanceof Date)
      this.innerTime = date;
    else
      this.innerTime = new Date();


  }

  getClientDate(): Date {

    let returnDate = new Date(this.innerTime);
    // Add the timezone offset to returnDate
    returnDate.setMinutes(this.innerTime.getMinutes() + this.innerTime.getTimezoneOffset())

    return returnDate;
  }

  getClientDateString(): string {
    return this.getClientDate().toLocaleDateString()
  }

  getServerDateString(): string {
    return this.getServerDate().toLocaleDateString()
  }

  getClientLongDateTimeString(): string {
    return NoZoneDate.getLongDateTimeString(this.getClientDate());
  }

  getServerLongDateTimeString(): string {
    return NoZoneDate.getLongDateTimeString(this.getServerDate());
  }

  static getLongDateTimeString(date: Date): string {
    let hours = date.getHours();
    let ampm: string;
    if (hours === 0) {
      hours = 12;
      ampm = "AM";
    }
    else if (hours === 12) {
      ampm = "PM";
    }
    else if (hours > 12) {
      hours -= 12;
      ampm = "PM";
    }
    else {
      ampm = "AM";
    }
    let minuteString = "00" + date.getMinutes();
    minuteString = minuteString.substr(minuteString.length - 2, 2);
    return this.getLongDateString(date) + ` ${hours}:${minuteString} ${ampm}`;
  }

  getClientLongDateString(): string {
    return NoZoneDate.getLongDateString(this.getClientDate());
  }

  getServerLongDateString(): string {
    return NoZoneDate.getLongDateString(this.getServerDate());
  }

  static getLongDateString(date: Date): string {
    var daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${daysOfWeek[date.getDay()]}, ${monthsOfYear[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  getServerDate(): Date {
    return this.innerTime;
  }

  // HACK: We should reconsider how we are converting dates - why do we use the representation that we do?

  getRealTime(): Date {
    let returnValue = new Date(this.innerTime);
    returnValue.setMinutes(-returnValue.getTimezoneOffset());
    return returnValue;
  }

  //milliseconds * seconds * minutes * hours
  private one_day = 1000*60*60*24
  daysDiff(comparisonDate: NoZoneDate): number {
    let day1 = this.innerTime.getTime();
    let day2 = comparisonDate.getServerDate().getTime();

    return Math.round((day1-day2)/this.one_day);
  }
}