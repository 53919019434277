import { JQueryController } from "./jqueryController";
import { registerMessageController } from "./bindings/userMessaging";

export class JQueryMessageController extends JQueryController {
  private static _activeControllersByID: { [htmlID: string]: JQueryMessageController } = {};

  public constructor(rootElement: JQuery) {
    super(rootElement);

    this.reverseVisibility("[role=alert]");
    JQueryMessageController._activeControllersByID[rootElement.attr("id")!] = this;
    this.addDestroyCallback(() => {
      delete JQueryMessageController._activeControllersByID[rootElement.attr("id")!];
    });
  }

  public showMessage(): void {
    this.restoreVisibility("[role=alert]");
  }

  @JQueryController.bindClick("button")
  public hideMessage(): void {
    this.reverseVisibility("[role=alert]");
  }

  // TODO: Change this class to use a selector instead of message ID
  public static showMessage(messageID: string): void {
    for (let key in JQueryMessageController._activeControllersByID) {
      let controller = JQueryMessageController._activeControllersByID[key];
      if (key === messageID) {
        controller.showMessage();
      }
      else {
        controller.hideMessage();
      }
    }
  }

  public static hideMessage(messageID: string): void {
    JQueryMessageController._activeControllersByID[messageID].hideMessage();
  }

  public static hideAllMessages(): void {
    // Just use showMessage with a condition that will never be true for the message ID
    JQueryMessageController.showMessage(undefined as any);
  }
}

// We need to register like this to avoid circular references
registerMessageController(JQueryMessageController);