import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { getPasswordHash } from "../model/authentication";
import { setCookie } from "@legacyx/web-uitoolkit/cookies";

interface LoginChallenge {
  InternalUserLoginID: string;
  PublicSalt: string;
}

export class LoginController extends JQueryController {
  @JQueryController.bindInputValue("#username") public username!: string;
  @JQueryController.bindInputValue("#password") public password!: string;
  //@JQueryController.bindVisibility()

  @JQueryController.bindClick("#login-submit-button")
  @JQueryController.bindValidation("#login-form")
  public async login(): Promise<void> {
    var challenge = await this.executeServiceCall<LoginChallenge>('login/loginChallenge', {
      userName: this.username
    });

    await this.executeServiceCall<string>('login/loginResponse', {
      internalUserLoginID: challenge.InternalUserLoginID,
      publicHash: getPasswordHash(this.password, challenge.PublicSalt)
    });
    window.location.href = 'notes.html';
  }
}