import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { NoteSingleQuery, NoteAttachment, CommentWithModifiedDate } from "../model/note"
import { currentUser } from "../shared/currentUser"
import { NoZoneDate } from "@legacyx/web-uitoolkit/noZoneDate";
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { Company } from "model/company";
import { PaginatedResults } from "model/pagination";
import { DropzoneController } from "../../web-uitoolkit/src/bindings/dropzone-v2";
import { DateTime } from "../shared/datetime";
import { SelectOption } from "model/select";

interface NewNote {

  noteID?: string;
  
  // General
  noteDate?: string;
  date?: Date;
  time?: string;
  driveID?: string,
  comments: string | any;
  // Person
  personID?: string;
  firstName?: string;
  lastName?: string;
  registrationNumber?: string;
  currentEmployee: boolean;
  position?: string;
  local?: string;
  friendly: boolean;
  phoneNumber?: string;
  email?: string;
  branchLocation?: string;
  company?: string;

  card?: Card

  privateUserIDs?: string[]
}

interface ResponseNote extends NewNote {
  noteDate: string;
  createdBy: string;
  noteID: string;
  equipmentID: string;
  companyID: string;
  createByUserAccountID: string;
  attachments: NoteAttachment[];
  sharedUsers: PrivateUser[];
  commentHistory: CommentWithModifiedDate[];
  canEditComment: boolean;
}

interface Card {
  cardID?: string;
  effectiveDate: Date;
  expiryDate: Date;
}

interface PrivateUser {
  UserAccountID: string;
  FirstName: string;
  LastName: string;
}


interface PersonLookup {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  RegistrationNumber: string;
  Source: string;
}

interface PersonResponse {
  PersonList: PersonLookup[];
  ShowOeSystemWarning: boolean;
}


interface Person extends PersonLookup {
  CurrentEmployee: boolean;
  Email: string;
  Friendly: boolean;
  PersonID: string; 
  PhoneNumber: string;
  Position: string;
  UnionLocal: string;
}

interface Drive {
  DriveID: string;
  Name: string;
  StartDate: string;
  EndDate?: string;
  NumberOfCompanies: boolean;
}


export class NoteSingleController extends JQueryController {
  private _privateNoteSelect!: JQuery<HTMLElement>;
  private noteID!: string;
  private originalComment!: string;
  
  //View
  @JQueryController.bindVisibility('#new-note-header') public newNoteHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#update-note-header') public updateNoteHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#new-note-breadcrumb') public newNoteBreadcrumbVisibility!: boolean;
  @JQueryController.bindVisibility('#update-note-breadcrumb') public updateNoteBreadcrumbVisibility!: boolean;
 
  @JQueryController.bindDisplayText('#view-title') public title!: string;
  // General
  @JQueryController.bindDatePickerValue('#note-date', "NoZoneDate") public noteDate?: NoZoneDate | Date;
  @JQueryController.bindInputValue('#note-time') public noteTime?: string;
  @JQueryController.bindInputValue('#note-comments') public noteComments?: string;
  @JQueryController.bindEnabled('#note-comments') public isCommentsEnabled?: boolean;
  @JQueryController.bindDisplayText('#comments-label') public noteCommentsLabelText = "Comments";
  // Person

  

  @JQueryController.listSearchQuery<PersonLookup>('#person-lookup', (value: PersonLookup) => {
    let results = (["FirstName", "LastName", "PhoneNumber", "Email", "RegistrationNumber"] as (keyof PersonLookup)[])
    .reduce< string | null >((returnValue, key) => {
      if(value[key]) {
        if(returnValue) {
          return returnValue + " - "+ value[key];
        } else {
          return value[key];
        }
      }
      return returnValue;
    }, null) || "";
    return results;
  }, undefined, undefined, 600)
  public async getTheSelect2Elements(searchTerm: string): Promise<PersonLookup[]> {
    let wantsOeSystemUsers = this.isSearchingForOeSystemUsers;
    if (searchTerm && searchTerm.length >= 3){
      
      let results = await executeServiceCall<PersonResponse>(`notes/getDetailedPeopleForSelect?term=${searchTerm}&wantsOeSystemUsers=${wantsOeSystemUsers}`);
      if (results.ShowOeSystemWarning) {
        JQueryMessageController.showMessage("oe-system-warning");
      } else {
        JQueryMessageController.hideMessage("oe-system-warning");
      }
      return results.PersonList;
    }
    return [];
  }
  
  @JQueryController.bindListValue('#person-lookup')
  public person?: Person;

  @JQueryController.bindInputChange('#person-lookup')
  public function() {
    if (this.person) {
      this.firstName = this.person.FirstName;
      this.lastName = this.person.LastName;
      this.registrationNumber = this.person.RegistrationNumber;
      this.phone = this.person.PhoneNumber;
      this.email = this.person.Email;
      this.position = this.person.Position;
      this.currentEmployee = this.person.CurrentEmployee;
      this.local = this.person.UnionLocal;
      this.friendly = this.person.Friendly;
      this.arePersonFieldsEnabled = false;
      if (this.person.PersonID == '00000000-0000-0000-0000-000000000000'){
        this.areOeSystemFieldsEnabled = true;
      } else {
        this.areOeSystemFieldsEnabled = false;
      }
    } else {
      this.arePersonFieldsEnabled = true;
    }
  }

  @JQueryController.bindEnabled('#first-name, #last-name, #registration-number, #phone, #email, #position, #current-employee, #local, #friendly')
  private arePersonFieldsEnabled: boolean = true;

  @JQueryController.bindEnabled('#local,#current-employee,#friendly, #position')
  private areOeSystemFieldsEnabled: boolean = true;
  
  @JQueryController.bindInputValue('#first-name') public firstName?: string;
  @JQueryController.bindInputValue('#registration-number') public registrationNumber?: string;
  @JQueryController.bindInputValue('#last-name') public lastName?: string;
  @JQueryController.bindInputValue('#phone') public phone?: string;
  @JQueryController.bindInputValue('#email') public email?: string;

  @JQueryController.bindSelectOptions("#company") public companyOptions!: SelectOption[]
  @JQueryController.bindInputValue('#company') public company?: string;
  @JQueryController.bindSelectOptions("#drives") public driveOptions!: SelectOption[]
  @JQueryController.bindInputValue('#drives') public drive?: string;
  @JQueryController.bindInputValue('#branch-location') branchLocation ?: string;
  @JQueryController.bindInputValue('#position') public position?: string;
  @JQueryController.bindInputChecked('#current-employee') public currentEmployee!: boolean;
  @JQueryController.bindInputValue('#local') public local?: string;
  @JQueryController.bindInputChecked('#friendly') public friendly!: boolean;

  @JQueryController.bindEnabled('.disable-on-edit') public disableOnEditControls!: boolean;
  @JQueryController.bindVisibility('.hide-on-edit') public hideOnEditControls!: boolean;
  @JQueryController.bindVisibility('.hide-on-new') public hideOnNewControls!: boolean;
  @JQueryController.bindVisibility('.hide-if-not-owner') public visibleIfOwner = false;
  @JQueryController.bindRepeatingController("#note-attachments") public attachments!: NoteAttachment[];
  @JQueryController.bindVisibility(".email-attachments") public existingAttachmentVisiblity!: boolean;
  @JQueryController.bindVisibility("#OeSystemError") public showOeSystemWarning !: boolean;

  @JQueryController.bindRepeatingController("#note-comment") public commentsHistory!: CommentWithModifiedDate[];

  //Private Note
  @JQueryController.bindInputChecked('#private-note-check') public isPrivate!: boolean;
  @JQueryController.bindInputChange('#private-note-check')
  public privateNoteChange() {
    this.setPrivateVisability(this.isPrivate)
    this.disablePrivateControls(this.isPrivate)
    this.clearOutOptions(this.isPrivate)
  }
  @JQueryController.bindAttribute('#main-card', 'class') public cardClass!:string;
  @JQueryController.bindAttribute('label', 'style') public labelStyle!:string;
  @JQueryController.bindAttribute('.custom-control-label', 'style') public checkLabelStyle!:string;
  @JQueryController.bindInputChecked('#oesystem-person-check') public isSearchingForOeSystemUsers!: boolean;
  @JQueryController.bindVisibility('#person-selector') public personSelectorVisibility!: boolean;
  @JQueryController.bindVisibility('#selected-people') public selectedPersonsVisibility!: boolean;
  @JQueryController.bindEnabled('.disable-on-private') public disableOnPrivateNote!: boolean;
  @JQueryController.bindInputValue('#creator') public creatorName!: string;
  @JQueryController.bindDisplayHtml('#selected-persons') public selectedPeople!: string;
  private _userSelect!: JQuery<HTMLElement>;
  private selectedPrivateUsers!: string[];
  private sharedUsers!: PrivateUser[];
  @JQueryController.bindSelectOptions("#province") public provinceOptions!: SelectOption[]
  
  private populateCreator(){
    if(currentUser){
      this.creatorName = currentUser.name || 'You';
    }
  }

  private setPrivateVisability(isPrivate: boolean){
    this.personSelectorVisibility = isPrivate;
    if (isPrivate){
      this.cardClass = "card text-white bg-secondary card-border-color card-border-color-primary";
      this.labelStyle= "color:white";
      this.checkLabelStyle = "color:white";
    }
    else {
      this.cardClass = "card card-border-color card-border-color-primary";
      this.labelStyle = "color:#666666";
      this.checkLabelStyle = "color:#666666";
    }
  }

  private disablePrivateControls(isPrivate: boolean){
    this.disableOnPrivateNote = !isPrivate;
  }

  private clearOutOptions(isPrivate: boolean){
    if (!isPrivate){
      this._userSelect.val('null').trigger("change");
      this.selectedPrivateUsers = [];      
    } else {
      this.cardEffectiveDate = undefined;
      this.cardExpiryDate = undefined;
    }
  }
  
  private setupSelect2(){
    this._userSelect = $('#private-person-lookup').select2({
      multiple:true,
      allowClear: true,
      placeholder: "Enter the name of the person you are looking for",
      ajax: {
        url: '/services/notes/getPrivateNoteUsers',
        delay: 200,
        minimumInputLength: 3,
        processResults: function(response) {
          return {
            results: response.results.map((x: any) => {
              return {id: x.UserAccountID, text:  `${x.FirstName} ${x.LastName}`};
            }),
            pagination : response.pagination
          }
        },
        dataType: 'json'
      }
    })
    this._userSelect.on("select2:select  select2:unselect ", () => {
      this.selectedPrivateUsers = this._userSelect.select2('data').map((x) => {
        return x.id
      })
    })
  }

  // Card
  @JQueryController.bindDatePickerValue('#card-effective-date', "NoZoneDate") public cardEffectiveDate?: NoZoneDate;
  @JQueryController.bindDatePickerValue('#card-expiry-date', "NoZoneDate") public cardExpiryDate?: NoZoneDate;

  @JQueryController.bindDropzoneController("#new-note-dropzone", "/services/notes/addNoteFile", {
    autoProcessQueue: false,
    parallelUploads: 1,
    addRemoveLinks: true
  })
  public dropzone!: DropzoneController;
  private cardValidForMonths?: number | null;
  constructor(rootElement: JQuery<HTMLElement>) {
    super(rootElement);
    var query = QueryParser.getQuery<NoteSingleQuery>(false);   
    if(query){
      this.newNoteHeaderVisibility = false;
      this.updateNoteHeaderVisibility = true; 
      this.newNoteBreadcrumbVisibility = false;
      this.updateNoteBreadcrumbVisibility = true;

    }else{
      this.newNoteHeaderVisibility = true;
      this.updateNoteHeaderVisibility = false;
      this.newNoteBreadcrumbVisibility = true;
      this.updateNoteBreadcrumbVisibility = false;
    }    
    this.populateCompanySelect().then(() => {
      if(query && query.noteID) {
        this.noteID = query.noteID;
        this.title = (query.noteID ? 'Update Note' : 'New Note')
        this.editMode(query.noteID != undefined);
        this.loadDetails(query.noteID)
        //this.setHeader(false);
        //this.setBreadcrumb(false);
      } else {
        var date = new Date();
        this.noteDate = date;
        this.existingAttachmentVisiblity = false;
        this.noteTime = DateTime.get24TimeString(date);
        this.hideOnNewControls = false;
        //this.setHeader(true);
        //this.setBreadcrumb(true);
      }
    }).then(() => {
     executeServiceCall<boolean>('notes/checkForOesystemSettings').then(result => {
       this.isSearchingForOeSystemUsers = result;
     })
     executeServiceCall<number | null>('getSettings/cardExpiry').then(result => {
      this.cardValidForMonths = result;
     });
    });
    
    //This needs to be serialized so that the combo box can default to the current drive
    this.populateDriveSelect().then(() => {
      this.populateDrive();
    });
    this.selectedPrivateUsers = [];
    this.populateCreator();
    this.setupSelect2();

    
  }

  private setBreadcrumb(isNew: boolean) {
    this.newNoteBreadcrumbVisibility = isNew;
    this.updateNoteBreadcrumbVisibility = !isNew;
  }

  private setHeader(isNew: boolean) {
    this.newNoteHeaderVisibility = isNew;
    this.updateNoteHeaderVisibility = !isNew;
  }

  public privateMode(users:PrivateUser[]){
    if(users.length){
      this.selectedPersonsVisibility = true;
      this.isPrivate = true;
      this.selectedPeople = this.getUsersFromArray(users);
      this.cardClass = "card text-white bg-secondary card-border-color card-border-color-primary"
      this.labelStyle = "color:white"
      this.checkLabelStyle = "color:white";
    }
  }
  private getUsersFromArray(users:PrivateUser[]){
    let displayString = "Shared with: "
    for (let i=0;i<users.length;i++){
      if (i==users.length-1){
        displayString += `${users[i].FirstName} ${users[i].LastName}.`
      } else {
        displayString += `${users[i].FirstName} ${users[i].LastName}, `
      }
    }
    return displayString
  }
  @JQueryController.bindInputChange('#card-effective-date')
  public cardEffectiveDateChanged() {
    if(this.cardValidForMonths && this.cardEffectiveDate) {
      var effectiveDate = this.cardEffectiveDate.getClientDate();
      var expiryDate = new Date( effectiveDate);
      expiryDate.setMonth(effectiveDate.getMonth() + this.cardValidForMonths);
      this.cardExpiryDate = new NoZoneDate(expiryDate);
    }
  }


  private async loadDetails(noteID: string) {
    var note = await executeServiceCall<ResponseNote>(`notes/getNote?noteID=${noteID}`);
    let noteDate = (new Date(note.noteDate));
    this.noteDate = noteDate;
    this.noteTime = DateTime.get24TimeString(noteDate);
    this.noteComments = note.commentHistory.length > 0 ? note.commentHistory[0].Comment : "";
    this.noteCommentsLabelText = note.commentHistory.length > 1 ? "Comments (edited)" :  "Comments";
    this.originalComment = this.noteComments;
    this.isCommentsEnabled = note.canEditComment;
    this.visibleIfOwner = note.canEditComment;
    this.commentsHistory = note.commentHistory;
    this.drive = note.driveID;
    this.firstName = note.firstName;
    this.registrationNumber = note.registrationNumber;
    this.lastName = note.lastName;
    this.phone = note.phoneNumber;
    this.email = note.email;
    this.company = note.company;
    this.branchLocation = note.branchLocation;
    this.position = note.position;
    this.currentEmployee = note.currentEmployee;
    this.local = note.local;
    this.friendly = note.friendly;
    if(note.attachments.length) {
      this.attachments = note.attachments;
      this.existingAttachmentVisiblity = true;
    } else {
      this.existingAttachmentVisiblity = false;
    }

    //Card Information
    if(note.card) {
      //this probably breaks it
      this.cardEffectiveDate = new NoZoneDate(note.card.effectiveDate);
      this.cardExpiryDate = new NoZoneDate(note.card.expiryDate);
    }
    this.privateMode(note.sharedUsers);
  }

  private editMode(hasNoteID: boolean) {
    this.disableOnEditControls = !hasNoteID;
    this.hideOnEditControls = !hasNoteID;
    this.hideOnNewControls = hasNoteID;
  }


  private _addTime(date: Date, time:string) : Date {
    var timeSplit = time.split(":");
    date.setMinutes(parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]));
    return date;
  }

  public populateDrive(){
    if(currentUser){
      this.drive = currentUser.currentDriveID
    }
  }

  private async populateCompanySelect() {
    this.companyOptions = (await executeServiceCall<PaginatedResults<Company>>('companies/getCompaniesForSelect')).results.map(x => { return { text: x.CompanyName, value: x.CompanyID }})
  }

  private async populateDriveSelect() {
    let allDrives = await executeServiceCall<Drive[]>('drives/getDrives');
    this.driveOptions = allDrives.map(x => {
      return {text: x.Name, value:x.DriveID}
    })
  }

  @JQueryController.bindClick("#add-note-button") 
  @JQueryController.bindValidation("#note-form")
  public async addNewNote() {
    var newNote: NewNote = {
      // General
      date: this._addTime((this.noteDate! as NoZoneDate).getServerDate(), this.noteTime!),
      comments: this.noteComments!,
      driveID: this.drive,
      // Person
      personID: this.person ? this.person.PersonID : undefined,
      firstName: this.firstName,
      registrationNumber: this.registrationNumber,
      lastName: this.lastName,
      currentEmployee: this.currentEmployee,
      position: this.position,
      local: this.local,
      friendly: this.friendly,
      phoneNumber: this.phone,
      email: this.email,
      branchLocation: this.branchLocation,
      company: this.company
    }

    //On Private Notes we don't want a card attached to them
    if(this.company && this.cardEffectiveDate && this.cardExpiryDate && !this.isPrivate)
      newNote.card = {
        effectiveDate: (this.cardEffectiveDate! as NoZoneDate).getServerDate(),
        expiryDate: (this.cardExpiryDate! as NoZoneDate).getServerDate()
      }
    if(this.isPrivate){
      if(currentUser)
        this.selectedPrivateUsers.push(currentUser.userAccountID!);
      newNote.privateUserIDs = this.selectedPrivateUsers;
    } else {
      newNote.privateUserIDs = undefined;
    }

    var newNoteID = await executeServiceCall<PaginatedResults<Company>>('notes/createNote', {note: newNote})

    // After created note add files
    var result = await this.dropzone.sendFiles({noteID: newNoteID});

    if(result.failed.length > 0)
      JQueryMessageController.showMessage("new-note-failed");
    else {
      this.goBack()
    }
  }

  @JQueryController.bindClick("#cancel-new-note-button") 
  public goBack() {
    window.location.href = '/notes.html'
  }

  @JQueryController.bindClick("#add-comment-button")
  public async addComment()
  {
    if (this.noteComments){
      if (this.noteComments == this.originalComment){
        return;
      }
      var result = await executeServiceCall("notes/addCommentToNote", {
        noteID: this.noteID,
        comment: this.noteComments
      });
      let commentObj = {
        Comment: this.noteComments,
        ModifiedTime: new Date(),
      }
      

      //Need to reassign as unshift isn't enough to let the binding figure out it needs to update
      this.commentsHistory.unshift(commentObj);
      this.commentsHistory = this.commentsHistory;
      this.originalComment = this.noteComments;
      JQueryMessageController.showMessage("created-note-comment");
      this.noteCommentsLabelText = "Comments (edited)";
    }    
  }
}

export class AttachmentController extends JQueryController {

  @JQueryController.bindDisplayText(".file-name") public fileName!: string;
  @JQueryController.bindAttribute("a.file-name", "href") public link!: string;

  public constructor(rootElement: JQuery, public parentController: NoteSingleController, public attachment: NoteAttachment) {
    super(rootElement)
    this.fileName = attachment.fileName;
    this.link = `/services/notes/downloadAttachment?noteAttachmentID=${attachment.noteAttachmentID}&noteID=${attachment.noteID}` 
  }
}

export class CommentHistoryController extends JQueryController {
  @JQueryController.bindDisplayText(".comment-date") public noteDate !: string;
  @JQueryController.bindDisplayText(".comment-content") public noteComment !: string;

  public constructor(rootElement: JQuery, public parentController: NoteSingleController, public comment: CommentWithModifiedDate){
    super (rootElement);
    this.noteDate = comment.ModifiedTime.toLocaleDateString() + " at "  + comment.ModifiedTime.toLocaleTimeString();
    this.noteComment = comment.Comment;
  }
}