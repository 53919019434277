import { JQueryController } from "./jqueryController";
import { getPage } from "./navigationContext";

interface Breadcrumb {
  title: string;
  viewSelector: string;
  constructionArgs: any[];
  page?: string;
}

export class JQueryBreadcrumbController extends JQueryController {
  public static current?: JQueryBreadcrumbController;

  public constructor(rootElement: JQuery) {
    super(rootElement);

    // We only expect to have one of these controllers at most on any given screen; register
    // it for future access
    if (JQueryBreadcrumbController.current) {
      throw Error("There is more than one breadcrumb on the screen.");
    }
    JQueryBreadcrumbController.current = this;
    this.addDestroyCallback(() => delete JQueryBreadcrumbController.current);
  }

  private _breadcrumbs: Breadcrumb[] = [];

  public hide(): void {
    this.rootElement.hide();
  }

  public show(): void {
    this.rootElement.show();
  }

  // TODO: Make declarative version of this?
  // TODO: Pull selector automatically from caller?
  public setBreadcrumb(title: string, selector: string, args?: any[]): void {
    // This is called after navigation and by default we show breadcrumbs on any new navigation;
    // the caller can call hide() for itself if it wants to hide the breacrumb
    this.show();

    // There is one special case: the root breadcrumb will not have anything initialized yet;
    // outside of that we want to find an existing breadcrumb
    // TODO: This prevents the root breadcrumb from having arguments, is this OK?
    // TODO: Do we want the root breadcrumb to be more explicitly set?
    let existingBreadcrumbIndex: number;
    if (this._breadcrumbs.length === 0) {
      this._breadcrumbs.push({
        title,
        viewSelector: selector,
        constructionArgs: args || []
      });
      existingBreadcrumbIndex = 0;
    }
    else {
      existingBreadcrumbIndex = this._breadcrumbs.findIndex(x => x.viewSelector === selector);
      if (existingBreadcrumbIndex === -1) {
        throw new Error(`Breadcrumb metadata not found for new breadcrumb with title '${title}' and selector '${selector}'`);
      }
    }

    let currentBreadcrumbElement = this._getBreadcrumbElement(existingBreadcrumbIndex);
    currentBreadcrumbElement.text(title);
    currentBreadcrumbElement.attr("jquery-breadcrumb-selector", selector);
    this._breadcrumbs[existingBreadcrumbIndex].title = title;
    this._breadcrumbs[existingBreadcrumbIndex].constructionArgs = args || this._breadcrumbs[existingBreadcrumbIndex].constructionArgs

    // TODO: This is a completely screwy place to do this, we need real navigation controls
    // TODO: To fix this we should stop using jquery-controller-v1 and tell the breadcrumb controller to initialize first views instead
    // Because of how pages are initialized we need to wait for the first navigable
    // controller to initialize before we do predetermined navigation
    setTimeout(() => {
      // If there's navigation data, kick off an immediate navigation action
      let rawNavigationState = sessionStorage.getItem("JQueryBreadcrumbController.navigationState");
      if (rawNavigationState) {
        sessionStorage.removeItem("JQueryBreadcrumbController.navigationState")
        let navigationState: { selector: any, args: any[] } = JSON.parse(rawNavigationState);
        this.navigateToController.apply(this, [navigationState.selector, ...navigationState.args]);
      }
      rawNavigationState = sessionStorage.getItem("JQueryBreadcrumbController.navigationStateStack");
      if (rawNavigationState) {
        sessionStorage.removeItem("JQueryBreadcrumbController.navigationStateStack")
        let navigationState: Breadcrumb[] = JSON.parse(rawNavigationState);
        this.navigateToControllerStack(navigationState);
      }
    });
  }

  private _getBreadcrumbElement(index: number): JQuery<HTMLElement> {
    return jQuery(this.rootElement.find(".breadcrumb-item").get(index));
  }

  private _clearBreadcrumbs(): void {
    //We don't remove the first item because the rest of the controller
    //assumes there will always be at least one entry
    //because the first bread crumb is defined in the html
    this.rootElement.find(".breadcrumb-item:not(:first)").remove();
  }

  private _getCurrentBreadcrumbElement(): JQuery<HTMLElement> {
    return this.rootElement.find(".breadcrumb-item").last();
  }

  private _addBreadcrumb(title: string, selector: string, page: string | undefined, args: any[]): void {
    // The last breadcrumb will have the correct styles for the new item; we'll modify the prior
    // breadcrumb accordingly
    let previousBreadcrumb = this._getCurrentBreadcrumbElement();
    let newBreadcrumb = previousBreadcrumb.clone(true, true);
    newBreadcrumb.text(title);
    newBreadcrumb.attr("jquery-breadcrumb-selector", selector);
    previousBreadcrumb.parent().append(newBreadcrumb);
    previousBreadcrumb.removeClass("active");
    previousBreadcrumb.removeAttr("aria-current");

    // Set up the metadata while we're at it
    this._breadcrumbs.push({
      title,
      viewSelector: selector,
      constructionArgs: args,
      page: page
    });
  }

  @JQueryController.bindClick(".breadcrumb-item")
  public clickBreadcrumb(e: JQuery.TriggeredEvent): void {
    let selectorToOpen = e.currentTarget.getAttribute("jquery-breadcrumb-selector")!;
    this._navigateBackToController(selectorToOpen);
  }

  public static newTabNavigateToPageController(page: string, selector: string, ... args: any[]): void {
    
      sessionStorage.setItem("JQueryBreadcrumbController.navigationState", JSON.stringify({ selector, args }));
      window.open(page, "_blank");
      //Child has clone of sessionStorage
      //Now we clear our session storage
      sessionStorage.removeItem("JQueryBreadcrumbController.navigationState");
  }

  public static newTabNavigateToPageControllerStack(page: string, stack: Breadcrumb[]) {
    
      sessionStorage.setItem("JQueryBreadcrumbController.navigationStateStack", JSON.stringify(stack));
      window.open(page, "_blank");
      //Child has clone of sessionStorage
      //Now we clear our session storage
      sessionStorage.removeItem("JQueryBreadcrumbController.navigationStateStack");
  }

  // TODO: Support arguments?
  public static navigateToPageController(page: string, selector: string, ... args: any[]): void {
    if (page != getPage()) {
      sessionStorage.setItem("JQueryBreadcrumbController.navigationState", JSON.stringify({ selector, args }));
      window.location.href = page;
    }
    else {
      this.current!.navigateToController.apply(this.current, [selector, ... args]);
    }
  }

  public static navigateToPageControllerStack(page: string, stack: Breadcrumb[]) {
    if (page != getPage()) {
      sessionStorage.setItem("JQueryBreadcrumbController.navigationStateStack", JSON.stringify(stack));
      window.location.href = page;
    }
    else {
      this.current!.navigateToControllerStack(stack);
    }
  }

  public static navigateToPageControllerWithReturn(page: string, selector: string, ... args: any[]): void {
    // Setting all current bread crumbs to have a page
    for(let i = this.current!._breadcrumbs.length - 1; i >= 0  && !this.current!._breadcrumbs[i].page; i--) {
      this.current!._breadcrumbs[i].page = getPage();
    }
    this.current!._breadcrumbs.push({
      viewSelector: selector,
      constructionArgs: args,
      title: "Loading..."
    });
    this.navigateToPageControllerStack(page, this.current!._breadcrumbs)
  }

  public navigateToController(selector: string, ... args: any[]): void {
    // The open controller will always be the last one
    let existingSelector = this._breadcrumbs[this._breadcrumbs.length - 1].viewSelector;

    // Check if the target for navigation already exists in our navigation stack; if it does,
    // navigate back to it; if it doesn't, navigate forward
    if (this._breadcrumbs.find(x => x.viewSelector === selector)) {
      // TODO: What if the arguments disagree? We need to include args as part of the uniqueness of a navigation
      this._navigateBackToController(selector, args);
    }
    else {
      this._navigateForwardToController(selector, existingSelector, args);
    }
  }

  public navigateToControllerStack(stack: Breadcrumb[]) {
    // The open controller will always be the last one
    let existingSelector = this._breadcrumbs[this._breadcrumbs.length - 1].viewSelector;
    
    //We will navigate to this last
    var lastEntryInStack = stack.pop();
    
    if(stack.length > 0 ) {
      this._clearBreadcrumbs();
      //the firs bread crumb is a special case because
      // it is setup in the html directly
      let firstCrumbInfo  = stack.shift();
      let firstCrumbElement = this._getCurrentBreadcrumbElement();
      firstCrumbElement.text(firstCrumbInfo!.title);
      firstCrumbElement.attr("jquery-breadcrumb-selector", firstCrumbInfo!.viewSelector);
      firstCrumbElement.addClass("active");
      firstCrumbElement.attr("aria-current","page");

      this._breadcrumbs = [firstCrumbInfo!];
      for(let navigation of stack) {
          this._addBreadcrumb(navigation.title, navigation.viewSelector, navigation.page, navigation.constructionArgs)
      }
    }

    this._navigateForwardToController(lastEntryInStack!.viewSelector, existingSelector, lastEntryInStack!.constructionArgs);
  }

  public navigateBack(): void {
    // The selector to open is the second last one in the list; this can't be done if we don't
    // have one
    if (this._breadcrumbs.length < 2) {
      throw new Error("There is no history to navigate back to");
    }
    let selectorToOpen = this._breadcrumbs[this._breadcrumbs.length - 2].viewSelector;
    this._navigateBackToController(selectorToOpen);
  }

  private _addUninitializedBreadcrumb(selector: string, args: any[]) {
    this._addBreadcrumb("Loading...", selector, undefined, args);
  }

  private _navigateForwardToController(selectorToOpen: string, selectorToClose: string, args: any[]): void {
    this._addUninitializedBreadcrumb(selectorToOpen, args.slice());
    this._finishNavigationAfterBreadcrumbSetup(selectorToOpen, selectorToClose, args);
  }

  private _navigateBackToController(selectorToOpen: string, args?: any[]): void {
    // The selector to close is the last one in the list
    let selectorToClose = this._breadcrumbs[this._breadcrumbs.length - 1].viewSelector;

    // Navigate back to the requested selector by stripping back the selector list and doing a
    // forward navigation; the selector we just received will always be in our list unless we
    // made a coding error so we know we'll find it
    let oldBreadcrumb = this._breadcrumbs[this._breadcrumbs.length - 1];
    while (oldBreadcrumb.viewSelector != selectorToOpen) {
      this._breadcrumbs.pop();
      this._getCurrentBreadcrumbElement().remove();
      oldBreadcrumb = this._breadcrumbs[this._breadcrumbs.length - 1];
    }

    // The breadcrumb stack is now set to show the prior breadcrumb value; reset its proper state,
    // treat it as uninitialized and do a navigation
    let breadcrumbElement = this._getCurrentBreadcrumbElement();
    breadcrumbElement.addClass("active");
    breadcrumbElement.attr("aria-current", "page");

    // The state is ready for forward navigation; use any arguments we're provided with, if not
    // default to what we already had
    if(oldBreadcrumb.page && oldBreadcrumb.page != getPage()) {
      JQueryBreadcrumbController.navigateToPageControllerStack(oldBreadcrumb.page, this._breadcrumbs);
      return;
    }
    this._finishNavigationAfterBreadcrumbSetup(selectorToOpen, selectorToClose,
      args || oldBreadcrumb.constructionArgs);
  }

  private _finishNavigationAfterBreadcrumbSetup(selectorToOpen: string, selectorToClose: string, args: any[]): void {
    let existingController = JQueryController.getControllerBySelector(selectorToClose);
    existingController.close();
    JQueryController.initializeController(selectorToOpen, args);
  }
}