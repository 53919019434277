import { JQueryPropertyBinding } from "../jqueryBinding";

export class DataTableCurrentPage extends JQueryPropertyBinding<number> {
  
  public getBoundValue(boundElements: JQuery): number {
    if($.fn.dataTable.isDataTable(boundElements)) {
      return boundElements.DataTable().page();
    }
    return 0;
  }
  public setBoundValue(boundElements: JQuery, value: number): void {
    if($.fn.dataTable.isDataTable(boundElements)) {
       boundElements.DataTable().page(value).draw('page');
    }
  }
}