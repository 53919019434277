import { JQueryController } from "@legacyx/web-uitoolkit/jqueryController";
import { QueryParser } from "../shared/queryParser";
import { JQueryMessageController } from "@legacyx/web-uitoolkit/jqueryMessageController";
import { DriveSingleQuery, DriveListingQuery} from "../model/drive"
import { currentUser } from "../shared/currentUser"
import { executeServiceCall } from "@legacyx/web-uitoolkit/serviceCalls";
import { PaginatedResults } from "model/pagination";
import { SelectOption } from "model/select";
import { Company } from "model/company";
import { DriveAgreementQuery } from "../model/agreement";

interface DriveCompanies {
  CompanyID: string,
  CompanyName: string,
  SignedCards?: string,
  EstimatedEmployees?: number,
  Ratio?: number
}

interface AddDriveResponse {
  DriveID: string
}

interface DriveDetails {
  DriveID?: string,
  Name: string,
  StartDate: Date, 
  EndDate: Date | null,
  Companies: DriveCompanies[],
  AgreementID?: string,
}


export class DriveSingleController extends JQueryController {
  @JQueryController.listSearchQuery<Company>('#company-lookup', (value: Company) => {
    return (["CompanyName"] as (keyof Company)[])
    .reduce< string | null >((returnValue, key) => {
      if(value[key]) {
        if(returnValue) {
          return returnValue + " - "+ value[key];
        } else {
          return value[key];
        }
      }
      return returnValue;
    }, null) || "";
  })
  public async getTheSelect2Elements(searchTerm: string): Promise<Company[]> {
    if (searchTerm)
      return await executeServiceCall(`drives/getDetailedCompanyForSelect?term=${searchTerm}`);
    return [];
  }
  @JQueryController.bindListValue('#company-lookup')
  public company?: Company;

  @JQueryController.bindInputChange('#company-lookup')
  public function(something: JQuery.Event) {
    if (this.company) {
      let found = false
      for (let i=0; i < this.companies.length; i++){
        if (this.companies[i].CompanyID === this.company.CompanyID){
          found = true;
        }
      } 
      if (!found) {
        //Companies needs to be set, cannot just push to refresh the table
        let newCompany = this.companies;
        newCompany.push(this.company);
        this.companies = newCompany;
      }
    }
  }
  // View
  @JQueryController.bindVisibility('#new-drive-header') public newDriveHeaderVisibility!: boolean;
  @JQueryController.bindVisibility('#update-drive-header') public updateDriveHeaderVisibility!: boolean;
  @JQueryController.bindDisplayText("#cookieName") public cookieName!: string;
  @JQueryController.bindVisibility(".new-drive-button") public newDriveButtonVisibility: boolean;
  @JQueryController.bindVisibility(".edit-drive-button") public editDriveButtonVisiblity: boolean;

  // General
  @JQueryController.bindInputValue("#name") public name!: string;
  @JQueryController.bindDatePickerValue("#startDate") public startDate!:  Date;
  @JQueryController.bindDatePickerValue("#endDate") public endDate!: Date | null;
  // Validation
  @JQueryController.bindParsleyValidation("#main-form") public isMainFormValid!: boolean;
  @JQueryController.bindSelectOptions("#company") public companyOptions!: SelectOption[]
  @JQueryController.defineDataTableColumn("CompanyName")
  @JQueryController.defineDataTableColumnFromTemplate("CompanyID", "#user-list-table-action-column-template-remove")
  // @JQueryController.defineDataTableColumn("EffectiveDate")
  // @JQueryController.defineDataTableColumn("ExpiryDate")
  @JQueryController.bindDataTable("#companies-table", {
    responsive: {
      details: false
    },
    language: {
      search: "Search"
    },
    order: [[0,"desc"]]
  })
  public companies: DriveCompanies[] = [];

  private _driveID?: string;
  private _agreementID?: string;
  private _cleanDrive: DriveDetails = {
    DriveID: "",
    Name: "",
    StartDate: new Date(),
    EndDate: null,
    Companies: [],
  };

  constructor(rootElement: JQuery<HTMLElement>, driveID?: string) {
    super(rootElement);
    this.newDriveButtonVisibility = false;
    this.editDriveButtonVisiblity = false;
    var query = QueryParser.getQuery<DriveSingleQuery>(false);
    if(!currentUser || !currentUser.canManageDrives) {
      window.location.href = "/notes.html"
    }
    //If we are viewing the drive, we don't want the url to go away. But if we are redirected
    //from the agreements page we remove it, this keeps it consistent to creating a new drive
    if (query && !query.driveID){
      let driveQuery = QueryParser.getQuery<DriveAgreementQuery>(true);
      if (driveQuery && driveQuery.union && driveQuery.agreementID &&driveQuery.agreementName){
        this.name = driveQuery.agreementName + "-" + driveQuery.union
        this._agreementID = driveQuery.agreementID;
      }      
    }
    if(query && query.driveID && currentUser) {
      this._driveID = query.driveID;
      this.LoadDriveInformation().then(() => {
        this.populateCompanySelect();
      })
      this.setNewUserVisiblity(false);
      this.setHeader(false);
    } else {
      this.cookieName = "New Drive";
      this.populateCompanySelect();
      this.setHeader(true);
      this.setNewUserVisiblity(true);
    }
  }
  
  private setHeader(isNew: boolean) {
    this.newDriveHeaderVisibility = isNew;
    this.updateDriveHeaderVisibility = !isNew;
  }

  private setNewUserVisiblity(isNewUser: boolean) {
      this.editDriveButtonVisiblity = !isNewUser;
      this.newDriveButtonVisibility = isNewUser;
  }

  private setUIValues(drive: DriveDetails) {
    this.name = drive.Name;
    this.startDate = drive.StartDate;
    this.endDate = drive.EndDate;
    this.companies = [...drive.Companies];
    this._cleanDrive = {...drive};
  }

  public deleteCompany(companyID: string){
    let newCompanies = this.companies;
    for (let i=0; i < this.companies.length; i++){
      if (newCompanies[i].CompanyID === companyID){
        newCompanies.splice(i, 1);
      }
    }
    this.companies = newCompanies;
  }

  public async LoadDriveInformation() {
    var driveInformation = await this.executeServiceCall<DriveDetails>("drives/getDrive",
    {
      driveID: this._driveID
    });
    this.cookieName = driveInformation.Name;
    this.companies = await this.executeServiceCall<DriveCompanies[]>("drives/getCompaniesByDrive", {driveID: this._driveID});
    driveInformation.Companies = this.companies;
    this.setUIValues(driveInformation);
  }

  private async populateCompanySelect() {
    this.companyOptions = ((await executeServiceCall<PaginatedResults<Company>>('companies/getCompaniesForSelect')).results.map(x => {return { text: x.CompanyName, value: x.CompanyID }}))
  }

  @JQueryController.bindClick("#cancel") 
  public cancelInput() {
    this.setUIValues(this._cleanDrive);
  }

  @JQueryController.bindClick("#cancelCreate") 
  public cancelCreateDrive() {
    window.location.href = '/drives.html'
  }

  @JQueryController.bindInputChange("#startDate")
  @JQueryController.bindInputChange("#endDate") 
  public checkDates() {
    $("#startDate").parsley().validate();
    $("#endDate").parsley().validate();
  }
  
  @JQueryController.bindClick("#save")
  @JQueryController.bindValidation("#drive-form")
  public async UpdateDrive() {
    if(this._driveID) {
      this._cleanDrive = {
        DriveID: this._driveID,
        Name: this.name,
        StartDate: this.startDate,
        EndDate: this.endDate,
        Companies: this.companies,
      }
      
      var response = await this.executeServiceCall("drives/upsertDrive",
      {
        drive: this._cleanDrive
      });

      JQueryMessageController.showMessage("updated-drive");
    }
  }

  @JQueryController.bindClick("#createDrive")
  @JQueryController.bindValidation("#drive-form")
  public async CreateDrive(e: JQuery.Event): Promise<void> {
    var response = await this.executeServiceCall<AddDriveResponse>("drives/upsertDrive",
    {
      drive: {
        Name: this.name,
        StartDate: this.startDate,
        EndDate: this.endDate,
        Companies:this.companies,
        AgreementID: this._agreementID
      }
    });

    this._driveID = response.DriveID;
    this.setNewUserVisiblity(false);
    
    window.location.href = "/drives.html"+ QueryParser.BuildQueryString<DriveListingQuery>({createdDrive: this.name});
  }
}